import React from 'react';
import './Loader.css'

export const initLoader = (elemId, elemRef) => {
    let element = elemId ? document.getElementById(elemId) : elemRef;
    const loader = element.querySelector(".loader--sm");
    if(loader) return;
    const loaderEl = document.createElement("div");
    loaderEl.classList.add("loader--sm");
    element.append(loaderEl);
}

export const stopLoader = (elemId, elemRef) => {
    let element = elemId ? document.getElementById(elemId) : elemRef;
    const loaderEl = element.querySelector(".loader--sm");
    loaderEl.remove();
}

const Loader = () => <div className='loader'></div> ;
 
export default Loader;