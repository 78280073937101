import { createPortal } from "react-dom";
import Loader from "../Components/Common/Loader/Loader";

const spinner = document.getElementById("progress-spinner")

export default function Spinner(){
    return createPortal(<Loader/>, spinner);
}

export const appendSpinner = () => {
    spinner.style.display = "grid";
}

export const detachSpinner = () => {
    spinner.style.display = "none";
}