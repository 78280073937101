import {
	get,
	post,
	postFormData,
	put,
	putFormData,
	download,
	deleteurl,
	getPartsRocket,
	downloadWFAPP,
	downloadGarageBillAPP,
	getV2,
} from "./ApiMethods.js";
import {
	getNon,
	postNon,
	putNon,
	downloadNon,
	deleteurlNon,
} from "./ApiMethodsNonNet";
import store from "../Redux/Store/Store.js";
import { setToastData } from "../Redux/Actions/ActionCreators";
// import { mapQueryParams } from "../Utils/helpers.js";

import { checkIfFinanceAdminOrOps, isMalaysiaLogin, isNonNetowrkUser } from "../Components/Routes/AdminUsers.js";

import { $detachProgressBar, $loadProgressBar } from "../Utils/progressBar.js";
import { $addToast, $removeAllToast } from "../Utils/toast.js";
import { guid } from "../Utils/guid.js";
import { type } from "../Components/Reconciliation/Validation/JoiSchema.js";

export const getWorkShopId = () => {
	const state = store.getState();
	return state.garageDetails.data && state.garageDetails.data.id;
};

const getRetailerId = () => {
	const state = store.getState();
	return (
		state.garageDetails.retailer_data && state.garageDetails.retailer_data.id
	);
};

const getRetailerCityId = () => {
	const state = store.getState();
	return (
		state.garageDetails.retailer_data &&
		state.garageDetails.retailer_data.city_id
	);
};

const checkUserType = () => {
	const state = store.getState();
	let userType = state.garageDetails.userType;
	if (userType && userType.includes("nn")) return true;
	return false;
};


const setNetworkCallGet = (queryStr) => {
	let allowNN = window.location.pathname == "/Reconciliation/Non-network%20Garage%20wise";
	return  ((allowNN ||checkUserType()) 
				&& getNon("nn-" + queryStr)
				|| get(queryStr));
};

const setNetworkCallPost = (queryStr, payload) => {
	return checkUserType()
		? postNon("nn-" + queryStr, payload)
		: post(queryStr, payload);
};

const setNetworkCallPostFormData = (queryStr, payload) => {
	return postFormData(queryStr, payload);
}

const setNetworkCallDownload = (queryStr) => {
	return checkUserType() ? downloadNon("nn-" + queryStr) : download(queryStr);
};

const setNetworkCallWFAPPDownload = (queryStr) => {
	return checkUserType() ? null : downloadGarageBillAPP(queryStr);
}

const setNetworkCallPut = (queryStr, payload) => {
	return checkUserType()
		? putNon("nn-" + queryStr, payload)
		: put(queryStr, payload);
};

const setNetworkCallDelete = (queryStr) => {
	return checkUserType() ? deleteurlNon("nn-" + queryStr) : deleteurl(queryStr);
};

export const getStatusMonitoring = async (dateRange, orderOf) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/information/${workShopId}/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&order_of=${orderOf}`
	);
	return res && res.data ? res.data : [];
};

export const getStatusOrders = async (
	dateRange,
	orderOf,
	status_key,
	page = 1,
	limit = 5
) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/information/${workShopId}/orders-list/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&order_of=${orderOf}&status_key=${status_key}&limit=${limit}&page=${page}`
	);
	return res && res.data ? res.data : [];
};

export const getBusinessOverview = async (dateRange, orderOf) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/overview/${workShopId}/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&order_of=${orderOf}`
	);
	return res && res.data ? res.data : [];
};

export const getProductivityOverview = async (dateRange, orderOf) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/reports/productivity/${workShopId}/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&order_of=${orderOf}`
	);
	return res && res.data ? res.data : {};
};

export const getOrders = async (
	orderOf,
	orderType,
	dateRange,
	ordersCatagoryCode,
	page,
	limit = 10,
	search = null,
	isNetworkEcosystem = false
) => {
	const workShopId = getWorkShopId();
	let res;
	let url
	if(isNetworkEcosystem){
		url = `workshop/download/summary/${workShopId}/?order_type=all&order_of=${orderOf}&page=${page}&limit=${limit}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&of_network_ecosystem=1`
		url = search ? `${url}&search=${search}` : url
	}
	else if (orderOf === "garage" && orderType === "retail") {
		url = `workshop/download/summary/${workShopId}/?order_type=${orderType}&order_of=${orderOf}&page=${page}&limit=${limit}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&bill=${ordersCatagoryCode}`
		url = search ? `${url}&search=${search}` : url
	} 
	else {
		url = `workshop/download/summary/${workShopId}/?order_type=${orderType}&order_of=${orderOf}&page=${page}&limit=${limit}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
		url = search ? `${url}&search=${search}` : url
	}
	res = await get(url);
	return res && res.data ? res.data : [];
};

export const downloadOrders = async (
	orderOf,
	orderType,
	dateRange,
	ordersCatagoryCode
) => {
	const workShopId = getWorkShopId();
	let res;
	if (orderOf === "garage" && orderType === "retail") {
		res = await download(
			`workshop/download/summary/${workShopId}/?order_type=${orderType}&order_of=${orderOf}&is_download=1&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&bill=${ordersCatagoryCode}`
		);
	} else {
		res = await download(
			`workshop/download/summary/${workShopId}/?order_type=${orderType}&order_of=${orderOf}&is_download=1&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
		);
	}
	return res ? res : null;
};

export const downloadTrnsationHistory = async (month, year,transaction_type) => {
	let res;
	res = await download(
		`retailer/garage/txn-hst/?month=${month}&year=${year}&is_download=1&transaction_type=${transaction_type}`
	);
	return res ? res : null;
};
export const getEmployeeTypes = async () => {
	const res = await get(`workshop/owner/user-roles`);
	return res && res.data ? res.data : [];
};

export const getEmployees = async () => {
	const workShopId = getWorkShopId();
	const res = await get(`workshop/owner/users/${workShopId}/`);
	return res && res.data ? res.data : [];
};

export const getSingleEmployee = async (id) => {
	const workShopId = getWorkShopId();
	const res = await get(`workshop/owner/users/${workShopId}/?id=${id}`);
	return res && res.data ? res.data : [];
};

export const deleteEmployeeRole = async (id, userId) => {
	const workShopId = getWorkShopId();
	const res = await deleteurl(`workshop/owner/users/${workShopId}/`, {
		// ...employeeDetails,
		id,
		user_id: userId,
		workshop_id: workShopId,
	});
	return res;
};

export const addEmployee = async (employeeDetails) => {
	const workShopId = getWorkShopId();
	const res = await post(`workshop/owner/users/${workShopId}/`, {
		...employeeDetails,
		workshop_id: workShopId,
	});
	return res;
};

export const editEmployee = async (employeeDetails) => {
	const workShopId = getWorkShopId();
	const res = await put(`workshop/owner/users/${workShopId}/`, {
		...employeeDetails,
		workshop_id: workShopId,
	});
	return res;
};

export const addGarage = async (garageDetails) => {
	const res = await post(`workshop/on-board/`, garageDetails);
	return res;
};

export const getEscalations = async (dateRange) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/orders/escalations/${workShopId}/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
	);
	return res && res.data ? res.data : [];
};

export const getReconAndCreditLimit = async() => {
	const workShopId = getWorkShopId();
	const res = await get(`workshop/recon-and-credit-limit/?workshop_id=${workShopId}`);
	return res?.data ?? {};
}

export const SaveReconAndCreditLimit = async (payload) => {
	const workshopId = getWorkShopId();
	const res = await put(`workshop/recon-and-credit-limit/`, {workshop_id : workshopId, ...payload});
	return res?.data;
}

export const getSettlements = async (
	settlementsType,
	dateRange,
	page,
	page_limit = 10,
	activeOption
) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/settlement/invoices/?workshop_id=${workShopId}&recon=${settlementsType}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&page=${page}&page_limit=${page_limit}&order_type=${activeOption}`
	);
	return res && res.data ? res.data : [];
};

export const getSettlementsForSpares = async (
	settlementsType,
	dateRange,
	page,
	page_limit = 10,
	activeOption
) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	const res = await httpService(
		`reconciliation/garage-wise/spares/?garage_id=${workShopId}&recon_type=${settlementsType}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&page=${page}&page_limit=${page_limit}&spare_order_type=${activeOption}`
	);
	return res && res.data ? res.data : [];
};

export const downloadSettlements = async (
	settlementsType,
	dateRange,
	activeOption
) => {
	const workShopId = getWorkShopId();
	const res = await download(
		`workshop/settlement/invoices/?workshop_id=${workShopId}&recon=${settlementsType}&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&order_type=${activeOption}&is_download=1`
	);
	return res ? res : null;
};

export const getSlabSettlements = async (settlementsType, month, year) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	const res = await httpService(
		`reconciliation/settlement/slab/commission-bill/?garage_id=${workShopId}&month=${month}&year=${year}&recon_status=${settlementsType}`
	);
	return res && res.data ? [res.data] : [];
};

export const getGarages = async (cityId, region, order_type) => {
	let queryStr = "";
	checkUserType() ? (queryStr = `all_garages=1`) : (queryStr = ``);
	(checkIfFinanceAdminOrOps() || isMalaysiaLogin())? (queryStr = `show_all_workshops=1`) : (queryStr = ``);  
	if (cityId) queryStr = `${queryStr}&city_id=${cityId}`;
	if (region) queryStr = `${queryStr}&region=${region}`;
	if (order_type) queryStr = `order_type=${order_type}`; 
	const res = await setNetworkCallGet(`workshops/?${queryStr}`);
	return res && res.data ? res.data : [];
};

export const getGaragesForSummary = async (partyId) => {
	// let queryStr = "";
	// checkUserType() ? (queryStr = `all_garages=1`) : (queryStr = ``);
	// (checkIfFinanceAdminOrOps() || isMalaysiaLogin())? (queryStr = `show_all_workshops=1`) : (queryStr = ``);  
	// if (cityId) queryStr = `${queryStr}&city_id=${cityId}`;
	// if (region) queryStr = `${queryStr}&region=${region}`;
	// if (order_type) queryStr = `order_type=${order_type}`; 
	const res = await setNetworkCallGet(`workshops/?is_audit_workshop=1${partyId ? `&party_id=${partyId}` : ''}`);
	return res && res.data ? res.data : [];
};


export const getNonNetworkGarages = async () => {
	const res = await getNon("nn-workshop/listing/");
	return res && res.data? res.data : [];
}

export const getDashBoardTodayStatus = async (businessType) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/dashboard/bussiness-metrics/${workShopId}/?order_of=${businessType}`
	);
	return res && res.data ? res.data : {};
};

export const getBusinessMonth = async (businessType, dateRange) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/dashboard/${workShopId}/metrics/month/${businessType}/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
	);
	return res && res.data ? res.data : {};
};

export const getChartsData = async () => {
	const workShopId = getWorkShopId();
	const res = await get(`workshops/dashboard/graph/${workShopId}/`);
	return res && res.data ? res.data : {};
};

export const getRetailerMainDashboardDetails = async (start_date, end_date) => {
	const retailerId = getRetailerId();
	const res = await get(
		`retailer/web-dashboard/?retailer_id=${retailerId}&start_date=${start_date}&end_date=${end_date}`
	);
	return res && res.data ? res.data : {};
};

export const getRetailerMainDashboardChartsData = async () => {
	const retailerId = getRetailerId();
	const res = await get(`retailer/web-dashboard/graph-data/${retailerId}/`);
	return res && res.data ? res.data : {};
};

export const getNearestGarages = async () => {
	const retailerId = getRetailerId();
	const city_id = getRetailerCityId();
	const res = await get(`retailer/city-wise/garages/${city_id}/${retailerId}/`);
	return res && res.data ? res.data : {};
};

export const approveOrEscalateSettlements = async (actionType, payLoad) => {
	const workShopId = getWorkShopId();
	const res = await put(
		`workshop/settlement/${workShopId}/request/${actionType}/`,
		payLoad
	);
	return res;
};

export const approveOrEscalateSlabCommissionBill = async (payLoad) => {
	const workShopId = getWorkShopId();
	const res = await put(`reconciliation/settlement/recon-request/`, {
		...payLoad,
		garage_id: workShopId,
	});
	return res;
};

export const requestSettlements = async (payLoad) => {
	const workShopId = getWorkShopId();
	const res = await put(
		`workshop/settlement/${workShopId}/recon/request/`,
		payLoad
	);
	return res;
};

export const requestSettlementsInventory = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await put(`workshop/settlement/inventory/request_settlement/`, {
		garage_id: workShopId,
		month,
		year,
		is_requested: 1,
	});
	return res;
};

export const downloadCustomerBill = async (orderId) => {
	const res = await download(
		`workshop/order/get_bill/?order_id=${orderId}&is_pdf=1&estimate_flag=0`
	);
	return res ? res : null;
};

export const downloadGarageBill = async (orderId) => {
	const res = await download(
		`workshop/admin/bill_for_garage/?order_id=${orderId}&is_pdf=1`
	);
	return res ? res : null;
};

export const downloadCommissionBill = async (orderId) => {
	const res = await download(
		`workshop/admin/commission_invoice/?order_id=${orderId}&is_pdf=1`
	);
	return res ? res : null;
};

export const downloadSlabCommissionBill = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await download(
		`reconciliation/settlement/slab/commission-bill/?garage_id=${workShopId}&month=${month}&year=${year}&download_bill=1`
	);
	return res ? res : null;
};

export const getDiscountAndTakeRate = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/dashboard/settlement/discount_take_rate/?garage_id=${workShopId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : [];
};

export const updateDiscountAndTakeRate = async (payLoad) => {
	const workShopId = getWorkShopId();
	const res = await put(`workshop/dashboard/settlement/discount_take_rate/`, {
		...payLoad,
		garage_id: workShopId,
	});
	return res;
};

export const getGomEssentials = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/settlement/inventory/?garage_id=${workShopId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : [];
};

export const updateGomEssentialsItems = async (payLoad, month, year) => {
	const workShopId = getWorkShopId();
	const payLoadData = { garage_id: workShopId, items: payLoad, month, year };
	const res = await put(`workshop/settlement/inventory/`, payLoadData);
	return res;
};

export const getRegionReconciliation = async (month, year, activeOption) => {
	const res = await get(
		`reconciliation/region-wise/?month=${month}&year=${year}&order_type=${activeOption}`
	);
	return res && res.data ? res.data : [];
};

export const getRegionTable = async (month, year, activeOption) => {
	const res = await get(
		`reconciliation/city-wise/all-cities-amount-data/?month=${month}&year=${year}&order_type=${activeOption}&get_data=1`
	);
	return res && res.data ? res.data : [];
};

export const regionReconWorkShop = async (month, year, activeOption) => {
	const res = await get(
		`reconciliation/settlement/all-cities/approved-orders/summary/?month=${month}&year=${year}&order_type=${activeOption}&get_data=1`
	);
	return res && res.data ? res.data : [];
};

export const getRegionReconciliationDownload = async (
	month,
	year,
	activeOption
) => {
	const res = await download(
		`reconciliation/region-wise/?month=${month}&year=${year}&order_type=${activeOption}&is_download=1`
	);
	return res ? res : null;
};

export const getCityReconciliation = async (
	month,
	year,
	region,
	cityId = 1,
	activeOption
) => {
	const res = await get(
		`reconciliation/city-wise/?month=${month}&year=${year}&region=${region}&city_id=${cityId}&order_type=${activeOption}`
	);
	return res && res.data ? res.data : [];
};

export const getRetailersSettlementReconciliation = async ({month,
	year,
	spareRetailerId}) => {
	const res = await get(
		`reconciliation/settlement/retailer-recon-summary/?retailer_id=${spareRetailerId}&month=${month}&year=${year}`
	);
	return res && res.data ? [res.data] : [];
};

export const getRetailersCreditReconciliation = async ({
	month,
	year,
	spareRetailerId
}) => {
	const res = await get(
		`reconciliation/settlement/retailer/advance-credit/?retailer_id=${spareRetailerId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : [];
};

export const getRetailersTransactionHistory = async({
	month,
	year,
	page = 1, 
	retailer_id = null,
	city_id = null,
	search = null
}) => {
	const res = await get(
		`advance-credit/txn-hst?month=${month}&year=${year}&page=${page}&page_limit=20&order_type=retailer${city_id ? `&city_id=${city_id}${retailer_id ? `&retailer_id=${retailer_id}` : ""}` : ""}${search ? `&search=${search}` : ""}`)
	return res && res.data ? res.data : [];
}

export const getDistributorTransactionHistory = async({
	month,
	year,
	page = 1,
	distributor_id = null,
	city_id = null,
	search = null,
}) => {
	const res = await get(
		`advance-credit/txn-hst/?month=${month}&year=${year}&page=${page}&page_limit=20&order_type=distributor&from_city_new=1${city_id ? `&city_id=${city_id}` : ""}${distributor_id ? `&distributor_id=${distributor_id}` : ""}${search ? `&search=${search}` : ""}`
	)
	return res && res.data ? res.data : [];
}

export const getRetailersPaymentReconciliation = async ({
	month,
	year,
	spareRetailerId
}) => {
	const res = await get(
		`reconciliation/settlement/retailer/payments/?retailer_id=${spareRetailerId}&month=${month}&year=${year}&page=1&page_limit=20`
	);
	return res && res.data || [];
};

export const getGarageReconciliation = async (
	month,
	year,
	orderType,
	page,
	page_limit = 10,
	activeOption,
	_appVersion = 1
) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	let appService = null;
	if(_appVersion == 1){
		appService = httpService(
			`reconciliation/garage-wise/?month=${month}&year=${year}&order_filter=${orderType}&garage_id=${workShopId}&page=${page}&page_limit=${page_limit}&order_type=${activeOption}`
		);
	}
	else{
		appService = httpService(
			`reconciliation/v2/garage-wise/?month=${month}&year=${year}&order_filter=${orderType}&garage_id=${workShopId}&page=${page}&page_limit=${page_limit}&order_type=${activeOption}`
		)
	}
	const res = await appService;
	return res && res.data ? res.data : [];
};

export const getGarageReconciliationForSpares = async (
	month,
	year,
	page,
	page_limit = 10
) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`reconciliation/garage-wise/spares/?month=${month}&year=${year}&garage_id=${workShopId}&page=${page}&page_limit=${page_limit}`
	);
	return res && res.data ? res.data : [];
};



export const getGarageReconciliationOrders = async(payload) => {
	let getEntries = Object.entries(payload);
	const entriesLen = getEntries.length;
	if(entriesLen == 0)
		return;
	getEntries = getEntries.filter(([,value]) => !!value);
	getEntries = getEntries.reduce((acc, cur, index) => {
		acc += `${cur[0]}=${cur[1]}`;
		if(index < entriesLen - 1){
			acc+="&";
		}
		return acc;
	}, `?`);
	// console.log(getEntries);
	// let queryParams = `?`;
	// queryParams += month ? `month=${month}&` : "";
	// queryParams += year ? `year=${year}&` : "";
	// queryParams += page ? `page=${page}&` : "";
	// queryParams += page_limit ? `page_limit=${page_limit}&` : "";
	// queryParams += is_download ? `is_download=${is_download}&` : "";
	// queryParams += order_type ? `order_type=${order_type}` : "";
	const workShopId = getWorkShopId();
	const res = 
	await getNon(`reconciliation/garage-wise/orders/${getEntries}&garage_id=${workShopId}`);
	// console.log(res);
	return res && res.data || [];
}

export const downloadNNGarageReconOrders = async (payload) => {
	let getEntries = Object.entries(payload);
	const entriesLen = getEntries.length;
	if(entriesLen == 0)
		return;
	getEntries = getEntries.filter(([,value]) => !!value);
	getEntries = getEntries.reduce((acc, cur, index) => {
		acc += `${cur[0]}=${cur[1]}`;
		if(index < entriesLen - 1){
			acc+="&";
		}
		return acc;
	}, `?`);

	const res = await downloadNon(`reconciliation/garage-wise/orders/${getEntries}`);
	return res;
}

export const getPersonReconciliation = async (
	month,
	year,
	region,
	cityId,
	userId,
	page,
	page_limit = 10,
	searchByOrderId = null,
	garage_id=null
) => {
	const res = await get(
		`reconciliation/settlement/payments/person-wise/?month=${month}&year=${year}&region=${region}&city_id=${cityId}&party_name_id=${userId || ""}&page=${page}&page_limit=${page_limit}&is_audit=1${searchByOrderId ? `&search=${searchByOrderId}` : ""}${garage_id ? `&garage_id=${garage_id}` : ""}`
	);
	return res && res.data ? res.data : [];
};

export const downloadPersonReconciliation = async (
	month,
	year,
	region,
	cityId,
	userId
) => {
	const res = await download(
		`reconciliation/settlement/payments/person-wise/?month=${month}&year=${year}&region=${region}&city_id=${cityId}&user_id=${userId}&is_download=1`
	);
	return res ? res : null;
};

export const downloadPartySummary = async (
	order_id
) => {
	const res = await download(
		`workshop/order-summary/audit/?order_id=${order_id}`
	);
	return res ? res : null;
};

export const getPersonReconUsers = async () => {
	const res = await get(`reconciliation/settlement/finance-users/?is_audit=1`);
	return res && res.data ? res.data : [];
};

export const searchGarageOrders = async (orderData, page, page_limit = 10) => {
	const res = await get(
		`workshop/orders/search/?search=${orderData}&page=${page}&page_limit=${page_limit}`
	);
	return res && res.data ? res.data : [];
};

export const getGarageReconSettlementSummary = async (month, year, _appVersion = 1, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	let appService = null;
	if(_appVersion == 1){
		appService = httpService(
			`reconciliation/settlement/code-wise-summary/?month=${month}&year=${year}&garage_id=${workShopId}&fetch_btob=${fetch_btob}`
		)
	}
	else if(_appVersion == 2){
		appService = httpService(
			`reconciliation/v2/settlement/summary?month=${month}&year=${year}&garage_id=${workShopId}&fetch_btob=${fetch_btob}`
		)
	}
	const res = await appService;
	return res && res.data ? [res.data] : [];
};

export const getNNGarageReconSettlementSummary = async({month, year}) => {
	const workShopId = getWorkShopId();
	const res = await getNon(`nn-workshop/spares/settlement-summary/?garage_id=${workShopId}&month=${month}&year=${year}`);
	// console.log("settlement summary:", res);
	return res && res.data ? [res.data] : [];
}

export const getGarageSettlementHistory = async (month, year) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	const res = await httpService(
		`reconciliation/settlement/code-wise-summary/?month=${month}&year=${year}&garage_id=${workShopId}&from_garage_app=1`
	);
	return res && res.data ? [res.data] : [];
};

export const getGarageReconPaymentSummary = async (page, page_limit = 5) => {
	const workShopId = getWorkShopId();
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = checkIfNonNetworkUser ? getNon : get;
	const res = await httpService(
		`reconciliation/settlement/payments/?garage_id=${workShopId}&page=${page}&page_limit=${page_limit}`
	);
	return res && res.data ? res.data : [];
};

export const getGarageNNReconPaymentSummary = async ({
	month,
	year,
	is_download
}) => {
	const workShopId = getWorkShopId();
	const res = await getNon(`nn-workshop/spares/settlement-payment/?garage_id=${workShopId}&month=${month}&year=${year}${!!is_download && "&is_download=1" || ""}`)
	return res?.data;
}

export const downloadGarageNNReconPaymentSummary = async({
	month,
	year,
	is_download
}) => {
	const workShopId = getWorkShopId();
	const res = await downloadNon(`nn-workshop/spares/settlement-payment/?garage_id=${workShopId}&month=${month}&year=${year}${!!is_download && "&is_download=1" || ""}`)
	return res;
}

export const approvePaymentForNonNetworkRecon = async(payload) => {
	const res = await putNon(`nn-workshop/spares/payment-mark-approved/`, payload);
	return res;
}


export const downloadCityRecon = async (
	month,
	year,
	region,
	cityId,
	activeOption
) => {
	const res = await download(
		`reconciliation/city-wise/?month=${month}&year=${year}&region=${region}&city_id=${cityId}&is_download=1&order_type=${activeOption}`
	);
	return res ? res : null;
};

const penaltyInvoice = async (month, year, cityId) => {
	const formData = new FormData();
	formData.append("month", month);
	formData.append("year",year);
	formData.append("city_id", cityId);
	return await postFormData(`workshop/invoices/penalty-invoice/`, formData, false);

}

const consolidateInvoice = async (month, year, cityId) => {
	return await get(
		`workshop/invoices/consolidate-invoice/?month=${month}&year=${year}&city_id=${cityId}`
	);
}

export const sendConsolidateMail = async (month, year, cityId, type) => {
	if(type == "retail") {
		$loadProgressBar();
		const parallResponse = 
		Promise.all([
			penaltyInvoice(month, year, cityId),
			consolidateInvoice(month, year, cityId) ]);

		
		parallResponse.then((resp) => {
			
			resp[0]?.status && 
			$addToast({
				toastId : guid(),
				toastType : "success",
				message : resp[0]?.message
			})

			resp[1]?.status &&
			$addToast({
				toastId : guid(),
				toastType : "success",
				message : `Consolidate Invoice ${resp[1]?.message}`
			})

		}).catch((error) => {
			$addToast({
				toastId : guid(),
				toastType : "error",
				message : "Something went wrong"
			})
		}).finally(() => {
			setTimeout(() => {
				$removeAllToast();
			}, 4000)
		})

	}
	else{
		consolidateInvoice(month, year, cityId).then(resp => {
			resp?.status &&
			$addToast({
				toastId : guid(),
				toastType : "success",
				message : `Consolidate Invoice ${resp?.message}`
			})
		}).catch(() => {
			$addToast({
				toastId : guid(),
				toastType : "error",
				message : "Something went wrong"
			})
		}).finally(() => {
			setTimeout(() => {
				$removeAllToast();
			}, 4000)
		})
	}
	
	return;
};

export const downloadGarageRecon = async (
	month,
	year,
	orderType,
	activeOption,
	_appVersion = 1
) => {
	if (!activeOption) activeOption = "retail";
	const workShopId = getWorkShopId();
	let appService = null;
	if(_appVersion == 1) appService = download(
		`reconciliation/garage-wise/?month=${month}&year=${year}&order_filter=${orderType}&garage_id=${workShopId}&is_download=1&order_type=${activeOption}`
	)
	else appService = download(
		`reconciliation/v2/garage-wise/?month=${month}&year=${year}&order_filter=${orderType}&garage_id=${workShopId}&is_download=1&order_type=${activeOption}`
	)
	const res = await appService;
	return res ? res : null;
};

export const downloadGarageReconConsolidate = async (month, year, billType, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	const res = await download(
		`workshop/invoices/consolidate-invoice/?month=${month}&year=${year}&garage_id=${workShopId}&bill_type=${billType}&fetch_btob=${fetch_btob}`
	);
	return res ? res : null;
};

export const downloadPenaltyAndReward = async (month, year, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	const res = await download(
		`workshop/wallet/download/transaction_history/${workShopId}/?month=${month}&year=${year}&fetch_btob=${fetch_btob}`
	);
	return res ? res : null;
};

export const downloadGarageReconSpares = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`reconciliation/garage-wise/spares/?month=${month}&year=${year}&garage_id=${workShopId}&is_download=1`
	);
	return res ? res : null;
};

export const getReconOpeningBalance = async (dateYear) => {
	const workShopId = getWorkShopId();
	const [month,year]=dateYear.split("-")
	const res = await get(
		`reconciliation/settlement/opening-balance/?garage_id=${workShopId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : {};
};

export const recordReconOpeningBalance = async (payLoad, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	const res = await post(`reconciliation/settlement/opening-balance/`, {
		...payLoad,
		garage_id: workShopId,
		fetch_btob,
	});
	return res;
};

export const getRetailerOpeningBalance = async ({retailerId, month, year}) => {
	const res = await get(
		`reconciliation/settlement/retailer/opening-balance/?retailer_id=${retailerId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : {};
};

export const nnRecordReconOpeningBalance = async (payload) => {
	const workShopId = getWorkShopId();
	const res = await postNon(`nn-workshop/spares/recon/settlement/opening-balance/`, {
		...payload,
		garage_id : workShopId
	});
	return res;
}

export const nnGetReconOpeningBalance = async(dateYear) => {
	const [month,year]=dateYear.split("-")
	const workShopId = getWorkShopId();
	const res = await getNon(`nn-workshop/spares/recon/settlement/opening-balance/?garage_id=${workShopId}&month=${month}&year=${year}`);
	return res && res.data || {};
}

export const getAccessoriesListRetail = async () => {
	const res = await get(`retailer/accessories/categories/`);
	return res && res.data ? res.data : [];
};

export const getInventoryListRetail = async () => {
	const res = await get(`workshop/gom-inventory/`);
	return res && res.data ? res.data : [];
};

export const getLineItemsList = async (searchTerm, carId, dealId) => {
	const res = await get(
		`workshop/insurance/service_part_name/?version=1&value=${searchTerm}&car_id=${carId}&deal_id=${dealId}&package_car_id=0&app_version=1.9.5&from_garage_app=1&is_debug=true`
	);
	return res && res.data ? res.data : [];
};

export const getAlreadyOrderedLineItemsList = async (orderId) => {
	const res = await get(
		`workshop/spares/get/spare_items/?order_id=${orderId}&app_version=1.9.5&from_garage_app=1&is_debug=true`
	);
	return res && res.data ? res.data : [];
};

export const uncheckSendToCR = async (orderId) => {
	const res = await put(`dashboard/spares/uncheck/send/to/cr/${orderId}/`);
	return res && res.data ? res.data : [];
};

export const getOrderDetailsOfCurrentOrder = async (orderId) => {
	const res = await get(
		`workshop/orders/order-details/?order_id=${orderId}&limit=1`
	);
	return res && res.data ? res.data : [];
};

export const getOrderDetailsOfCurrentOrder2 = async (orderId) => {
	const res = await get(
		`workshop/order/estimate_order/?order_id=${orderId}&contract_type=103&is_gomechanic=1&hstOrderActId=0&app_version=1.9.5&from_garage_app=1&is_debug=true`
	);
	return res && res.data ? res.data : [];
};

export const getEstimateItemsList = async (orderId) => {
	const res = await get(
		`workshop/order/estimate_order/?order_id=${orderId}&hstOrderActId=0&is_gomechanic=1`
	);
	return res && res.data ? res.data : [];
};

export const apiForEstimatePayload = async (orderId, gmNum, cpmId) => {
	const res = await get(
		`workshop/order/insurance/detail_service_part_name/?order_id=${orderId}&gm_num=${gmNum}&version=1&cpm_id=${cpmId}&app_version=1.9.5&from_garage_app=1&is_debug=true`
	);
	return res && res.data ? res.data : [];
};

export const addLineItemsList = async (payload) => {
	const res = await post(`workshop/order/estimate_order/`, payload);
	return res && res.data ? res.data : [];
};

export const addFinalLineItemsList = async (payload) => {
	const res = await post(`workshop/spares/place_order/?from_dashboard=true`, payload);
	return res && res.data ? res.data : [];
};

export const sendNotificationToCR = async (
	name,
	crId,
	workshopName,
	orderId,
	regNo
) => {
	const res = await get(
		`workshop/notification/send_to_cr/?name=${name}&cr_id=${crId}&workshop_name=${workshopName}&order_id=${orderId}&reg_no=${regNo}&type=estimate&cr_mobile=&app_version=1.9.5&from_garage_app=1&is_debug=true`
	);
	return res && res.data ? res.data : [];
};

export const recordRetailOpeningBalance = async (payLoad) => {
	const res = await post(`reconciliation/settlement/retailer/advance-credit/`, {
		...payLoad,
	});
	return res;
};

export const recordRetailOpeningBalanceAdd = async (payLoad) => {
	const res = await post(
		`reconciliation/settlement/retailer/opening-balance/`,
		{
			...payLoad,
		}
	);
	return res;
};

export const processReconSettlement = async (payLoad, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	const res = await post(`reconciliation/settlement/payments/`, {
		...payLoad,
		garage_id: workShopId,
		fetch_btob,
	});
	return res;
};

export const nnProcessReconSettlement = async (payload) => {
	const workShopId = getWorkShopId();
	const res = 
	await postNon(`nn-workshop/spares/settlement-payment/`, 
	{
		...payload,
		garage_id : workShopId
	});
	return res;
}

// note - send payable_by = “garage” if amount>0 else “gomechanic”
// PROCESS SETTLEMENT API - 
// post - nn-workshop/spares/settlement-payment/
// payload-
// {
//     "garage_id": 1,
//     "type":2,
//     "month": 2,
//     "year": 2022,
//     "amount": 1218.4,
//     "remark": "test",
//     "paid_amount": 218.4,
//     "previous_outstanding": 2000,
//     "otp": 8080,
//     "payment_mode": 3,
//     "spare_order_ids": [
//         "GJ1/2022/00011"
//     ],
//     "fwd_txn_uuids": [
//         "793f2ab6-97d2-4537-9724-8784b06f3f2d",
//             "e1813312-883f-44d4-8d95-02a03c26b322",
//             "ef8d0161-033f-431f-8851-7fcf534d974d",
//             "1812bba4-a7c9-4bf9-9362-6b58bbd0cd94"
//     ],
//     "return_txn_uuids": [
//     ],
//     "payable_by":"garage",
//     "uuid":"8881f153-87fa-4ce7-a627-b94894f9e134"
// }

export const processRetailSettlement = async (payLoad) => {
	const res = await post(`reconciliation/settlement/retailer/payments/`, {
		...payLoad,
		cashback_reward: 0
	});
	return res;
};

export const getCitiesOfRegion = async (region, isNonNetwork = false) => {
	const checkIfNonNetworkUser = isNonNetowrkUser();
	const httpService = (checkIfNonNetworkUser || isNonNetwork) ? getNon : get;
	const res = await httpService(`reconciliation/cities/${region}/`);
	return res && res.data ? res.data : [];
};



export const getAllCities = async (param = "") => {
	const res = await get(`reconciliation/cities/all/${param}`);
	return res && res.data ? res.data : [];
};

export const getReconEscalations = async (
	escalatedBy,
	page,
	page_limit = 5
) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`reconciliation/settlement/escalated-orders/?escalated_by=${escalatedBy}&garage_id=${workShopId}&page=${page}&page_limit=${page_limit}`
	);
	return res && res.data ? res.data : [];
};

export const searchReconEscalations = async (
	orderData,
	page,
	page_limit = 5
) => {
	const res = await get(
		`reconciliation/settlement/escalated-orders/?page=${page}&page_limit=${page_limit}&search=${orderData}`
	);
	return res && res.data ? res.data : [];
};

export const getReconEscalationBillDetails = async (orderId) => {
	const res = await get(
		`reconciliation/settlement/escalated-orders/detail/?order_id=${orderId}`
	);
	return res && res.data ? res.data : [];
};

export const resolveReconEscalationBill = async (orderId) => {
	const res = await put(
		`reconciliation/settlement/escalated-orders/update/${orderId}/`
	);
	return res && res.data ? res.data : [];
};

export const saveReconEscalationBill = async (billData) => {
	const res = await post(
		`reconciliation/settlement/escalated-orders/request/`,
		billData
	);
	return res && res.data ? res.data : [];
};

export const getDiscountTakeRateSlabs = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`reconciliation/settlement/slab-line-item/?garage_id=${workShopId}&month=${month}&year=${year}`
	);
	return res && res.data ? res.data : {};
};

export const updateDiscountTakeRateSlabs = async (payLoad) => {
	const workShopId = getWorkShopId();
	const res = await post(`reconciliation/settlement/slab-line-item/`, {
		...payLoad,
		garage_id: workShopId,
	});
	return res && res.data ? res.data : [];
};

export const updateDiscountReconInsurance = async (payLoad) => {
	const workShopId = getWorkShopId();
	const res = await post(`reconciliation/insurance/recon-rate/`, {
		...payLoad,
		garage_id: workShopId,
	});
	return res && res.data ? res.data : [];
};

export const getDiscountTakeRateLineItems = async (item) => {
	const res = await get(
		`workshop/order/insurance/new_service_part_name/?value=${item}`
	);
	return res && res.data ? res.data : [];
};

export const requestReconSettlements = async (actionType, payLoad) => {
	const res = await put(
		`reconciliation/settlement/orders/${actionType}/`,
		payLoad
	);
	return res;
};

// All Account Request
export const allAccounts = async (acTivePage, onePageRows, contactType) => {
	let url = `reconciliation/settlement/all/bank-details/?page=${acTivePage}&page_limit=${onePageRows}&contact_type=${contactType}`;
	const res = await get(url);
	return res && res.data ? res.data : [];
};

const checkNullEmptyString = (val) => {
	if (val != "" && val != null) {
		return true;
	}
	return false;
};
// All Garage  Payments
export const garagePayments = async (
	date,
	endDate,
	acTivePage,
	onePageRows,
	paymentStatus,
	searchBy,
	searchParam
) => {
	let url = `reconciliation/settlement/payments/garage-to-gom/?start_date=${date}&end_date=${endDate}&page=${acTivePage}&page_limit=${onePageRows}`;
	if (checkNullEmptyString(paymentStatus))
		url += `&payment_status=${paymentStatus}`;
	if (checkNullEmptyString(searchBy)) url += `&search_by=${searchBy}`;
	if (checkNullEmptyString(searchParam)) url += `&search_param=${searchParam}`;
	const res = await get(url);
	return res && res.data ? res.data : [];
};

/* 
  Recon Request
  requestType = "retail-network" , "recon" , "retail-non-network"
*/
export const searchPaymentsRequest = async (
	date,
	endDate,
	acTivePage,
	onePageRows,
	city,
	region,
	vendorInvoiceType = null,
	requestStatus,
	paymentStatus,
	searchBy,
	searchParam,
	page_limit = 5,
	requestType="",
	order_of_days_diff = 1,
	order_of_request_date = 1
) => {
	let url = `reconciliation/settlement/get-all-recon-request/?start_date=${date}&end_date=${endDate}&page=${acTivePage}&page_limit=${onePageRows}`;
	if(requestType === "recon") {
		url = `reconciliation/settlement/get-all-recon-request/?start_date=${date}&end_date=${endDate}&page=${acTivePage}&page_limit=${onePageRows}&order_of_days_diff=${order_of_days_diff}&order_of_request_date=${order_of_request_date}`;	
	} 
	if(requestType === "retail-network") {
		url = `reconciliation/settlement/retailer/get-all-payment-request/?start_date=${date}&end_date=${endDate}&page=${acTivePage}&page_limit=${onePageRows}&order_of_days_diff=${order_of_days_diff}&order_of_request_date=${order_of_request_date}`;
	}
	if(requestType === "retail-non-network") {
		url = `nn-dashboard/spares/get/all/requests/for/payment/?start_date=${date}&end_date=${endDate}&page=${acTivePage}&page_limit=${onePageRows}`;
	}
	if (checkNullEmptyString(region)) url += `&region=${region}`;
	if (checkNullEmptyString(city)) url += `&city_id=${city}`;
	if (checkNullEmptyString(requestStatus))
		url += `&request_status=${requestStatus}`;
	if (checkNullEmptyString(paymentStatus))
		url += `&payment_status=${paymentStatus}`;
	if (checkNullEmptyString(searchBy)) url += `&search_by=${searchBy}`;
	if (checkNullEmptyString(searchParam)) url += `&search_param=${searchParam}`;
	if(!!vendorInvoiceType) url+=`&invoice_type=${vendorInvoiceType}`;
	let res;

	if(requestType === "retail-non-network") {
		res = await getNon(url);
	} else {
		res = await get(url);
	}
	return res && res.data ? res.data : [];
};

/* GET payment rejection reason list */
export const getNNRPRejectionList = async (requestType) => {
	let url;
	if(requestType == "recon") url = `nn-workshop/spares/payment-rejection-dropdown/`;
	else url = `nn-dashboard/spares/retailer/payment-rejection/dropdown-options/` 
	let response = await getNon(url);
	return response && response?.data || [];
} 

export const getExpirationList = async () => {
	const response = await get("reconciliation/settlement/rejection-reasons/");
	return response && response?.data || [];
}

/* POST Confirm Rejection Reason API */
export const confirmNNRPReject = async (payload, requestType) => {
	let url;
	if(requestType == "recon") url = `nn-workshop/spares/recon/reject-payment-request/`;
	else url = `nn-dashboard/spares/retailer/reject-payment-request/confirm/`;
	let response = await postNon(url, payload);
	return response && response?.data || null;
}

export const confirmFinancePayReject = async (payload) => {
	const response = await post(`reconciliation/settlement/reject-payment-request/`, payload);
	return response && response?.data || null;
}

export const confirmFinanceReconReject = async (payload) => {
	const response = await post('reconciliation/settlement/reject-recon-request/', payload);
	return response && response?.data || null;
}

export const raiseRequestNetworkFinance = async (payload) => {
	const response = await post(`reconciliation/settlement/reissue-payment-request/`, payload);
	return response && response?.data || null
}


export const raiseRequestReconFinance = async (payload) => {
	const response = await post(`reconciliation/settlement/reissue-recon-request/`, payload);
	return response && response?.data || null
}

export const toggleFinPayoutMode = async (payload) => {
	const response = await post(`toggle/garage-recon-request/`, payload);
	return response && response.data || null;
}


// non network retail payment
// Get Garage Account Details

export const getAccountDetails = async (id, requestType, startDate, endDate) => {
	let url = `reconciliation/settlement/bank-details/?garage_id=${id}`; 
	if(requestType === "malaysia-recon"){
		url = `reconciliation/settlement/all/bank-details/?garage_id=${id}`;
		url +=`&start_date=${startDate}&end_date=${endDate}&page=1&limit=10`;
	}
	if(requestType === "recon") {
		url = `reconciliation/settlement/bank-details/?garage_id=${id}`; 
	} 
	if(requestType === "retail-network"){
		url = `reconciliation/settlement/network-retailer/bank-details/?retailer_id=${id}`;
	}
	if(requestType === "retail-non-network") {
		url = `reconciliation/settlement/non-network-retailer/bank-details/?retailer_id=${id}`;
	}

	const res = await get(url);
	return res && res.data ? res.data : [];
};

export const addNewAccount = async (payload) => {
	const res = await post(
		`reconciliation/settlement/razorpay/add-new-account/
	`,
		payload
	);
	return res && res.data ? res.data : [];
};

export const editAccountInformation = async (payload) => {
	let url = `reconciliation/settlement/razorpay/update-contact/`;
	const res = await put(url, payload);
	return res && res.data ? res.data : [];
};

// Pay out

export const payout = async (data, requestType) => {
	let url = `reconciliation/settlement/payment/payout/`;
	if(requestType === "recon") {
		url = `reconciliation/settlement/payment/payout/`;
	}
	if(requestType === "retail-network") {
		url = `reconciliation/settlement/gom-to-network-retailer/payment/payout/`;
	}
	if(requestType === "retail-non-network") {
		url = `reconciliation/settlement/gom-to-non-network-retailer/payment/payout/`
	}
	const res = await post(url, data);
	return res && res.data ? res.data : [];
};

/** ========Spare Order APIs============================= */

export const getCancellationReasonForSparesOrder = async () => {
	let res = await getNon(`nn-dashboard/spares/spare_order/cancel/`)
	return res?.data ?? [];
}

export const getSparesWorkshopList = async () => {
	const res = await getNon(`nn-workshop/spares/billing_address/`);
	return res?.data ?? [];
}

export const changeWorkshop = async (payload) => {
	const res = await postNon('nn-workshop/spares/billing_address/', payload);
	return res;
}

export const cancelSparesOrders = async (payload) => {
	const res = await postNon(`nn-dashboard/spares/update/status/parts_not_available/${payload?.spare_order_id}/`);
	const res1 = await postNon('nn-dashboard/spares/spare_order/cancel/', payload);
	return res1;
}


export const getSparesOrderList = async (
	region,
	city,
	startDate,
	endDate,
	page,
	limit = 10,
	isCarOrder,
	spareId = "",
	isAllGarages,
	isDeliveredOrder,
	orderStatus,
	brand = "",
	ageing = ""
) => {
	let workShopId = getWorkShopId();
	// if (isAllGarages) workShopId = ""
	if (workShopId == 1001001) workShopId = "";
	if (spareId) {
		startDate = "";
		endDate = "";
	}
	let queryStr = "";
	if (isDeliveredOrder) queryStr = `${queryStr}&is_delivered=1`;
	if(orderStatus) queryStr = `${queryStr}&filter_type=${orderStatus}`;
	else queryStr = ``;
	const res = await setNetworkCallGet(
		`dashboard/spares/orders/?direction=${region}&cities=${city}&start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}&is_car_order=${isCarOrder}&garage_id=${workShopId}&spare_order_id=${spareId}&brand_filter=${brand}&days_filter=${ageing}${queryStr}`
	);
	// const res = await get(
	//     `dashboard/spares/orders/?direction=${region}&cities=${city}&start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}&is_car_order=${isCarOrder}&garage_id=${workShopId}&spare_order_id=${spareId}`
	// );
	return res ? res : null;
};

export const singleOrderData = async (orderId, activeTab) => {
	const res = await setNetworkCallGet(
		`workshop/spares/order/${orderId}?user_type=${activeTab}`
	);
	return res ? res : null;
};

export const cityList = async () => {
	const res = await setNetworkCallGet(`dashboard/spares/cities/`);
	return res ? res : null;
};

export const getCitiesAll = async () => {
	const res = await setNetworkCallGet(`reconciliation/cities/all/`);
	return res ? res.data : null;
};

export const cityListForRegion = async (direction) => {
	const res = await setNetworkCallGet(`dashboard/spares/cities/${direction}`);
	return res ? res : null;
};

export const downloadReviewPhotos = async (uuid) => {
	const res = await setNetworkCallDownload(
		`dashboard/spares/download/images/${uuid}/`
	);
	return res ? res : null;
};

export const saveDataAfterReview = async (uuid, payload) => {
	const res = await setNetworkCallPut(
		`dashboard/spares/item/${uuid}/`,
		payload
	);
	return res ? res : null;
};

export const fetchDataPartNumber = async (part_number, uuid) => {
	const res = await setNetworkCallGet(
		`dashboard/spares/get/details/by/part_no/${part_number}/?uuid=${uuid}`
	);
	return res ? res : null;
};

export const saveDataAfterReviewGlobal = async (payload, isNonRole) => {
	
	if(payload && isNonRole){
		// const mainKey = 'vendor_name';
		const mandatoryKeys = [
			'vendor_name',
			'mrp',
			'tax_rate',
			'discount',
			'vendor_discount',
			'hsn_code',
			'purchase_with_tax',
			'invoice_no',
			'quantity',
			'etd',
			'sku_code',
			'sku_desc',
			'quantity',
			'part_type',
			'pickup_date',
			'sku_hsn_code',
		];
		// console.log('editAndSaveDataGlobal',payload);
		// let count = 0;
		let validate = true;
		payload.map((item, index) =>{
			if(item['si_status_id'] === 35){
				let keys = Object.keys(item);
				for(var i = 0; i < keys.length; i++){
					let value = keys[i];
					if(validate && mandatoryKeys.indexOf(value) !== -1){
						if(!item[value] && (value !== "mrp" || value !== "purchase_with_tax" || value !== "vendor_name")){
							validate = false;
							break;
						}
						else if(value === "vendor_name" && (item[value] === "Select Vendor" || !item[value])){
							validate = false;
							break;
						}
						else if((value === "mrp" || value === "purchase_with_tax") && (!item[value] || item[value] == 0 || item[value] == "0.0")){
							validate = false;
							break;
						}else{
						}
					}
				}
			}
			if(item['si_status_id'] === 15 || item['si_status_id'] === 30){
				if(typeof item['purchase_with_tax'] === "string" && !item['purchase_with_tax']){
					validate = false;
				}
				if(typeof item['mrp'] === "string" && !item['mrp']){
					validate = false;
				}
				if(typeof item['purchase_with_tax'] === "number" && item['purchase_with_tax'] < 0){
					validate = false;
				}
				if(typeof item['mrp'] === "number" && item['mrp'] < 0){
					validate = false;
				}
			}
	if (item['si_status_id'] === 105 && item['return_bill_no']  === null && item['is_return_download'] === null && item['is_download'] === true ){
		item['is_download']  = false
	} else if (item['si_status_id'] === 105 && item['return_bill_no']  === null && item['is_return_download'] === false && item['is_download'] === true ){
		item['is_return_download']  = true
	} else if(item['si_status_id'] === 105 && item['return_bill_no']  !== null){
		item['is_return_download']  = true
		item['is_download']  = true
	}
		});
		if(!validate){
			store.dispatch(
				setToastData({
					message: "Please fill all fields",
					status: false,
				})
			);
			return {"status":false, "message": "Mandatory fields"};
		}
	}
	const res = await setNetworkCallPut(
		`dashboard/spares/bulk-update/item/
	`,
		payload
	);
	return res ? res : null;
};

export const placeOrderWarehouse = async (payload) => {
	let nonNetworkServer;
	if (
		process.env.REACT_APP_API_URL.includes("dev") ||
		process.env.REACT_APP_API_URL.includes("staging")
	) {
		nonNetworkServer = 'dev';
	} else {
		nonNetworkServer = 'prod';
	}
	const res = await postNon(
		`/nn-dashboard/spares/place/order/in/warehouse/?app_version=1.4.2&from_garage_app=1&channel=nn_garage&server=nn_${nonNetworkServer}
	`,
		payload
	);
	return res ? res : null;
};

export const downLoadExcelForSpares = async (spareId) => {
	const res = await setNetworkCallDownload(
		`dashboard/spares/download/excel/${spareId}/`
	);
	return res ? res : null;
};

export const downLoadPdfForSpares = async (spareId) => {
	const res = await setNetworkCallDownload(
		`workshop/spares/download-bill/order-wise/${spareId}/`
	);
	return res ? res : null;
};

export const saleInvoiceDownload = async (orderId) => {
	const res = await downloadWFAPP(`v1/oauth/admin/surveyor_bill_pdf?order_id=${orderId}&estimate_flag=0&is_pdf=1`);
	return res ?? null;
}

export const customerOrderSummaryDownload = async (orderId) => {
	const res = await downloadWFAPP(`v1/oauth/admin/get_bill?order_id=${orderId}&estimate_flag=0&is_pdf=1`);
	return res ?? null;
}

export const purchaseInvoiceDownload = async (orderId) => {
	const res = await downloadWFAPP(`v1/oauth/admin/get_purchase_bill?order_id=${orderId}&is_pdf=1`);
	return res ?? null;
}

export const downloadPdfForBilledOrdersGoMech = async (orderId, isAuditTaxInvoice) => {
	let queryStr="";
	if(isAuditTaxInvoice){
		queryStr=``
	}else{
		queryStr=`&user_flag=finance`
	}
	const res = await setNetworkCallWFAPPDownload(
		// `v1/oauth/admin/get_bill?order_id=${orderId}&is_pdf=1${queryStr}`
		`workshop/order/get_bill/?order_id=${orderId}&is_pdf=1&estimate_flag=0${queryStr}`
	);
	return res ? res : null;
};

export const downloadNetworkEcoBillOrderGoM = async(orderId) => {
	return await download(`workshop/order-summary/network-ecosystem/?order_id=${orderId}`)
}

export const downLoadPdfForMobil = async (orderId) => {
	const res = await setNetworkCallDownload(
		`workshop/mobil/orders/bill?order_id=${orderId}`
	);
	return res ? res : null;
};

export const downLoadVendorBill = async (spareId) => {
	const res = await setNetworkCallDownload(
		`dashboard/spares/download/vendor_bill/${spareId}/`
	);
	return res ? res : null;
};
export const downLoadNNVendorBill = async (billZipPath) => {
	const res = await downloadNon(billZipPath);
	return res ? res : null;
};

export const downloadNNPaymentScreenshotRecon = async (urlPath) => {
	const res = await downloadNon(urlPath);
	return res ? res : null;	
}

export const downLoadRunnerBill = async (spareId) => {
	const res = await setNetworkCallDownload(
		`workshop/spares/download-running-bill/order-wise/${spareId}/`
	);
	return res ? res : null;
};

export const getVendorsList = async () => {
	const res = await setNetworkCallGet(`dashboard/spares/vendors/`);
	return res ? res : null;
};

export const freezeOrder = async (spareId) => {
	const res = await setNetworkCallPut(
		`dashboard/spares/freeze_order/${spareId}/`
	);
	return res ? res : null;
};

export const unfreezeOrder = async (spareId) => {
	const res = await setNetworkCallPut(
		`dashboard/spares/unfreeze_order/${spareId}/`
	);
	return res ? res : null;
};

export const sendToProcurement = async (spareId, city) => {
	let queryStr;
	checkUserType()
		? (queryStr = `${spareId}/${city}/`)
		: (queryStr = `${spareId}/${city}/`);
	const res = await setNetworkCallPut(
		`dashboard/spares/update/status/open/into/procuring/${queryStr}`
	);
	return res ? res : null;
};

export const downloadAndShowRC = async (orderId) => {
	const res = await setNetworkCallDownload(
		`dashboard/spares/download/rc/${orderId}/`
	);
	return res ? res : null;
};

export const addVendorDetails = async (name, location) => {
	const res = await setNetworkCallPost(
		`dashboard/spares/vendor/addition/procurement/`,
		{
			name,
			location,
		}
	);
	return res ? res : null;
};

export const deleteSparesOrder = async (spareId) => {
	const res = await setNetworkCallPost(
		`dashboard/spares/update/status/parts_not_available/${spareId}/`
	);
	return res ? res : null;
};

export const sendBillToWorkShop = async (spareId) => {
	const res = await setNetworkCallGet(`workshop/spares/send-bill/${spareId}/`);
	store.dispatch(
		setToastData({
			message: res ? res.message : "Something Went Wrong",
			status: res ? res.status : "",
		})
	);
	return res ? res : null;
};

export const notifyPrices = async (spareId) => {
	const res = await setNetworkCallGet(
		`dashboard/spares/notify/garage/${spareId}/`
	);
	store.dispatch(
		setToastData({
			message: res ? res.message : "Something Went Wrong",
			status: res ? res.status : "",
		})
	);
	return res ? res : null;
};

export const getSparesHistory = async (uuid) => {
	const res = await setNetworkCallGet(`dashboard/spares/history/items/${uuid}`);
	return res ? res : null;
};

/**==================== spares/insurance settlement =========================== */
export const getReconTakeRateList = async (month, year, orderType) => {
	const workShopId = getWorkShopId();
	const res =
		await get(`reconciliation/insurance/recon-rate/?garage_id=${workShopId}&month=${month}&year=${year}
	`);
	return res ? res : null;
};

export const postReconTakeRateList = async (payload) => {
	const res = await post(`reconciliation/insurance/recon-rate/`, payload);
	return res ? res : null;
};

export const downLoadPdfForGOM = async (type, month, year) => {
	const workShopId = getWorkShopId();
	const res = await download(
		`workshop/settlement/inventory/?garage_id=${workShopId}&month=${month}&year=${year}&download_bill=1&type=${type}`
	);
	return res ? res : null;
};

export const sendBillSummary = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`reconciliation/settlement/approved-orders/summary/mail/?garage_id=${workShopId}&month=${month}&year=${year}`
	);
	store.dispatch(
		setToastData({
			message: res ? res.message : "Something Went Wrong",
			status: res ? res.status : "",
		})
	);
	return res ? res : null;
};

export const getBrandsList = async (make) => {
	const res = await get(`workshop/spares-brands/list/?brand_type=${make}`);
	return res ? res : null;
};

export const getPaymentsList = async (orderId) => {
	const res = await get(`reconciliation/order/payments/?order_id=${orderId}`);
	return res ? res : null;
};

export const editPaymentsList = async (orderId, payload) => {
	const res = await put(
		`reconciliation/order/payments/?order_id=${orderId}`,
		payload
	);
	return res ? res : null;
};

export const downLoadSampleForSpares = async (carOrBulk) => {
	const res = await setNetworkCallDownload(
		`workshop/spares/${carOrBulk}/bulk_update`
	);
	return res ? res : null;
};

export const downLoadSampleForSparesOrders = async (
	carOrBulk,
	userType,
	city,
	startDate,
	endDate,
	region,
	orderStatus,
	) => {
	let workShopId = getWorkShopId();
	if (workShopId === 1001001) workShopId = "";	
	let queryStr=``;
	
	queryStr = city.length ? `${queryStr}&cities=${city}`: queryStr;
	queryStr = startDate.length ? `${queryStr}&start_date=${startDate}`: queryStr;
	queryStr = endDate.length ? `${queryStr}&end_date=${endDate}` : queryStr;
	queryStr = region.length ? `${queryStr}&direction=${region}` : queryStr;
	queryStr = orderStatus.length ? `${queryStr}&filter_type=${orderStatus}` : queryStr;	
	// console.log(`dashboard/spares/download/excel_orders/?order_type=${carOrBulk}&user_type=${userType}&garage_id=${workShopId}&${queryStr}`)
	const res = await setNetworkCallDownload(
		`dashboard/spares/download/excel_orders/?order_type=${carOrBulk}&user_type=${userType}&garage_id=${workShopId}&${queryStr}`
	);
	return res ? res : null;
};

export const uploadSampleForSpares = async (file) => {
	const res = await setNetworkCallPost(`workshop/spares/1/bulk_update`, file);
	store.dispatch(
		setToastData({
			message: res ? res.message : "Something Went Wrong",
			status: res ? res.status : "",
		})
	);
	return res ? res : null;
};

export const addRetailer = async (retailerUserDetails) => {
	const res = await setNetworkCallPostFormData(
		`retailer/add/retailer/`,
		retailerUserDetails
	);
	return res;
};


export const updateRetailer = async (retailerUserDetails) => {
	const res = await putFormData(`retailer/update/data/`, retailerUserDetails);
	return res;
};

export const updateRetailerVisiblityStatus = async (data) => {
	const res = await put(`retailer/update/status/`, data);
	return res;
};

/** Spares Dashboard */

export const getTransactionHistoryCityWise = async (
	month,
	year,
	id,
	page_num,
	page_limit,
	transaction_type,
	city_id,
	search = null
) => {
	let queryStr = `month=${month}&year=${year}`;
	if (id) queryStr = `${queryStr}&retailer_id=${id}`;
	if (page_num) queryStr = `${queryStr}&page=${page_num}`;
	if (page_limit) queryStr = `${queryStr}&page_limit=${page_limit}`;
	if (transaction_type) queryStr = `${queryStr}&transaction_type=${transaction_type}`;
	if (city_id) queryStr = `${queryStr}&city_id=${city_id}`;
	if (search) queryStr = `${queryStr}&search=${search}`;

	const res = await get(`retailer/garage/txn-hst/?${queryStr}`);
	return res ? res : null;
};

export const getRecievedPaymentTransactions = async (retailer_id) => {
	const res = await get(`retailer/payments-list/?retailer_id=${retailer_id}`);
	return res ? res : null;
};

export const getUnverifiedGarageTransactionInfo = async (
	garage_id,
	latitude,
	longitude,
	city_id,
	mobil_order_id
) => {
	let requestStr = `${garage_id ? `garage_id=${garage_id}` : `mobil_order_id=${mobil_order_id}`}`;
	if (latitude) requestStr = `${requestStr}&r_latitude=${latitude}`;
	if (longitude) requestStr = `${requestStr}&r_longitude=${longitude}`;
	if (city_id) requestStr = `${requestStr}&city_id=${city_id}`;
	if (mobil_order_id && garage_id) requestStr = `${requestStr}&mobil_order_id=${mobil_order_id}`;
	const res = await get(`retailer/garage/info/?${requestStr}`);
	return res ? res : null;
};

export const getUnverifiedPlReturnGarageTransactionInfo = async (
	garage_id,
	latitude,
	longitude,
	city_id,
	spare_order_id
) => {
	let requestStr = `${garage_id ? `garage_id=${garage_id}` : `spare_order_id=${spare_order_id}`}`;
	if (latitude) requestStr = `${requestStr}&r_latitude=${latitude}`;
	if (longitude) requestStr = `${requestStr}&r_longitude=${longitude}`;
	if (city_id) requestStr = `${requestStr}&city_id=${city_id}`;
	if (spare_order_id && garage_id) requestStr = `${requestStr}&spare_order_id=${spare_order_id}`;
	const res = await get(`retailer/garage/info/?${requestStr}`);
	return res ? res : null;
};

export const getSpareSellOtp = 
	async (garage_id, 
		   spare_amount_to_sold,
		   transaction_type = null,
		   no_of_parts = null) => {	   
		const res = await getV2(
			`retailer/garage/spares-otp?garage_id=${garage_id}&spare_amount_to_sold=${spare_amount_to_sold}${transaction_type ? `&transaction_type=${transaction_type}`: ""}${no_of_parts ? `&no_of_parts=${no_of_parts}` : ""}`
		);
		store.dispatch(
			setToastData({
				message: res ? res.message : "Something Went Wrong",
				status: res ? res.status : "",
			})
		);
		return res ? res : null;
};

export const verifySpareSellOtp = async (mobile = null, spareAmount = null, payload) => {
	let workshopId = getWorkShopId();
	let queryParams = "";
	mobile && (queryParams = `${queryParams}mobile=${mobile}&`);
	spareAmount && (queryParams = `${queryParams}spare_amount_to_sold=${spareAmount}&`);
	// const res = await post(`retailer/garage/spares-otp/?${queryParams}garage_id=${workshopId}`, payload);
	const res = await post(`retailer/garage/spares-otp/?${queryParams}`, payload);
	return res;
};

export const verifyReturnSparesOTP = async (payload) => {
	const res = await post(`retailer/garage/return-item/verify-otp/`, payload);
	return res;
};

export const fetchWorkshopsInventory = async () => {
	const res = await get(`workshop/gom-inventory/`);
	return res ? res : null;
};

export const getPendingSettlementDateWiseAPI = async (month, year) => {
	const res = await get(`retailer/mobil-data/?month=${month}&year=${year}`);
	return res ? res : null;
};

export const getMobilDownloadingContentAPI = async (order_id) => {
	const res = await download(
		`workshop/mobil/orders/bill/?order_id=${order_id}`
	);
	return res ? res : null;
};

export const getMobilTaxInvoice = async (order_id) => {
	const res = await download(
		`retailer/garage/tax-invoices/?mobil_order_id=${order_id}`
	);
	return res ? res : null;
};


export const getListOfSpareRetailers = async (vendorType) => {
	const res = await get(
		`workshop/spare-retailers/?all_retailers=1&vendor_type=${vendorType}&retailer_type=${vendorType}`
	);
	return res ? res : null;
};

export const getAllRetailers = async () => {
	const res = await get(`retailer/all-retailers/`);
	return res ? res : null;
};

export const getPurchaseSettlement = async (retailerId, month, year) => {
	const res = await get(
		`reconciliation/settlement/retailer/purchase/?retailer_id=${retailerId}&month=${month}&year=${year}&page=1&page_limit=10`
	);
	return res ? res : null;
};

export const getMobilOrders = async (month, year) => {
	const workShopId = getWorkShopId();
	const res = await get(
		`workshop/mobil/orders/?month=${month}&year=${year}&page=1&page_limit=20&garage_id=${workShopId}`
	);
	return res ? res.data : null;
};

export const savePurchaseEntryData = async (
	payload,
	retailerId,
	retailerName,
	retailerCity,
	month,
	year
) => {
	let token = "Bearer " + localStorage.getItem("garageToken");

	const headers = {
		// Accept: "application/json",
		// "Content-Type": "application/json",
		Authorization: token,
	};

	const fileData = new FormData();
	fileData.append("purchase_bill", payload.selectedInvoice);
	fileData.append("retailer_id", retailerId);
	fileData.append("month", month);
	fileData.append("year", year);
	fileData.append("retailer_name", retailerName);
	fileData.append("taxable_amount", payload.taxable_amount);
	fileData.append("tax_amount", payload.tax_amount);
	fileData.append("bill_amount", payload.bill_amount);
	fileData.append("invoice_date", payload.invoice_date);
	fileData.append("invoice_no", payload.invoice_no);
	fileData.append("city_id", retailerCity);

	let queryString;

	let queryStringDev = `https://staging.garage.gomechanic.app/reconciliation/settlement/retailer/purchase/`;
	let queryStringProd = `https://garage.gomechanic.app/reconciliation/settlement/retailer/purchase/`;

	if (
		process.env.REACT_APP_API_URL.includes("dev") ||
		process.env.REACT_APP_API_URL.includes("staging")
	) {
		queryString = queryStringDev;
	} else {
		queryString = queryStringProd;
	}

	//   const res = await post(
	//     `reconciliation/settlement/retailer/purchase/`,
	//     fileData
	//   );
	let res = fetch(queryString, {
		method: "POST",
		headers: headers,
		body: fileData,
	})
		.then((response) => response.json())
		.then((data) => {
			// setLoader(false)
			//   setFileUploaded(true);
			store.dispatch(
				setToastData({
					message: data ? data.message : "Something Went Wrong",
					status: data ? data.status : "",
				})
			);
			return data;
			//   window.location.reload();
		})
		.catch((error) => {
			// setLoader(false)
			console.error(error);
		});
	return res;
};

export const downLoadPurchaseBill = async (invoiceNumber) => {
	const res = await download(
		`reconciliation/settlement/retailer/download-purchase/${invoiceNumber}/`
	);
	return res ? res : null;
};

export const getSparesMonthYearWiseAPI = async (
	month,
	year,
	page_num,
	page_limit
) => {
	let queryStr = `month=${month}&year=${year}`;
	if (page_num) queryStr = `${queryStr}&page=${page_num}`;
	if (page_limit) queryStr = `${queryStr}&page_limit=${page_limit}`;
	const res = await get(`retailer/spares-data/bulk-orders/?${queryStr}`);
	return res ? res : null;
};

export const getSparesDownloadingContentAPI = async (order_id) => {
	const res = await download(
		`workshop/spares/download-bill/order-wise/${order_id}/`
	);
	return res ? res : null;
};
export const getSparesRetailerInvoiceDownloadingContentAPI = async (order_id) => {
	const res = await download(
		`workshop/spares/download-bill/order-wise/${order_id}/?retailer_invoice=1`
	);
	return res ? res : null;
};

export const getMobileRetailerTaxInvoice = async(order_id) => 
await download(`workshop/mobil/orders/bill/?purchase_invoice=true&order_id=${order_id}&tax_invoice=1`);

export const getMobilGarageTaxInvoice = async(order_id) => 
await download(`retailer/garage/tax-invoices/?mobil_order_id=${order_id}`);

export const getAllClusters = async () => {
	const res = await get(`dashboard/spares/clusters`);
	return res ? res : null;
};
/** *** Spares Dashboard END *** */

export const preViewRC = async (orderId) => {
	const res = await get(`dashboard/spares/preview/rc/${orderId}/`);
	return res ? res : null;
};

// Mobil Order APIS////////////////////////=======================================
export const getMobilVariants = async (id) => {
	const res = await get(`workshop/mobil/variant/list/?workshop_id=${id}`);
	return res ? res : null;
};

export const placeMobilOrder = async (payload, id) => {
	const res = await post(`workshop/mobil/place_order/`, {
		...payload,
		workshop_id: id,
	});
	return res ? res : null;
};

// Spares Analysis Apis Start here //
export const getCarModelsForAnalysis = async (workshopId, days) => {
	const res = await get(
		`workshop/spares/analytics/dashboard/?workshop_id=${workshopId}&days=${days}`
	);
	return res ? res : null;
};

export const getPercentageDataForAnalysis = async (
	workshopId,
	carId,
	days,
	year_of_mfg = ""
) => {
	const res = await get(
		`workshop/spares/analytics/details/?workshop_id=${workshopId}&car_type_id=${carId}&days=${days}&year_of_mfg=${year_of_mfg}`
	);
	return res ? res : null;
};

// Line Item Selection APIS
export const getLineItemsForSparesAccessories = async () => {
	const res = await get(`retailer/garage/fetch/spare_parts/`);
	return res ? res : null;
};

export const getSearchDataForCar = async (query) => {
	const res = await getPartsRocket(
		`api/v1/part/search?query=${query}&type=typing`
	);
	return res ? res : null;
};

export const getSearchDataForCarEnter = async (query) => {
	const res = await getPartsRocket(
		`api/v1/part/search?query=${query}&type=return_key`
	);
	return res ? res : null;
};

export const getCategoriesDataForVariants = async (category, c_id) => {
	const res = await getPartsRocket(
		`api/v1/part/parts?category=${category}&compatibility_group_id=${c_id}`
	);
	return res ? res : null;
};

export const getPaymentsReconReport = async (start_date, end_date, is_download, requestType) => {
	let url = '';
	if(requestType === "recon") {
		url = `reconciliation/settlement/get-all-recon-request/?start_date=${start_date}&end_date=${end_date}&is_download=${is_download}`;
	}
	if(requestType === "retail-network") {
		url = `reconciliation/settlement/retailer/get-all-payment-request/?start_date=${start_date}&end_date=${end_date}&is_download=${is_download}`;
	}
	const res = await download(url);
	return res ? res : null;
};

export const getNonNetworkRetailPaymentRequestsReport = async (start_date, end_date, is_download) => {
	const res = await downloadNon(
		`nn-dashboard/spares/get/all/requests/for/payment/?start_date=${start_date}&end_date=${end_date}&is_download=${is_download}`
	);
	return res ? res : null;
}

export const getSendPaymentRequestDetails = async (spareOrderId) => {
	// const workShopId = getWorkShopId();
	const res =
		await getNon(`nn-dashboard/spares/make/request/for/payment?spare_order_id=${spareOrderId}`);//HR3/2021/00191
	return res ? res : null;
};

export const raisePaymentRequest = async (payload) => {
	let url = `reconciliation/settlement/retailer/make-payment-request/`;
	const res = await post(url, payload);
	return res;
}

export const postSendPaymentRequestDetails = async (month,year,remarks,orderId,sparesPaymentData,rowGarageId) => {
	const payload={}
	const workShopId = rowGarageId ? rowGarageId : getWorkShopId();
	payload['garage_id']=workShopId;
	payload['month']=month+1;
	payload['year']=year;
	payload['remarks']=remarks;
	payload['spare_order_id']=orderId;
	payload['data']=sparesPaymentData; 
	const res = await postNon(`nn-dashboard/spares/make/request/for/payment/`,payload);	
	return res && res.data ? res.data : [];
};

export const getInvoiceList = async (
	page,
	limit = 10,	
	spare_order_id,
	) => {
	let queryStr = "";
	const res = await setNetworkCallGet(`dashboard/spares/invoices/?page=${page}&limit=${limit}&spare_order_id=${spare_order_id}${queryStr}`);	
	return res ? res : null;
};

export const downloadAllBillsOrderWise = async (spare_order_id) => {
	const res = await setNetworkCallGet(`workshop/spares/download-all-bills/order-wise/${spare_order_id}/`);
	return res ? res : null;
}

export const downloadInvoiceReturnBills = async (order_id) => {
	const res = await downloadNon( `nn-workshop/spares/download-bill/order-wise/${order_id}/?return_invoice=1` );
	return res ? res : null;
};

export const downloadInvoiceAPI = async (url) => {
	const res = await downloadNon( url );
	return res ? res : null;
}

//----- nn-discount apis --------

export const getBrandList = async () => {
	const res = await getNon(`nn-dashboard/spares/brand/list/`);
	return res?.status ? res?.data : [];
}

export const postNNDiscount = async (payload) => {
	const res = await postNon(`nn-dashboard/spares/workshop/discount/`, payload);
	return res ? res : null;
}

export const getNNDiscount = async ({garage_id = "", city_name = "", filter_type = null}) => {
	let queryParams = filter_type === 0 ? `?garage_id=${garage_id}` : `?city_name=${city_name}`; 
	const res = await getNon(`nn-dashboard/spares/workshop/discount/${queryParams}`);
	return res?.status ? res?.data : [];
}


export const downloadRetailerMOU = async({retailer_id = null}) => {
	if(retailer_id >= 0){
		let res = await download(`retailer/download_mou/?retailer_id=${retailer_id}`);
		return res;
	}
	return {}
}

export const getRetailerInvoicesBilling = async(spare_order_id) => {
	if(spare_order_id){
		let res = await download(`workshop/spares/download-bill/order-wise/${spare_order_id}/?parcit_invoice=1`);
		return res;
	}
	return {};
}

export const getSparesInterbillingInvoice = async(spare_order_id) => {
	if(spare_order_id){
		let res = await download(`retailer/garage/tax-invoices/?spare_order_id=${spare_order_id}`);
		return res;
	}
	return {};
}

export const getVechicleParts = async ({brand = "", model = "", year = "", fuel = ""}) => {
	let response = await getPartsRocket(`api/v1/part/parts_by_brand_and_model?brand=${brand}&model=${model}&fuel=${fuel}&year=${year}`);
	return response?.data || []
} 

export const networkSpaceBrandList = async() => {
	let response = await get(`workshop/retailer/search/car_brands/`);
	return response?.status && response?.data || [];
}

export const networkSpaceModelList = async(brand_id = "") => {
	let response = await get(`workshop/retailer/search/car_models/?brand_id=${brand_id}`)
	return response?.status && response?.data || [];
}

export const networkSpaceYearList = async(car_model_id = "") => {
	let response = await get(`workshop/retailer/search/years_of_mfg/?car_model_id=${car_model_id}`);
	return response?.status && response?.data || [];
}

export const networkSpareFuelList = async(car_model_id, year) => {
	let response = await get(`workshop/retailer/search/car_model_fuel/?car_model_id=${car_model_id}&year=${year}`);
	return response?.status && response?.data || [];
}

export const downloadMonthlyInsuranceGarageCommission = async ({month, year, billType = "gomechanic"}, fetch_btob = 0) => {
	const workShopId = getWorkShopId();
	let response = await download(`workshop/invoices/ins-consolidate-invoice/garage-code/?month=${month}&year=${year}&garage_id=${workShopId}&bill_type=${billType}&fetch_btob=${fetch_btob}`)
	return response;
}

export const downloadRefunds = async({month, year}, fetch_btob = 0) => {
	const workshopId = getWorkShopId();
	let response = await download(`reconciliation/refunded-orders/?garage_id=${workshopId}&month=${month}&year=${year}&is_download=1&fetch_btob=${fetch_btob}`)
	return response;
}

export const downloadPI = async ({
	month,
	year
}, fetch_btob) => {
	const workshopId = getWorkShopId();
	let response = await download(`workshop/invoices/penalty-invoice/?month=${month}&year=${year}&garage_id=${workshopId}&fetch_btob=${fetch_btob}`);
	return response;
}

export const getReturns = async (spare_order_id) => {
	let response = await getNon(`nn-workshop/spares/order/${spare_order_id}/?user_type=delivery&is_return=true`);
	return response?.status && response?.data || {};
}

export const updateReturns = async(payload) => {
	let response = await putNon(`nn-workshop/spares/return/item/`, payload);
	return response;
}

export const downloadReturns = async(spare_order_id) => {
	let response = await downloadNon(`nn-workshop/spares/download-bill/order-wise/${spare_order_id}/?return_invoices=1`)
	return response;
}

export const downloadSparesBill = async (spare_order_id) => {
	let response = await download(`workshop/spares/download-bill/order-wise/${spare_order_id}/`);
	return response;
}

export const downloadAccessoryBill = async(accessory_order_id) => {
	let response = await download(`retailer/download-accessories-bill/order-wise/${accessory_order_id}/`);
	return response;
}
export const downloadCreditGivenInvoice = async (spare_order_id) => {
	let response = await download(`workshop/spares/download-bill/order-wise/${spare_order_id}/`);
	return response;
}

export const doAmountOrders = async({
	orderId = null,
	month = null,
	year = null,
	pageIndex = 1
}) => {
	let response = await get(`workshop/insurance/orders-do-amount/?month=${month}&year=${year}&page=${pageIndex}&page_limit=50${orderId && `&order_id=${orderId}` || ""}`);
	return response;
}

export const downloadDoAmtFile = async(orderId) => {
	let response = await download(`workshop/insurance/download-do-file/?order_id=${orderId}`);
	return response;
}

export const editDoAmountOrders = async(payload) => {
	let response = await postFormData(`workshop/insurance/orders-do-amount/`, payload);
	return response;
}

export const getReconGarageWiseMN = async({
	month = null,
	year = null,
	orderFilter = null,
	pageIndex = 1,
	pageLimit = 50,
	orderType = 'retail'
}) => {
	const workshopId = getWorkShopId();
	let response = await get(`reconciliation/garage-wise/?month=${month}&year=${year}&order_filter=${orderFilter}&garage_id=${workshopId}&page=${pageIndex}&page_limit=${pageLimit}&order_type=${orderType}`)
	return response?.data ?? [];
}

const mapQueryParams = (payload) => {
	let getEntries = 
	Object.entries(payload);
	getEntries = getEntries.filter(([,value]) => !!value);
	const entriesLen = getEntries.length;
	if(entriesLen == 0)
		return null;
	getEntries = getEntries.reduce((acc, cur, index) => {
		acc += `${cur[0]}=${cur[1]}`;
		if(index < entriesLen - 1){
			acc+="&";
		}
		return acc;
	}, `?`);
	return getEntries;
}

// Onboarding Distributor APIs
export const getOnBoardingDistributors = async() => {
	return await get(`distributor/all-distributors/`);
}

export const addDistributor = async (payload) => {
	const res = await setNetworkCallPostFormData(
		`distributor/add/distributor/`,
		payload
	);
	return res;
};

export const updateDistributor = async (payload) => {
	const res = await putFormData(`distributor/update/data/`, payload);
	return res;
};

export const downloadDistributorMOU = async ({
	distributor_id = null
}) => {
	if(!distributor_id) return;
	return await download(`distributor/download_mou/?distributor_id=${distributor_id}`);
}

// Distributor Dashboard APIs
export const getDistributors = async ({
	distributor_type = null
}) => {
	let all_distributors = Number(Boolean(distributor_type));
	let queryParams = mapQueryParams({
		all_distributors,
		...(distributor_type && {distributor_type} || {})
	})
	
	return await get(`distributor/listing/${queryParams}`);
}

export const getDistributorSettlementSummary = async ({
	distributor_id,
	month,
	year
}) => {
	
	const queryParams = mapQueryParams({
		distributor_id,
		month,
		year
	})

	return await get(`reconciliation/settlement/distributor-recon-summary/${queryParams}`);
}

export const getDistributorPaymentSummary = async({
 distributor_id,
 month,
 year
}) => {
	return await get(`reconciliation/settlement/distributor/payments/?distributor_id=1&month=3&year=2022`)
}

class DistributorDashboardAPIs{
	constructor(payload = {}, method = "GET", ignoreCase = false){
		if(!ignoreCase){
			this.methods = {
				GET : get,
				POST : post,
				PUT : put,
				PARTS_ROCKET :getPartsRocket,
				DOWNLOAD : download
			}
			this.GET_METHODS = [
				this.methods["GET"], 
				this.methods["PARTS_ROCKET"],
				this.methods["DOWNLOAD"] ];
			this.IS_GETTER = this.GET_METHODS.includes(this.methods?.[method]);
			this.http = this.methods?.[method];
			if(payload?.hasOwnProperty("distributor_type") && this.IS_GETTER){
				this.payload = {
					...(payload?.["distributor_type"] 
						&& {
							distributor_type : payload?.["distributor_type"]
						} || {
							all_distributors : 1
						})
				}
			}
			else{
				this.payload = payload;
			}

			if(this.IS_GETTER){
					this.queryParams = mapQueryParams(this.payload);
			}
		}
	}

	getDistributors = async () => {
		return await this.http(`distributor/listing/${this.queryParams}`);
	}

	getRetailers = async () => {
		return await this.http(`retailer/get_retailer_list/${this.queryParams}`);
	}

	getDistributorSettlementSummary = async () => {
		return await this.http(`reconciliation/settlement/distributor-recon-summary/${this.queryParams}`);
	}

	getDistributorPaymentSummary = async() => {
		return await this.http(`reconciliation/settlement/distributor/payments/${this.queryParams}`)
	}

	getDistributorRecordCredit = async() => {
		return await this.http(`reconciliation/settlement/distributor/advance-credit/${this.queryParams}`)
	}

	searchSparesWithBrandModel = async() => {
		return await this.http(`workshop/retailer/spare_parts/${this.queryParams}&app_version=2.0.8&from_garage_app=1&is_debug=true`);
	}

	searchSparesOnlyByInput =  async() => {
		return await this.http(`workshop/bidding/private_label/list/${this.queryParams}`)
	}

	searchAccessories = async() => {
		return await this.http(`retailer/accessories/categories/${this.queryParams}`)
	}

	processSettlement = async() => {
		return await this.http(`reconciliation/settlement/distributor/payments/`, this.payload)
	}

	triggerOTPForDistributor = async() => {
		return await this.http(`distributor/advance-credit/send-to-distributor/${this.queryParams}`)
	}

	submitOTPForAdvanceCredit = async() => {
		return await this.http(`reconciliation/settlement/distributor/advance-credit/`, this.payload);
	}

	getRetailerInfo = async () => {
		return await this.http(`distributor/retailer/info/${this.queryParams}`)
	}

	triggerOTPForRetailer = async () => {
		return await this.http(`distributor/retailer/spares-otp/${this.queryParams}`, true);
	}

	verifyOTPForRetailer = async () => {
		return await this.http(`distributor/retailer/spares-otp/`, this.payload);
	}

	verifyOTPReturnPurchase = async () => {
		return await this.http(`distributor/retailer/return-item/verify-otp/`, this.payload);
	}

	getTransactionHistory = async () => {
		return await this.http(`distributor/retailer/txn-hst/${this.queryParams}`)
	}

	getDistributorPaymentDetails = async () => {
		return await this.http(`reconciliation/settlement/distributor/get-all-payment-request/${this.queryParams}`);
	}

	processDistributorRequest = async () => {
		return await this.http(`reconciliation/settlement/distributor/bank-details/${this.queryParams}`);
	}

	distributorAccounts = async () => {
		return await this.http(`reconciliation/settlement/all/bank-details/${this.queryParams}`);
	}

	distributorCreateAccount = async () => {
		return await this.http(`reconciliation/settlement/razorpay/add-new-account/`, this?.payload);
	}

	distributorUpdateAccount = async () => {
		return await this.http(`reconciliation/settlement/razorpay/update-contact/`, this?.payload);
	}

}

export {DistributorDashboardAPIs}


export const getGarageReconRequestsMN = async ({
	start_date,
	end_date,
	page = 1,
	limit = 10,
	payment_status = null,
	search_by = null,
	search_param = null,
}) => {

	const queryParams = mapQueryParams({
		start_date,
		end_date,
		page,
		limit,
		...(payment_status && {payment_status} || {}),
		...(search_by && search_param && {search_by, search_param} || {})
	})

	const response = await get(`reconciliation/settlement/get-all-recon-request/${queryParams}`);
	return response?.status ? response?.data : [];
}

export const getPaymentByGaragesMN = async (
	start_date,
	end_date,
	page = 1,
	limit = 10,
	payment_status = null,
	search_by = null,
	search_param = null,
) => {

	const queryParams = mapQueryParams({
		start_date,
		end_date,
		page,
		limit,
		...(payment_status && {payment_status} || {}),
		...(search_by && search_param && {search_by, search_param} || {})
	})

	const response = await get(`reconciliation/settlement/all/bank-details/${queryParams}`);
	return response?.status ? (response?.data || {}) : {};
}

export const payoutMN = async ({
	request_id,
	garage_id = "",
	payee_name = "",
	method
}) => {
	const response = 
	await post(`reconciliation/payout_garages/`, { request_id, garage_id, payee_name, method });
	return response;
}

export const fetchReconCreditById = async (advance_type, order_id) => {
	if(advance_type && order_id)
		return await get(`retailer/advance-credit/order-details/?advance_type=${advance_type}&order_id=${order_id}`);
	return;
}

export const editCreditGiven = async (payload, advance_type, order_id) => {
	return await put(`retailer/advance-credit/order-details/?advance_type=${advance_type}&order_id=${order_id}` , payload);
}

export const approveCreditGiven = async(payload, advance_type, order_id) => {
	return await put(`retailer/advance-credit/approve-order/?advance_type=${advance_type}&order_id=${order_id}`, payload);
}

export const getPreviousOrdersHistory = async ({
	// garage_id = null,
	// retailer_id = null
	order_type
}) => {
	const query = mapQueryParams({
		// garage_id,
		// retailer_id
		order_type
	})
	return await get(`reconciliation/unicommerce/orders/history${query||""}`);
}

export const orderTrackingQuery = async({
	order_type = null,
	page = 1,
	vendor_name = null,
	order_id_docket_number = null,
	status = null,
	sort_by,
    sort_dir,
	garage_id = null,
	retailer_id = null,
	month_id = null,
	month = null,
	year = null
}) => {
	let default_month_id;
	// if(!month_id){
	// 	default_month_id = +new Date().getUTCMonth() + 1;
	// }

	let queryParams = mapQueryParams({
		...(order_type == 'playstore'? {} : {order_type}),
		page,
		vendor_name,
		order_id_docket_number,
		status,
		sort_by,
        sort_dir,
		garage_id,
        retailer_id,
		month:month_id,
		month,
		year
	})
	return await get(`reconciliation/unicommerce/orders/tracking${queryParams}${order_type == 'playstore' ? '&order_type=pa_retailer&order_type=pa_garage_retailer&order_type=pa_distributor' : ''}`);
}

// https://staging.garage.gomechanic.app/reconciliation/unicommerce/order/cancel
// Form Data => spare_order_id = value

export const cancelOrderUnicommerce = async (payload) => {
	const response = await post(`reconciliation/unicommerce/order/cancel`, payload);
	console.log(response);
	return response;
}

export const downloadOrderTrackingSummary = async (spare_order_id) => {
	const response = await download(`workshop/spares/download-bill/order-wise/${spare_order_id}/`)
	return response;
}

export const onSearchSKUCode = 
async (
	search_string, 
	compatibility_group_id, 
	search_by,
	search_param ) => {
	let queryParams = mapQueryParams({
		search_string, compatibility_group_id, from_dashboard : true, search_by,
		search_param
	})

	const response = await get(`workshop/bidding/private_label/list/${queryParams}`)
	return response;
}

export const queryTicketSize =  async(order_type) => {
	const response = await get(`reconciliation/unicommerce/gom-pl-parts/cart/discounts?order_type=${order_type}`);
	return response;
}

export const retailerReconCreditTriggerOTP = async ({
	retailer_id = null,
	month = null,
	year = null,
	advance_type = null,
	approved_by = null,
	amount = null
}) => {
	let queryParams = mapQueryParams({
		retailer_id,
		month,
		year,
		advance_type,
		approved_by, 
		amount
	})
	const response = await getV2(`retailer/advance-credit/trigger-otp/${queryParams}`);
	return response;
}

export const getUnicommerceStatus = async () => {
	const response = await get(`reconciliation/unicommerce/status/list`);
	return response?.status ? response?.data : [];
}

export const getPreviousOrdersDetailsForWorkshop = async(order_id) => {
	const response = await get(`reconciliation/unicommerce/workshop/order/detail?order_id=${order_id}`);
	return response?.status ? response?.data : [];
}

export const getPreviousOrdersDetailsForRetailer = async(order_id) => {
	const response = await get(`retailer/advance-credit/order-details/?order_id=${order_id}&advance_type=1`);
	return response?.status ? response?.data : [];
}

export const getPreviousOrdersDetails = async(order_id, order_type) => {
	const response = await get(`reconciliation/unicommerce/order/detail?order_id=${order_id}&order_type=${order_type}`)
	return response?.status ? response?.data : [];
}

export const getNoPaymentsForWXRetailer = async({
	type = null,
	duration_of_days = null,
	status = null,
	city_id = null,
	search_param = null
}) => {
	let queryParams = mapQueryParams({
	type,
	duration_of_days,
	status,
	city_id,
	search_param
	})
	const response = await get(`reconciliation/settlement/no-payments-for-wx-and-retailers/${queryParams || ""}`);
	return response?.status ? response?.data : [];
}

export const NoPaymentSummaryData = async ({
	type = "retailer",
	request_id = null
}) => {
	let queryParams = mapQueryParams({
		type,
		request_id
		})
	const response = await get(`reconciliation/settlement/get-action-wx-or-retailer-request/${queryParams}`);
	return response.status ? 
	{ data : response?.data,
	  message : response?.message } : 
	{ };
}

export const NoPaymentsRaiseRequest = {
	RETAILER : async (payload) => {
		const response = await post(`reconciliation/settlement/retailer/make-payment-request/`, payload);
		console.log(response);
	},
	GARAGE : async (payload) => {
		const response = await post(`reconciliation/settlement/make-recon-request/`, payload);
		console.log(response);
	}
}

export const getMCapitalPayments = async ({
	request_id = null,
	garage_id = null,
	retailer_id = null,
}) => {
	let queryParams = mapQueryParams({
		garage_id,
		request_id,
		retailer_id
		})
	const response = await get(`reconciliation/settlement/mcapital/payment${queryParams}`);
	console.log(response);
	return response?.status ? response?.data : [];
}

export const processMCapitalPayments = async (payload) => {
	const response = await post(`reconciliation/settlement/mcapital/payment`, payload);
	console.log(response);
	return response;
}

export const downloadnopaymentin60days = async () => {
	const response = await download(`reconciliation/settlement/no-payments-for-wx-and-retailers/?is_download=1&download_excel=1`);
	return response;
	
}


export const uploadBulkOrders = async (payload) => {
	return await postFormData(`reconciliation/unicommerce/bulk-orders/upload`, payload);
}

export const bulkOrdersStatus = async () => {
	return await get(`reconciliation/unicommerce/bulk-orders/upload/status`)
}

export const approveBulkOrder = async(payload) => {
	return await post(`reconciliation/unicommerce/bulk-order/approve`, payload);
}

export const downloadSampleOrders = async() => {
	return await download(`reconciliation/unicommerce/bulk-orders/sample/download`);
}

export const downloadRejectedOrders = async() => {
	return await download(`reconciliation/unicommerce/bulk-orders/items/rejected/download`);
}

export const getWorkshopPLOrders = async ({workShopId, orderId}) => {
	return await get(`workshop/garage/private_label/order_details?workshop_id=${workShopId}&order_id=${orderId}`)
}

export const getDistributorPLOrders = async({distributorId, orderId}) => {
	return await get(`distributor/private_label/order_details?distributor_id=${distributorId}&order_id=${orderId}`)
}

export const getRetailerPLOrders = async({retailerId, orderId}) => {
	return await get(`v2/workshop/retailer/private_label/order_details?retailer_id=${retailerId}&order_id=${orderId}`);
}

export const downloadPurchaseTransactions = async({
	month,
	year,
	retailer_id
}) => {
	return await download(`retailer/puchase-txns/month-wise-excel/?retailer_id=${retailer_id}&month=${month}&year=${year}`)
}

export const getMTDMetrics = async ({
	month = null,
	year = null,
	type = "network"
}) => {
	const garageId = getWorkShopId();
	if(type == "network"){
		return await get(`nn-workshop/spares/mtd-business/?garage_id=${garageId}&month=${month}&year=${year}`)
	}
	else if(type == "non-network"){
		return await getNon(`nn-workshop/spares/mtd-business/?garage_id=${garageId}&month=${month}&year=${year}`) ;
	}
	return null;
}

export const getCollections = async ({
	start_date = null,
	end_date = null,
	page = 1, 
	page_limit = 20,
	request_status,
	vendor_invoice,
	payment_status,
	city_id,
	region,
	search_by,
	search_param
}) => {

	let queryParams = mapQueryParams({
		page,
		page_limit,
		start_date,
		end_date,
		request_status,
		vendor_invoice,
		payment_status,
		city_id,
		region,
		search_by,
		search_param
	})
	
	return await getNon(`nn-workshop/spares/recon-payments/${queryParams}`)
}


export const getRejectRemarkForCollection = async() => {
	return await getNon(`nn-workshop/spares/payment-rejection-dropdown/`)
}

export const onRejectCollection = async(payload) => {
	return await postNon(`nn-workshop/spares/recon/reject-payment-request/`, payload);
}

export const onNNApprovePayment = async (payment_id) => {
	return await put(`reconciliation/nn-garage/payment-mark-approved/`, {payment_id})
}

export const getNNCities = async (direction) => {
	const res = await getNon(`nn-dashboard/spares/cities/${direction}/`)
	return res && res.data ? res.data : [];
}
// "GET - 
// RESPONSE - {
//     ""status"": true,
//     ""message"": ""MTD Details Fetched Succesfully"",
//     ""data"": {
//         ""mtd_business_sales"": 1143.45,
//         ""mtd_return"": 523.0,
//         ""mtd_payment_done"": 4.0
//     }
// }"
export const getExpectedDeliveryCost = async({
	order_type = null,
	order_id = null,
	sku_code = null,
	garage_id = null,
	retailer_id = null,
	distributor_id = null,
	quantity = null
}) => {
	const queryParams = mapQueryParams({
		order_type,
		order_id,
		sku_code,
		garage_id,
		retailer_id,
		distributor_id,
		quantity
	})
	return await get(`bluedart/expected-delivery-cost/get${queryParams}`);
}

export const checkValidityForGarageV2 = async ({
	month = null,
	year = null
}) => {
	const workShopId = getWorkShopId();
	return await get(`reconciliation/v2/show-new-logic/check?garage_id=${workShopId}&month=${month}&year=${year}`);
}

export const downloadNetworkEcoInvoice = async(order_id) => {
	return await download(`workshop/invoices/network-ecosystem/?order_id=${order_id}`);	
}

export const getMobilOrderByOrderId = async(mobilOrderId = null) => {
	if(!mobilOrderId) return {};
	return await get(`retailer/garage/get-mobil-order/?mobil_order_id=${mobilOrderId}`)
}

export const getPlaystorePartners = async ({
	parent_id = null,
	type = null,
	page = null,
	page_limit = null
}) => {
	const queryParams = mapQueryParams({
		parent_id,
		type,
		page,
		page_limit
	})
	const response = await get(`playstore/workshop-partners${queryParams}`);
	return response.data || []
}

export const updateModilOrders = async (payload) => {
	$loadProgressBar();
	await put(`retailer/garage/return-mobil-item/`, payload);
	$detachProgressBar();
	return;
}

export const getPlaystoreHistory = async ({
	month,
	year,
	parent_id,
	type,
	order_id,
	page,
	page_limit
}) => {
	const queryParams = mapQueryParams({
		month,
		year,
		parent_id,
		type,
		order_id,
		page,
		page_limit
	})
	const response = await get(`playstore/order/transactions${queryParams}`);
	console.log(response);
	return response.data || [];
}