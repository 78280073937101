import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SparesTable from "../Common/Table/SparesTable";
import DateTimePicker from "../Common/DateTimePicker/DateTimePicker";
import "./Review.css";
import {
    tableHeaderData,
    tableHeaderKeys,
    tableHeaderDataBulk,
    tableHeaderKeysBulk,
    tableHeaderDataBulkMoreDetails,
    tableHeaderKeysBulkMoreDetails,
    tableHeaderDataMoreDetails,
    tableHeaderKeysMoreDetails,
} from "../../Constants/SparesOrder/ReviewConstant";
import {
    downLoadPdfForSpares,
    downloadReviewPhotos,
    saveDataAfterReview,
    downLoadExcelForSpares,
    getVendorsList,
    freezeOrder,
    sendBillToWorkShop,
    singleOrderData,
    getBrandsList,
    downLoadRunnerBill,
    notifyPrices,
    saveDataAfterReviewGlobal,
} from "../../Apis/Apis";
import {
    GET_VENDORLIST,
    GET_SINGLE_ORDER,
    SET_ORDER_ID,
} from "../../Redux/ReduxConstants/ReduxConstants";
import Loader from "../Common/Loader/Loader";

const MoreDetailsVendor = (props) => {
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState({
        headerData: [],
        headerKeys: [],
        bodyData: [],
        dataLoaded: false,
    });
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isOrderFreezed, setIsOrderFreezed] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [dataSaved, setDataSaved] = useState(false);
    const [brandList, setBrand] = useState(null);
    const [isGlobalEdit, setIsGlobalEdit] = useState(true);

    useEffect(() => {
        const { orderId } = props.location.state;
        const { activeOption } = props.location.state;
        dispatch({ type: SET_ORDER_ID, data: orderId });
        setOrderId(orderId);
        (async () => {
            const response = await singleOrderData(orderId);
            setData(response.data);
            dispatch({ type: GET_SINGLE_ORDER, data: response.data });
            setLoading(false);
            if (activeOption == 1)
                setTableData({
                    headerData: tableHeaderDataMoreDetails,
                    headerKeys: tableHeaderKeysMoreDetails,
                    bodyData: response ? response.data.spare_items : [],
                    dataLoaded: true,
                });
            else {
                setTableData({
                    headerData: tableHeaderDataBulkMoreDetails,
                    headerKeys: tableHeaderKeysBulkMoreDetails,
                    bodyData: response ? response.data.spare_items : [],
                    dataLoaded: true,
                });
            }
        })();

        const fetchData = (async () => {
            const res = await getVendorsList();
            dispatch({ type: GET_VENDORLIST, data: res.data });
        })();

        const fetchDataBrand = (async () => {
            // const listOfBrands = await getBrandsList(partType);
            // setBrand(listOfBrands);
        })();
    }, [isOrderFreezed, dataSaved]);

    const downloadTableData = async () => {
        const csvData = await downLoadExcelForSpares(data.spare_order_id);
        if (csvData) {
            const csvDaTa = await csvData.text();
            var a = document.createElement("a");
            const blob = new Blob([csvDaTa], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${data.spare_order_id}_orders.csv`;
            a.click();
        } else {
            alert("Sorry, Some Error Occured");
        }
    };

    const downloadBillPdf = async () => {
        if (data.parts_remaining == 0 && data.is_freezed) {
            const pdfData = await downLoadPdfForSpares(data.spare_order_id);
            if (pdfData) {
                const pdfDaTa = await pdfData.blob();
                var a = document.createElement("a");
                const blob = new Blob([pdfDaTa], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `${data.spare_order_id}_orders.pdf`;
                a.click();
            } else {
                alert("Sorry, Internal Server Error");
            }
        } else {
            alert("Parts Remaining should be 0 & Order Need to be Freezed");
        }
    };

    const downloadRunnerBillPdf = async () => {
        // if (data.parts_remaining == 0 && data.is_freezed) {
        const pdfData = await downLoadRunnerBill(data.spare_order_id);
        if (pdfData) {
            const pdfDaTa = await pdfData.blob();
            var a = document.createElement("a");
            const blob = new Blob([pdfDaTa], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${data.spare_order_id}_orders.pdf`;
            a.click();
        } else {
            alert("Sorry, Internal Server Error");
        }
        // }
        // else {
        //     alert("Parts Remaining should be 0 & Order Need to be Freezed");
        // }
    };

    const sendBill = async () => {
        const response = await sendBillToWorkShop(data.spare_order_id);
    };

    const notificationOfPrices = async () => {
        const response = await notifyPrices(data.spare_order_id);
    };

    const [dateRange, setDateRange] = useState({
        startDate: "01-".concat(moment(new Date()).format("MM-YYYY")),
        endDate: moment(new Date()).format("DD-MM-YYYY"),
    });

    const setDaTeRange = (date, dateType) => {
        const daTe = [moment(date).format("DD-MM-YYYY")];
        if (dateType === "from") {
            setDateRange({ ...dateRange, startDate: daTe });
        } else if (dateType === "to") {
            setDateRange({ ...dateRange, endDate: daTe });
        }
    };

    const downloadPhotos = async (uuid) => {
        const response = await downloadReviewPhotos(uuid);
        const a = document.createElement("a");
        if (response) {
            const blob = new Blob([await response.blob()], {
                type: "octet/stream",
            });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${data.spare_order_id}_orders.zip`;
            a.click();
        } else {
            alert("No Photos for this Order");
        }
    };

    const editAndSaveData = async (payload, orderUuid) => {
        setLoading(true);
        const response = await saveDataAfterReview(orderUuid, payload);
        setLoading(false);
        setDataSaved(true);
    };

    const editAndSaveDataGlobal = async (payload) => {
        setLoading(true);
        const response = await saveDataAfterReviewGlobal(payload);
        setLoading(false);
        setDataSaved(true);
    };

    const freezeSpareOrder = async () => {
        try {
            setLoading(true);
            const response = await freezeOrder(data.spare_order_id);
            setIsOrderFreezed(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            // console.log(err);
        }
    };

    return (
        <div className="componentPadding">
            {!isLoading ? (
                <>
                    <div className="flex alignItemsCenter marginBottom-2rem">
                        {/* <div className="flexSelect">
                            <p>Region</p>
                            <select id="garage-orders">
                                <option value="north">
                                    {props.location.state.region
                                        ? props.location.state.region.toUpperCase()
                                        : "Select Region"}
                                </option>
                            </select>
                        </div> */}

                        {/* <div
                            className="flexSelect"
                            style={{ marginLeft: "20px" }}
                        >
                            <p>City</p>
                            <select id="garage-orders">
                                <option value="Gurgaon">
                                    {props.location.state.city.toUpperCase()
                                        ? props.location.state.city
                                        : "Select City"}
                                </option>
                            </select>
                        </div> */}
                        <div
                            // className="marginLeftAuto"
                            style={{ marginTop: "50px" }}
                        >
                            <DateTimePicker
                                setDaTeRange={setDaTeRange}
                                date={dateRange.startDate}
                                dateType="from"
                            />
                        </div>
                        <div
                            style={{ marginTop: "50px" }}
                            className="marginLeft-1rem"
                        >
                            <DateTimePicker
                                setDaTeRange={setDaTeRange}
                                date={dateRange.endDate}
                                dateType="to"
                            />
                        </div>
                    </div>
                    {props.location.state.activeOption == 1 ? (
                        <div className="carDetails">
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    Car Make
                                </span>
                                &nbsp;&nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.cars.car_tbl.brand}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">Model</span>
                                &nbsp;&nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.cars.car_tbl.model}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    Fuel Type
                                </span>
                                &nbsp;&nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.cars.car_tbl.type}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    Reg. No.
                                </span>
                                &nbsp;&nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.cars.registration_no}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    Spares ID
                                </span>
                                &nbsp;&nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.spare_order_id}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    GM Order ID
                                </span>{" "}
                                &nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.gm_order_id}
                                </span>
                            </div>
                            <div className="carDetailsSpan">
                                <span className="carDetailsHeading">
                                    Code of
                                </span>{" "}
                                &nbsp;
                                <span className="carDetailsSubHeading">
                                    {data.order_of}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="carDetails">
                            <div className="carDetailsSpan">
                                <span
                                    className="carDetailsHeading"
                                    style={{ fontSize: "18px" }}
                                >
                                    Spares ID
                                </span>
                                &nbsp;&nbsp;
                                <span
                                    className="carDetailsSubHeading"
                                    style={{ fontSize: "18px" }}
                                >
                                    {data.spare_order_id}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="universal-edit">
                        <div
                            className="backOrder"
                            onClick={() => props.history.push("/Spares Orders")}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <p>Back To Order</p>
                        </div>

                        {isGlobalEdit ? (
                            <div
                                className="makeButton makeFunGreenButton"
                                onClick={() => setIsGlobalEdit(!isGlobalEdit)}
                            >
                                Edit All
                            </div>
                        ) : (
                            <div
                                className="makeButton makeFunGreenButton"
                                onClick={() => {
                                    setIsGlobalEdit(!isGlobalEdit);
                                    editAndSaveDataGlobal(tableData.bodyData);
                                }}
                            >
                                Save
                            </div>
                        )}
                    </div>

                    <SparesTable
                        tableHeaderData={tableData.headerData}
                        tableBodyData={tableData.bodyData}
                        tableHeaderKeys={tableData.headerKeys}
                        tableDataLoaded={true}
                        downloadPhotos={downloadPhotos}
                        editAndSaveData={editAndSaveData}
                        carData={data}
                        bulkOrCar={props.location.state.activeOption}
                        isGlobalEdit={isGlobalEdit}
                    />

                    <div className="partsDetails">
                        <div className="carDetailsSpan">
                            <span className="carDetailsHeading">
                                Placed Order
                            </span>
                            &nbsp;&nbsp;
                            <span>{data.parts_ordered}</span>
                        </div>
                        <div className="carDetailsSpan">
                            <span className="carDetailsHeading">Delivered</span>
                            &nbsp;&nbsp;
                            <span>{data.parts_delivered}</span>
                        </div>
                        <div className="carDetailsSpan">
                            <span className="carDetailsHeading">Cancelled</span>{" "}
                            &nbsp;&nbsp;
                            <span>{data.parts_cancelled}</span>
                        </div>
                        <div className="carDetailsSpan">
                            <span className="carDetailsHeading">Returned</span>{" "}
                            &nbsp;&nbsp;
                            <span>{data.parts_returned}</span>
                        </div>
                    </div>
                    {props.location.state.activeOption == 0 ? (
                        <div className="whiteButtons">
                            <div className="flexForButtons">
                                <div
                                    className="makeButton makeWhiteButton marginTop-2rem"
                                    onClick={downloadTableData}
                                >
                                    Download Excel
                                </div>
                            </div>
                            {/* <div className="flexForButtons"> */}

                            <div
                                className="makeButton makeWhiteButton marginTop-2rem"
                                onClick={downloadRunnerBillPdf}
                            >
                                Runner Bill
                            </div>
                            <div
                                className="makeButton makeWhiteButton marginTop-2rem"
                                onClick={notificationOfPrices}
                            >
                                Notify Prices
                            </div>
                            <div
                                className="makeButton makeWhiteButton marginTop-2rem"
                                onClick={freezeSpareOrder}
                            >
                                Freeze Order
                            </div>
                            <div
                                className="makeButton makeWhiteButton marginTop-2rem"
                                onClick={downloadBillPdf}
                            >
                                Download Bill
                            </div>
                            <div
                                className="makeButton makeWhiteButton marginTop-2rem"
                                onClick={sendBill}
                            >
                                Send Bill to WorkShop
                            </div>
                            {/* </div> */}
                        </div>
                    ) : (
                        <div className="whiteButtons">
                            <div className="flexForButtons">
                                <div
                                    className="makeButton makeWhiteButton marginTop-2rem"
                                    onClick={downloadTableData}
                                >
                                    Download Excel
                                </div>
                            </div>
                            <div
                                className="flexForButtons"
                                style={{ width: "45%" }}
                            >
                                <div
                                    className="makeButton makeWhiteButton marginTop-2rem"
                                    onClick={freezeSpareOrder}
                                >
                                    Freeze Order
                                </div>
                                <div
                                    className="makeButton makeWhiteButton marginTop-2rem"
                                    onClick={downloadBillPdf}
                                >
                                    Download Bill
                                </div>
                                <div
                                    className="makeButton makeWhiteButton marginTop-2rem"
                                    onClick={sendBill}
                                >
                                    Send Bill to WorkShop
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default MoreDetailsVendor;
