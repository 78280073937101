import { SelectPicker } from "rsuite";
import classNames from "classnames";
import "./garage.select.css";

export default function GarageSelectV2(props){
    const { checkbox_id,
            options, 
            statusKey, 
            labelKey, 
            selectedOptions, 
            subitemLabelKeyArray = [],
            onSelect = () => {}} = props;

    const getItemValue = (itemId) => options.find(option => option.id == itemId);
    if(options && options.length > 0)
     return <>
        <SelectPicker
            placeholder="Select Garage"
            size="lg"
            data={options.map((val) => ({
                label: val?.name || `Garage ID: ${val?.id}`,
                value: val?.id
            }))}
            onSelect={(_, item) => onSelect({id: item?.value, name: item?.label})}
            renderMenuItem={(label, item) => {
                const itemValue = getItemValue(item.value);
                if(!itemValue) return null;
                return <>
                <div
                    className={classNames(
                    "workshop-status-list", 
                    "workshop-status-list-v2-reset",
                    {"single-selected-item" : itemValue[checkbox_id] === selectedOptions })}>
                        <span className={classNames(
                        "workshop-name margin_l_8",
                        {"workshop-online" : itemValue[statusKey] === 1,
                        "workshop-offline" : itemValue[statusKey] === 0})}>
                            {label || itemValue?.[labelKey]}
                        </span>
                        <div className="workshop-details">
                            <div className='workshop-city'>{itemValue[subitemLabelKeyArray[0]] ?? "N/A"}</div>
                            <div className='workshop-gid'>G.ID: {itemValue[subitemLabelKeyArray[1]] ?? "N/A"}</div>
                        </div>
                </div>
                </>
            }}/>
    </>
    return null;
}