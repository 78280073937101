import { checkIfFinanceAdminOrOps } from "../../Components/Routes/AdminUsers"
var interval;

const autologout = (interval_timer) => {
    clearInterval(interval_timer);
	let Timer;
	const $elem = document.getElementById("auto-logout");
	$elem.style.display = "flex";
	clearTimeout(Timer);
	Timer = setTimeout(() => {
		localStorage.clear();
		sessionStorage.clear();
		$elem.style.display = "none";
		window.location.href = "/";
	}, 2000)
}


const setSessionTime = (time_delay) => {
    localStorage.setItem("_expiredTime", Date.now() + +time_delay);
    localStorage.setItem("_timer", "active");
}

const getSessionTime = () => {
    return +(localStorage.getItem("_expiredTime"));
}


export const sessionMiddleware = (store) => next => action => {
    if(checkIfFinanceAdminOrOps()){
        const isTimerActive = localStorage.getItem("_timer");
        if(isTimerActive != "active"){
            setSessionTime(60*60*1000);
            interval = setInterval(() => {
                const _presetTime = getSessionTime();
                if(_presetTime < Date.now()){
                    autologout(interval);
                }
            },1000)
        }

    }
    return next(action);
}