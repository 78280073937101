import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import SparesTable from '../Common/Table/SparesTable';
import { tableHeaderDataReturnNonNet,
         tableHeaderKeysReturnNonNet } from '../../Constants/SparesOrder/ReviewConstant'
import {ReturnStats} from './returns.stats';
import './returns.css'
import { getReturnsById, localMutate, mutateProcess, resetAll } from "../../Redux/Actions/Returns";
import { downloadReturns } from "../../Apis/Apis";
import { isReturnSpareModule } from "../Routes/AdminUsers";

export const Returns = () => {
    const [spareOrderId, setSpareId] = useState();
    const dispatch = useDispatch();
    const { returns_stats,
            returns_line_items , 
            loading,
            processState } = useSelector(state => state.returns);

    const fetchReturns = () => spareOrderId && dispatch(getReturnsById(spareOrderId)) || {};
    const updateProcess = () => dispatch(mutateProcess());

    const downloadPdfFile = async () => {
        let pdf = await downloadReturns(spareOrderId);
        if(pdf){
            const pdfDaTa = await pdf.blob();
            var a = document.createElement("a");
            const blob = new Blob([pdfDaTa], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${spareOrderId}.pdf`;
            a.click();
        }
        else{
            alert("No File Found")
        }
    }

    const changeHandler = (state, uuid) => {
        dispatch(localMutate({
            [state.name] : state.value
        }, uuid))
    }

    useEffect(() => {
        return () => {
            dispatch(resetAll());
        }
    },[])

    if(isReturnSpareModule()) 
    return <>
        <section className="nn-return-container">
            <div className="nn-input-container spacing-y-md">
                <input 
                    type="text" 
                    className="nn-return-input" 
                    placeholder="Search SpareID"
                    onChange={(e) => setSpareId(e?.target?.value)}/>
                <button type="button" className="nn-return-btn" onClick={fetchReturns}>Search</button>
            </div>
            {
                returns_line_items?.length > 0 && <>
                    <div className="spacing-y-md">
                        <ReturnStats data={returns_stats}/>
                    </div>
                    <div className="nn-buttons">
                            {processState === "edit_state" && <button onClick={updateProcess} type="button" className="nn-return-btn-green">Edit All</button>}
                            {processState === "save_state" && <button onClick={updateProcess} type="button" className="nn-return-btn-green">Save</button>}
                    </div>
                    <div className="spacing-y-md">
                        <SparesTable
                            tableHeaderData={tableHeaderDataReturnNonNet}
                            tableBodyData={returns_line_items}
                            tableHeaderKeys={tableHeaderKeysReturnNonNet}
                            tableDataLoaded={!loading}
                            returnsChangeHandler={changeHandler}
                            returnsDisabled={processState === "edit_state"}
                            returnInvoiceType
                        />
                    </div>
                    <button onClick={downloadPdfFile} type="button" className="nn-return-btn-green space-v-1">
                        <FontAwesomeIcon icon={faDownload}/>
                        <span>Download Return Invoice</span>
                    </button>
                </>
            }
        </section>
    </>;
    return null;
}
