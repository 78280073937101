import React from "react";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from  '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import './Calendar.css'


const SingleDateCalendar = (props) => {
  const { toggleCalendar, daTe, dateType, view } = props
  // const date = daTe ? new moment(daTe, 'DD-MM-YYYY')._d : new Date()

  const date = (view) => {
    let date;
    if (view === 'year') {
      date = daTe ? new moment(daTe, 'MM-YYYY')._d : new Date()
    } else {
      date = daTe ? new moment(daTe, 'DD-MM-YYYY')._d : new Date()
    }
    return date
  }

  const changeDate = date => {
    toggleCalendar(false, date, dateType)
  }
  const calendarType = (view) => {
    if (view === 'year') {
      return <Calendar 
                view={'year'}
                onClickMonth={changeDate} value={date(view)}
                prevLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                showNeighboringMonth={false}
                className='border-none margin-bottom-1rem'
             />
    } else if(view === 'month'){
        return <Calendar 
                  view={'month'}
                  onClickMonth={changeDate} value={date(view)}
                  prevLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                  nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                  showNeighboringMonth={false}
                  className='border-none margin-bottom-1rem'
              />
    }
    else {
      return <Calendar 
                onChange={changeDate} value={date(view)} 
                prevLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                showNeighboringMonth={false}
                className='border-none margin-bottom-1rem'
             />
    }
  }


  return (
    <div className='calendar-container'>
      {calendarType(view)}
    </div>
  );    
};

export default SingleDateCalendar;