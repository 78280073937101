import React, { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Header from "../Common/Header/Header/Header";
import DashBoardSideBar from "../Common/DashBoardSideBar/DashBoardSideBar";
import MoreDetailsVendor from "../SparesOrders/MoreDetailsVendor";
import { FilterContextProvider } from "../../Context/FilterContext";
import { Returns } from "../Returns";
import ErrorBoundary from "../Common/ErrorBoundary";
import Store from "../../Redux/Store/Store";
import { SET_SESSION_TIMER } from "../../Redux/ReduxConstants/ReduxConstants";
import Spinner from "../../Utils/spinner";

const DashBoard = lazy(() => import("../DashBoard"));
const Reports = lazy(() => import("../Reports/Reports"));
const Orders = lazy(() => import("../Orders/Orders"));
const Employees = lazy(() => import("../Employees/Employees"));
const AddEmployee = lazy(() => import("../Employees/AddEmployee"));
const EditEmployee = lazy(() => import("../Employees/EditEmployee"));
const ByGarage = lazy(() => import("../Settlements/ByGarage"));
const GomFinance = lazy(() => import("../Settlements/GomFinance"));
const SparesByGom = lazy(() => import("../Settlements/SparesByGom"));
const Escalations = lazy(() => import("../Escalations/Escalations"));
const GomEssentials = lazy(() => import("../GomEssentials/GomEssentials"));
const Region = lazy(() => import("../Reconciliation/Region"));
const City = lazy(() => import("../Reconciliation/City"));
const Garage = lazy(() => import("../Reconciliation/Garage"));
const NNGarage = lazy(() => import("../Reconciliation/NonNetworkGarage"));
const ReconRequest = lazy(() => import("../Payments/reconRequest/ReconRequest"));
const NonNetworkRetailPaymentRequests = lazy(() => import("../Payments/nonNetworkRetailPaymentRequests/NonNetworkRetailPaymentRequests"));
const Invoice = lazy(() => import("../Invoice/Invoice"));
const Discounts = lazy(() => import("../Discounts"));
const NetworkRetailerPaymentsRequest = lazy(() => import("../Payments/networkRetailerPaymentsRequest/NetworkRetailerPaymentsRequest"));
const AddAccounts = lazy(() => import("../Payments/addAccounts/AddAccounts"));
const WorkshopPLEntry = lazy(() => import("../Payments/workshop-pl-entry"));
const PaymentByGarages = lazy(() => import("../Payments/paymentByGarages/PaymentByGarages"));
const RetailerPayments = lazy(() =>
	import("../Reconciliation/RetailerPayments")
);
const RetailerPaymentsDashboard = lazy(() =>
	import("../Reconciliation/RetailerPaymentsDashboard")
);
const Person = lazy(() => import("../Reconciliation/Person"));
const EscalationsBill = lazy(() => import("../Reconciliation/Escalations"));
// const Garages = lazy(() => import("../Garages/Garages"));
const AddGarage = lazy(() => import("../Garages/AddGarage"));
const NotFound = lazy(() => import("../Common/NotFound/NotFound"));
const Login = lazy(() => import("../Common/Login/Login"));
const SparesOrders = lazy(() => import("../SparesOrders/SparesOrders"));
const Review = lazy(() => import("../SparesOrders/Review"));
const OrderStatus = lazy(() => import("../SparesOrders/OrderStatus"));
const RetailerOnboard = lazy(() =>
	import("../RetailerOnboard/RetailerOnboard")
);
const Purchase = lazy(() => import("../Purchase/Purchase"));
const MobilOrders = lazy(() => import("../MobilOrders/MobilOrders"));

// Spares Retailer Components
const SparesDashboardMain = lazy(() =>
	import("../SparesDashboard/SparesDashboardMain")
);
const GoMInventory = lazy(() => import("../GoM-Inventory/GoMInventory"));
const MobilPage = lazy(() => import("../MobilPage/Mobil"));
const GoMSparesPage = lazy(() => import("../GoMSpares/GoMSpares"));
const ReturnCredits = lazy(() => import("../ReturnCredit/ReturnCredit"));
const PlReturnCredits = lazy(() => import("../PlReturn/PlReturn"));
const SettlementHistory = lazy(() => import('../Reconciliation/Settlement.History'));
const DoAmount = lazy(() => import('../DoAmount/index.jsx'));

// Distributor dashboard
const GoMDistributor = lazy(() => import("../Distributor/GoMToDistributor"));
const DistributorRetailer = lazy(() => import("../Distributor/DistributorToRetailer"));
const ReturnPurchase = lazy(() => import("../Distributor/ReturnPurchase"));
const DistributorPaymentRequest = lazy(() => import("../Distributor/Payments/DistributorPaymentRequest"));
const DistributorAddAccounts = lazy(() => import("../Distributor/Payments/AddAccount"));
const OrderTrackingSystem = lazy(() => import("../Distributor/ReturnPurchase/order-tracking"));
const NoPaymentsin60Days = lazy(() => import("../Payments/nopaymentsin60days"));


const CollectionPayment = lazy(() => import("../Payments/collection"));

// Playstore
const Playstore = lazy(() => import('../Playstore'));

const Routes = () => {


	Store.dispatch({ type : SET_SESSION_TIMER })

	return (
		<Suspense fallback={<div></div>}>
			<Router>
				{/* <React.Fragment>
					<Header />
					<DashBoardSideBar />
				</React.Fragment> */}	
				<ErrorBoundary>
					<FilterContextProvider>
					<Switch>
						<Route exact path="/" component={Login} /> 
						<PrivateRoute
							exact
							path="/payments/workshop-pl-entry"
							component={GoMDistributor}
							distributorAdmin/>
						<PrivateRoute
							exact
							path="/dashboard/distributor-to-retailer"
							component={DistributorRetailer}
							distributorAdmin/>
						<PrivateRoute
							exact
							path="/dashboard/return-purchase"
							component={ReturnPurchase}
							distributorAdmin/>
						<PrivateRoute
							exact
							path="/dashboard/distributor-payment-request"
							component={DistributorPaymentRequest}
							distributorAdmin/>
						<PrivateRoute
							exact
							path="/dashboard/add-accounts"
							component={DistributorAddAccounts}
							distributorAdmin/>
						<PrivateRoute
							exact
							path="/returns"
							component={Returns}
							/>
						<PrivateRoute
							exact
							path="/dashboard"
							component={DashBoard}
							userAccess="all"
						/>
						<PrivateRoute path="/reports" component={Reports} />
						<PrivateRoute path="/billed-orders" component={Orders} />
						<PrivateRoute
							exact
							path="/employees/employees-list"
							component={Employees}
						/>
						<PrivateRoute
							exact
							path="/employees/add-employee"
							component={AddEmployee}
						/>
						<PrivateRoute
							exact
							path="/employees/employees-list/edit-employee"
							component={EditEmployee}
						/>
						<PrivateRoute
							exact
							path="/settlements/by-garage"
							component={ByGarage}
						/>
						<PrivateRoute
							exact
							path="/settlements/gom-finance"
							component={GomFinance}
						/>
						<PrivateRoute
							exact
							path="/reconciliation/settlement-history"
							component={SettlementHistory}
						/>
						<PrivateRoute
							exact
							path="/mobil-orders"
							component={MobilOrders}
							admin
						/>
						<PrivateRoute
							exact
							path="/settlements/spares-by-gom"
							component={SparesByGom}
						/>
						<PrivateRoute
							exact
							path="/gom-essentials"
							component={GomEssentials}
						/>
						<PrivateRoute
							exact
							path="/spares_dashboard"
							component={SparesDashboardMain}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/mobil_dashboard"
							component={SparesDashboardMain}
							userAccess="mobil_retailer"
						/>
						<PrivateRoute
							exact
							path="/gom_inventory"
							component={GoMInventory}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/gom_mobil"
							component={MobilPage}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/gom_spares"
							component={GoMSparesPage}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/return_credit"
							component={ReturnCredits}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/payments"
							component={RetailerPaymentsDashboard}
							userAccess="spares_retailer"
						/>
						<PrivateRoute
							exact
							path="/do-amount"
							component={DoAmount}
							userAccess="Finance Admin"/>
						<PrivateRoute
							exact
							path="/spares-orders"
							component={SparesOrders}
							admin
						/>
						<PrivateRoute
							exact
							path="/spares-orders/review"
							component={Review}
							admin
						/>
						<PrivateRoute
							exact
							path="/spares-orders/review/more-details"
							component={MoreDetailsVendor}
							admin
						/>
						<PrivateRoute
							exact
							path="/spares-orders/review/order-details"
							component={OrderStatus}
							admin
						/>
						<PrivateRoute
							exact
							path="/non-network-retailer-payment-request"
							component={NonNetworkRetailPaymentRequests}
							admin
						/>

						<PrivateRoute
							exact
							path="/invoice"
							component={Invoice}
							admin
						/>
						<PrivateRoute
							exact
							path="/discounts"
							component={Discounts}
							admin
						/>
						<PrivateRoute
							exact
							path="/escalations"
							component={Escalations}
							admin
						/>
						<PrivateRoute exact path="/purchase" component={Purchase} admin />
						<PrivateRoute
							exact
							path="/reconciliation/region-wise"
							component={Region}
							admin
						/>
						<PrivateRoute
							exact
							path="/order-tracking"
							component={OrderTrackingSystem}
							// NSAdmin
							/>
						<PrivateRoute
							exact
							path="/reconciliation/city-wise"
							component={City}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/no-payments-in-60-days"
							component={NoPaymentsin60Days}/>
						<PrivateRoute
							exact
							path="/payments/collection-payment"
							component={CollectionPayment}/>
						<PrivateRoute
							exact
							path="/payments/garage-recon-requests"
							component={ReconRequest}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/non-network-retailer-payment-request"
							component={NonNetworkRetailPaymentRequests}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/network-retailer-payment-request"
							component={NetworkRetailerPaymentsRequest}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/add-accounts"
							component={AddAccounts}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/workshop-pl-entry"
							component={WorkshopPLEntry}
							admin
						/>
						<PrivateRoute
							exact
							path="/payments/payment-by-garages"
							component={PaymentByGarages}
							admin
						/>
						<PrivateRoute
							exact
							path="/reconciliation/garage-wise"
							component={Garage}
							admin
						/>
						<PrivateRoute
							exact
							path="/reconciliation/non-network-garage-wise"
							component={NNGarage}
							admin/>
						<PrivateRoute
							exact
							path="/reconciliation/retailer-payments"
							component={RetailerPayments}
							admin
						/>
						<PrivateRoute
							exact
							path="/reconciliation/distributor-payments"
							component={GoMDistributor}
							financeAdmin
						/>
						<PrivateRoute
							exact
							path="/reconciliation/summary"
							component={Person}
							admin
						/>
						<PrivateRoute
							exact
							path="/reconciliation/escalations/bill-edit"
							component={EscalationsBill}
							admin
						/>
						<PrivateRoute
							exact
							path="/retailer-payments"
							component={RetailerPayments}
							admin
						/>
						<PrivateRoute
							exact
							path="/workshop-payments"
							component={WorkshopPLEntry}
							admin
						/>
						<PrivateRoute
							exact
							path="/pl-return"
							component={PlReturnCredits}
							userAccess="network_spares_admin"
						/>
						<PrivateRoute
							exact
							path="/distributor-payments"
							component={GoMDistributor}
							admin
						/>
						<PrivateRoute
							exact
							path="/playstore-orders"
							component={Playstore}
							admin
						/>
						{/* <PrivateRoute
							exact
							path="/garage/garage-list"
							component={Garages}
							admin
						/> */}
						<PrivateRoute
							exact
							path="/garage/add-garage"
							component={AddGarage}
							admin
						/>
						<PrivateRoute
							exact
							path="/onboarding"
							component={RetailerOnboard}
							admin
						/>
						<Route component={NotFound} />
					</Switch>
				</FilterContextProvider>
				<Spinner/>
				</ErrorBoundary>
			</Router>
		</Suspense>
	);
};

export default Routes;