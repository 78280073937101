import { combineReducers } from "redux";
import loginReducer from "./LoginReducer";
import garageDetailsReducer from "./GarageDetailsReducer";
import sideBarReducer from "./SideBarReducer";
import toastDataReducer from "./ToastDataReducer";
import cityIdReducer from "./CityIdReducer";
import reconOrderIdReducer from "./ReconOrderIdReducer";
import {
    vendorReducer,
    getSingleOrderDataReducer,
    setOrderIdForReview,
} from "./SparesReducer";

import {retailerPaymentsReducer} from './RetailerPayments';
import {ReturnsReducer} from './ReturnsReducer';
import {doAmountReducer} from './DoAmountReducer';
import dashboardReducer from "./DashboardReducer";

const reducers = combineReducers({
    loginToken: loginReducer,
    garageDetails: garageDetailsReducer,
    activeSideBarHeader: sideBarReducer,
    toastData: toastDataReducer,
    cityId: cityIdReducer,
    reconOrderId: reconOrderIdReducer,
    vendorList: vendorReducer,
    singleOrderList: getSingleOrderDataReducer,
    orderIdForReview: setOrderIdForReview,
    retailerPayments : retailerPaymentsReducer,
    returns :  ReturnsReducer,
    doAmt : doAmountReducer,
    dashboard : dashboardReducer
});

export default reducers;
