import {
    LOAD_SETTLEMENT_RECONCILIATION,
    LOAD_PAYMENT_RECONCILIATION,
    LOAD_CREDIT_RECONCILIATION,
    SET_LOADER,
    LOAD_RETAILERS,
    ADD_OBD_ITEMS,
    ADD_ACCESSORIES,
    ADD_INVENTORY_ITEMS,
    ADD_PART_ITEMS,
    UPDATE_ACCESSORIES,
    UPDATE_INVENTORY_ITEMS,
    UPDATE_PART_ITEMS,
    REMOVE_ACCESSORIES,
    REMOVE_INVENTORY_ITEMS,
    REMOVE_PART_ITEMS,
    CLEAR_PART_LIST,
    CLEAR_PART_ITEMS,
    CLEAR_OBD,
    CLEAR_ACCESSORIES,
    CLEAR_INVENTORY,
    LOAD_ACCESSORY,
    LOAD_MAPPED_ACCESSORY,
    LOAD_INVENTORY,
    LOAD_BRANDS,
    LOAD_MODELS,
    LOAD_YEARS,
    LOAD_FUEL,
    LOAD_PARTS,
    LOAD_MAPPED_PARTS,
    LOAD_TRANSACTION_HISTORY
} from '../ReduxConstants/RetailerPayments';


const initState = {
    retailersList : [], 
    accessoryList : [],
    inventoryList : [],
    loadingSettlement : false,
    loadingPayment : false,
    loadingCredit : false,
    loadingTransactionHistory: false,
    retailerSettlementRecon : [],
    retailerPaymentRecon : [],
    retailerCreditRecon : [],
    retailerTransactionHistory: [],
    OBDItems : [],
    Accessories : [],
    InventoryItems : [],
    partItems : [],
    vechiclePartsList : [],
    mappedParts : [],
    brandList : [],
    modelList : [],
    yearList : [],
    fuelList : []
}

const removeItem = (id, items) => items.filter(v => v?.item_id !== id);
const updateItem = (item, items) => {
    items = items.map(v => v?.item_id === item.item_id && item || v);
    return items;
}

const mapPartsItems = (items, dropdownSchema) => {
    let data = items?.map(item => ({
        image_url : item?.image_url,
        item_id : item?.product_id ?? item?.sku_id,
        item_name : item?.title ?? item?.label_name ?? item?.sub_category_name,
        item_unit_price : Number(item?.price) || null,
        quantity : 1,
        item_amount : null,
        discount: 0,
        discount_percent: 0,
        sku_code : item?.sku_code ?? item?.sku_id,
        sku_brand : item?.category_name,
        price : item?.price || 0,
        mrp : item?.mrp || 0,
        tax : item?.tax || 0,
        hsn_code: item?.hsn_code ?? "",
        car_brand_id: dropdownSchema?.brand?._id ?? "",
        car_brand: dropdownSchema?.brand?.name ?? "",
        car_model_id: dropdownSchema?.model?._id ?? "",
        car_model: dropdownSchema?.model?.name ?? "",
        car_variant: dropdownSchema?.fuel?.variant ?? "",
        car_fuel_engine: dropdownSchema?.fuel?.fuel_engine ?? "",
        car_year: dropdownSchema?.year ?? ""
    }));

    return data;
}

const mapAccessoryItems = (items) => {
    let data = items?.map(item => ({
        item_id: item?.id,
        item_name: item?.name,
        item_unit_price: 0,
        quantity: 1,
        discount: 0,
        discount_percent: 0,
        item_amount: 0}));
    return data;
}

export const retailerPaymentsReducer = (state = initState, {type, payload, dropdownSchema = null}) => {
    let copyState = JSON.parse(JSON.stringify(state));
    switch(type){
        case SET_LOADER : return {
            ...copyState,
            ...payload
        }
        case LOAD_SETTLEMENT_RECONCILIATION: return{
            ...copyState,
            loadingSettlement : false,
            retailerSettlementRecon : payload
        }
        case LOAD_PAYMENT_RECONCILIATION: return {
            ...copyState,
            loadingPayment : false,
            retailerPaymentRecon : payload
        }
        case LOAD_CREDIT_RECONCILIATION: return {
            ...copyState,
            loadingCredit : false,
            retailerCreditRecon : payload
        } 
        case LOAD_TRANSACTION_HISTORY:
            return {
                ...copyState,
                loadingTransactionHistory: false,
                retailerTransactionHistory: payload
            }
        case LOAD_RETAILERS: return { ...copyState, retailersList : payload};
        case LOAD_ACCESSORY: return { ...copyState, accessoryList : payload};
        case LOAD_MAPPED_ACCESSORY : return {...copyState, accessoryList : mapAccessoryItems(payload)}
        case LOAD_INVENTORY: return { ...copyState, inventoryList : payload};
        case LOAD_BRANDS : return {...copyState, brandList : payload};
        case LOAD_MODELS : return {...copyState, modelList : payload};
        case LOAD_YEARS : return {...copyState, yearList : payload};
        case LOAD_FUEL : return {...copyState, fuelList : payload};
        case LOAD_PARTS : return {...copyState, vechiclePartsList : payload};
        case LOAD_MAPPED_PARTS : return {...copyState, mappedParts : mapPartsItems(payload, dropdownSchema)}
        case ADD_OBD_ITEMS: return {...copyState, OBDItems : payload};
        case ADD_ACCESSORIES: return {...copyState, Accessories : [...copyState.Accessories, payload]};
        case ADD_INVENTORY_ITEMS: return {...copyState, InventoryItems : [...copyState.InventoryItems, payload]};
        case ADD_PART_ITEMS : return {...copyState, partItems : [...copyState.partItems, payload]}; 
        case UPDATE_ACCESSORIES: return {...copyState, Accessories : updateItem(payload, copyState.Accessories)};
        case UPDATE_INVENTORY_ITEMS: return {...copyState, InventoryItems : updateItem(payload, copyState.InventoryItems)};
        case UPDATE_PART_ITEMS : return {...copyState, partItems : updateItem(payload, copyState.partItems)};
        case REMOVE_ACCESSORIES: return {...copyState, Accessories : removeItem(payload, copyState.Accessories)};
        case REMOVE_INVENTORY_ITEMS: return {...copyState, InventoryItems : removeItem(payload, copyState.InventoryItems)};
        case REMOVE_PART_ITEMS: return {...copyState, partItems : removeItem(payload, copyState.partItems)};
        case CLEAR_PART_LIST : return {...copyState, vechiclePartsList : []};
        case CLEAR_PART_ITEMS: return {...copyState, partItems : []};
        case CLEAR_OBD: return {...copyState, OBDItems : []};
        case CLEAR_ACCESSORIES : return {...copyState, Accessories : []};
        case CLEAR_INVENTORY: return {...copyState, InventoryItems : []};
        // case MASTER_RESET: return initState;
        default:
            return copyState;
    }
}