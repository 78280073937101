import {removeAllChildNodes} from './helpers';

const alertClasses = {
    success : "fa-circle-check",
    error : "fa-circle-xmark",
    info : ""
}

export const $addToast = ({
    toastType = "info",
    message = "some message",
    toastId = null
}) => {
    const container = document.getElementById('toast-container');
    const toastWrapper = container.firstElementChild;
    const $ToastElem = document.createElement("div");
    const getIconClass = alertClasses[toastType];
    const $IconElem = document.createElement("i");
    const $TextElem = document.createElement("div");
    const $HeadElem = document.createElement("div");
    const $MsgElem = document.createElement("div");
    $ToastElem.setAttribute('id', toastId);
    $ToastElem.classList.add("show-toast-elem", `toast-elem-${toastType}`);
    $ToastElem.style.transform = "translateY(200px)";
    $IconElem.classList.add("fa-regular", getIconClass);
    $TextElem.classList.add("toast-body");
    $HeadElem.classList.add("toast-title");
    $MsgElem.classList.add("toast-message");
    $HeadElem.innerText = toastType.toLowerCase();
    $MsgElem.innerText = message;
    $TextElem.append($HeadElem);
    $TextElem.append($MsgElem);
    $ToastElem.append($IconElem);
    $ToastElem.append($TextElem);
    toastWrapper.append($ToastElem);
    !container.classList.contains("active-toast") &&
    container.classList.add("active-toast");
    setTimeout(() => {
        $ToastElem.style.transform = "translateY(0px)";
    },0)
}

export const $removeAllToast = () => {
    const container = document.getElementById('toast-container');
    removeAllChildNodes(container.firstElementChild);
    container.classList.contains("active-toast") &&
    container.classList.remove("active-toast");
};

export const $removeToastById = (toastId) => {
    const container = document.getElementById('toast-container');
    const toastElems = container.firstChild.childNodes;
    let findToastById = null;
    for(let toast of toastElems){
        if(toast.getAttribute("id") == toastId){
            findToastById = toast;
        }
    }
    container.removeChild(findToastById)
}

