export const LOAD_SETTLEMENT_RECONCILIATION = 'LOAD_SETTLEMENT_RECONCILIATION';
export const LOAD_PAYMENT_RECONCILIATION = 'LOAD_PAYMENT_RECONCILIATION';
export const LOAD_CREDIT_RECONCILIATION = 'LOAD_CREDIT_RECONCILIATION';
export const LOAD_TRANSACTION_HISTORY = 'LOAD_TRANSACTION_HISTORY';
export const SET_LOADER = 'SET_LOADER';
export const LOAD_RETAILERS = 'LOAD_RETAILERS';
export const LOAD_ACCESSORY = 'LOAD_ACCESSORY';
export const LOAD_MAPPED_ACCESSORY = 'LOAD_MAPPED_ACCESSORY';
export const LOAD_INVENTORY = 'LOAD_INVENTORY';
export const ADD_OBD_ITEMS = 'ADD_OBD_ITEMS'; 
export const ADD_ACCESSORIES = 'ADD_ACCESSORIES';
export const ADD_INVENTORY_ITEMS = 'ADD_INVENTORY_ITEMS';
export const ADD_PART_ITEMS = 'ADD_PART_ITEMS';
export const UPDATE_ACCESSORIES = 'UPDATE_ACCESSORIES';
export const UPDATE_INVENTORY_ITEMS = 'UPDATE_INVENTORY_ITEMS';
export const UPDATE_PART_ITEMS = 'UPDATE_PART_ITEMS';
export const REMOVE_ACCESSORIES = 'REMOVE_ACCESSORIES';
export const REMOVE_INVENTORY_ITEMS = 'REMOVE_INVENTORY_ITEMS';
export const REMOVE_PART_ITEMS = 'REMOVE_PART_ITEMS';
export const CLEAR_PART_LIST = 'CLEAR_PART_LIST';
export const CLEAR_PART_ITEMS = 'CLEAR_PART_ITEMS';
export const CLEAR_OBD = 'CLEAR_OBD';
export const CLEAR_ACCESSORIES = 'CLEAR_ACCESSORIES';
export const CLEAR_INVENTORY = 'CLEAR_INVENTORY'; 
export const LOAD_BRANDS = 'LOAD_BRANDS';
export const LOAD_MODELS = 'LOAD_MODELS';
export const LOAD_YEARS = 'LOAD_YEARS';
export const LOAD_FUEL = 'LOAD_FUEL';
export const LOAD_PARTS = 'LOAD_PARTS';
export const LOAD_MAPPED_PARTS = 'LOAD_MAPPED_PARTS';