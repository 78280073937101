export const SET_LOGIN_TOKEN = "SET_LOGIN_TOKEN";
export const SET_SIDEBAR_SUBHEADER = "SET_SIDEBAR_SUBHEADER";
export const SET_SIDEBAR_TOGGLE = "SET_SIDEBAR_TOGGLE";
export const SET_GARAGE_DETAILS = "SET_GARAGE_DETAILS";
export const SET_ACTION_MESSAGE = "SET_ACTION_MESSAGE";
export const SET_CITY_ID = "SET_CITY_ID";
export const SET_RECON_ORDER_ID = "SET_RECON_ORDER_ID";
export const GET_VENDORLIST = "GET_VENDORLIST";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_SESSION_TIMER = 'SET_SESSION_TIMER';
export const RESET_SESSION_TIMER = 'RESET_SESSION_TIMER';