import { useEffect } from "react";
import { useHistory } from "react-router-dom";


/*

ROUTE_MAPPER : {
    [URL] : [ID],
    .....,
    root : [BASE_URL]    
},

ACTIVE_ROUTE ?: [PATHNAME | OPTIONAL],
ROUTE_ACTIVE_CLASS : [SELECTED LINK CLASS]

*/

export default function useSidebar(props){
    const {
        ROUTE_MAPPER = {},
        ACTIVE_ROUTE = window.location.pathname,
        ROUTE_ACTIVE_CLASS = "active"
    } = props;

    const history = useHistory();

    useEffect(() => {
        ACTIVE_ROUTE &&
        setActiveRoute(ACTIVE_ROUTE)
        // eslint-disable-next-line
    },[ACTIVE_ROUTE])

    const navigateTo = (route) => {
        const previousRouteName =
        ROUTE_MAPPER?.
        [ACTIVE_ROUTE?.replace("%20", " ")?.trim()];
        const $pelem = document.getElementById(`${previousRouteName?.toLowerCase()}`);
        $pelem && $pelem.classList.remove(ROUTE_ACTIVE_CLASS);
        history.push(route)
    }
    
    const setActiveRoute = () => {
        // console.log(ACTIVE_ROUTE);
        // console.log(ROUTE_MAPPER);
        const routeName = 
        ROUTE_MAPPER?.
        [ACTIVE_ROUTE?.replace("%20", " ")?.trim()];
        const $elem = document.getElementById(`${routeName?.toLowerCase()}`);
        $elem && $elem.classList.add(ROUTE_ACTIVE_CLASS);
    }

    return {
        activateRoute : ACTIVE_ROUTE,
        navigateTo,
    };
}