import React, { useState } from "react";
import "./Table.css";
import moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SET_ORDER_ID } from "../../../Redux/ReduxConstants/ReduxConstants";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Checkbox from "../Checkbox/Checkbox";
import {isNonNetowrkUser} from '../../Routes/AdminUsers';
import InfoIcon from '../../../Images/info_black_24dp 3.svg';

const DownloadPaymentScreenshotButton = styled.div`
background-color : #00a126;
color : #fff;
display : inline-block;
padding : 0.75rem 1.5rem;
border-radius : 4px;
cursor : pointer;
opacity : 1;
&:active{
  opacity : 0.6;
}
`;

const AdvancePayAmtInfo = styled.div`
display : flex;
justify-content : flex-end;
align-items : center;
padding : 0 1rem;
gap : 0.7rem;
`;

const BrandWrapper = styled.div`
max-width : 180px;
text-align : center;
font-weight : 500;
line-height : 25px;
`;

const InputWrapper = styled.div`
display : flex;
justify-content : center;
`;

const ReturnInput = styled.input`
width : 3rem;
height : 1.6rem;
border-radius : 3px;
border : 1px solid rgba(0,0,0,0.3);
transition : all 0.25s linear;
&:focus{
  outline : none;
  border-color : rgba(0,0,0,0.54);
}
`;

const AdvancePayInfo = ({data = null, triggerInfoModal = () => {}}) => {
   return <AdvancePayAmtInfo>
      <div>{data ?? "--"}</div>
      <div className="info-icon-wrapper" onClick={triggerInfoModal}>
        <img className="info-icon" src={InfoIcon} />
      </div>
   </AdvancePayAmtInfo>
}


const isOrderApproved = (row) => 
  row["parts_in_procuring"] > 0 || 
  row["parts_in_procured"] > 0 || 
  row["items_dispatched"] > 0||
  row["parts_delivered"] > 0;

const SparesTable = (props) => {
  const vendorsList = useSelector((state) => state.vendorList);
  const [orderId, setOrderId] = useState(null);
  const [currentEtd, setCurrentEtd] = useState("");
  const [currentVendor, setCurrentVendor] = useState("");
  const [dateUpdated, setDateUpdated] = useState({
    uuid: null,
    isUpdated: false,
  });
  const [isDisabled, setIsDisabled] = useState({
    uuid: null,
    isDisabled: true,
  });
  const [skuFocus, setSkuFocus] = useState(false);
  const [skuCodeValue, setSkuCodeValue] = useState("");
  const [isEtdsFilled, setIsEtdsFilled] = useState(false);
  const [isPickupFilled, setIsPickupFilled] = useState(false);
  const [purchaseTax, setPurchaseTax] = useState("");

  const dispatch = useDispatch();

  const {
    tableHeaderData,
    tableBodyData,
    tableHeaderKeys,
    tableDataLoaded,
    scrollable,
    setSpareOrderIdForReview,
    downloadPhotos,
    editAndSaveData,
    isEditable,
    // vendorsList,
    carData,
    bulkOrCar,
    brandList,
    cancelOrder,
    fetchDataSku,
    garageDetails,
    fillAllEtds,
    fillAllVendors,
    showPaymentAmtInfo,
    setProcurementUUIDs,
    returnsChangeHandler,
    returnsDisabled,
    returnInvoiceType = false
    // fetchDataWithSku,
  } = props;

  let { isGlobalEdit, activeTab, fetchDataWithSku } = props;

  const tableHead = () => {
    const data = tableHeaderData.map((data, index) => {
      const fixedColumnClass = !index
        ? `sticky-columnUpper ${scrollable ? "thead-th" : ""}`
        : `${scrollable ? "thead-th" : ""}`;
      return (
        <th key={index} className={fixedColumnClass} style={{minWidth : "120px"}}>
          {data}
        </th>
      );
    });
    return (
      <thead>
        <tr>{data}</tr>
      </thead>
    );
  };

  const tableBody = () => {
    const data = tableBodyData.map((row, index) => {
      // if(row.bill_no) row.is_download=true;
      // else  row.is_download=false;
      let isReviewed;
      if (row.colour_code == "green") isReviewed = `is-reviewed`;
      else if (row.colour_code == "yellow") isReviewed = `is-reviewed-yellow`;
      else isReviewed = `is-reviewed-black`;
      const rowData = tableHeaderKeys.map((key, index) => {
        if (!index) {
          const fixedColumnClass = "sticky-column";
          if (key === "created_at" || key === "placed_on") {
            return (
              <td key={index} className={fixedColumnClass}>
                <div style={{ width: "max-content" }}>
                  {moment(row[key]).format("DD-MM-YYYY")}
                </div>
              </td>
            );
          }
          if (
            key === "sku_code" &&
            (props.location.pathname.includes("review") ||
              props.location.pathname.includes("more-details"))
          ) {
            return (
              <td
                key={index}
                style={{ width: "105px" }}
                className={fixedColumnClass}
              >
                {makeInputField(key, row)}
              </td>
            );
          } else
            return (
              <td
                key={index}
                style={{ width: "105px" }}
                className={fixedColumnClass}
              >
                {row[key]}
              </td>
            );
        } 
        else if(key === "is_return_item")
          return <td>
          <InputWrapper>
              <Checkbox disabled={returnsDisabled} checked={row[key]} onChange={(value) => !returnsDisabled && returnsChangeHandler({
                name : key,
                value
              }, row?.uuid)}/>
          </InputWrapper>
          </td>;
        else if(key === "return_quantity")
          return <td>
            <InputWrapper>
              <ReturnInput disabled={returnsDisabled} type="number" name={key} value={row[key]} onChange={(e) => !returnsDisabled && row?.quantity > 0 && e.target.value <= row?.quantity && returnsChangeHandler({
                name : e.target.name,
                value : e.target.value
              }, row?.uuid)} />
            </InputWrapper>
          </td>
        else if(key === "updated_at")
          return <td key={index}>
            <div style={{whiteSpace : "nowrap"}}>{moment(row[key]).format("DD-MM-YYYY")}</div>
          </td>
        else if (key === "Review")
          return <td key={index}>{reviewActions(key, row.spare_order_id, row.garage_id)}</td>;
        else if (key === "Cancel")
          return <td key={index}>{reviewCancel(key, row)}</td>;
        else if (key === "spares_items")
          return <td key={index}>{getMaximaETD(key, row)}</td>;
        else if (key === "garage") {
          return <td key={index}>{row.garage && row.garage.name}</td>;
        } else if (key === "etd" && !props.location.pathname.includes("review"))
          return (
            <td key={index}>
              {row.etd ? moment(row[key]).format("DD-MM-YYYY") : "N/A"}
            </td>
          );
        else if (
          (key === "sku_code" ||
            key === "quantity" ||
            key === "part_type" ||
            key === "mrp" ||
            key === "etd" ||
            key === "sku_desc" ||
            key === "pickup_date" ||
            key === "delivery_date" ||
            key === "vendor_name" ||
            key === "vendor_location" ||
            key === "tax_rate" ||
            key === "vendor_bill_no" ||
            key === "discount" ||
            key === "is_finance_approved" ||
            key === "sku_hsn_code" ||
            key === "invoice" ||
            key === "spares_inventory" ||
            key === "brand_name" ||
            key === "remark" ||
            key === "purchase_amount" ||
            key === "purchase_with_tax" ||
            key === "invoice_no" ||
            key === "vendor_discount" ||
            key === 'invoice_checkbox') &&
          props.location.pathname.includes("review")
        )
          return <td key={index}>{makeInputField(key, row)}</td>;
        else if(key === "mrp" || key === "purchase_with_tax" && returnInvoiceType) return <td>
          <div>{Number(row[key]).toFixed(2)}</div>
        </td>
        else if (key === 'info'){
          return <td key={index} style={{position:"relative"}}><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleToolTip(row.uuid)}/>
          <div id={row.uuid} className="info-card">
        <table>
            <tr>
                <th>
                    Order ID
                </th>
                <td>
                    {row.info.prev_spare_order_id ? row.info.prev_spare_order_id : 'NA'}
                </td>
            </tr>
            <tr>
                <th>
                    MRP
                </th>
                <td>
                    {row.info.prev_mrp ? row.info.prev_mrp : 'NA'}
                </td>
            </tr>
            <tr>
                <th>
                    Placed on
                </th>
                <td>
                    {row.info.prev_days ? row.info.prev_days : 'NA'}
                </td>
            </tr>
        </table>
    </div>
          {/* <ToolTip id={`tooltip_${tableHeaderData}`}/> */}
          </td>;
        }
        else if (key === "vendor_discount" || key === "purchase_with_tax")
          return <td key={index}>{setMrpValueFunc(row)}</td>;
        else if (key === "Order Status" || key === "Download Photos")
          return <td key={index}>{showMoreDetails(key, row, row.order_id)}</td>;
        else if (key === "Order Status Proc")
          return <td key={index}>{procKeys(key, row, row.order_id)}</td>;
        else if (key === "Order Status Del" && !returnInvoiceType)
          return <td key={index}>{delKeys(key, row, row.order_id)}</td>;
        else if (key === "Order Status Del" && returnInvoiceType)
          return <td key={index}>{mapSiStatusIdToKey(row?.si_status_id)}</td>;
        else if (key === "Actions" || key === "ActionsMore")
          return (
            <td key={index}>{showActionKeys(key, row, row.order_id, index)}</td>
          );
        else if (
          key === "stockone_order_id" ||
          key === "available_in" ||
          key === "is_stockone" ||
          key === "vendor_name"
        )
          return (
            <td key={index}>
              {showStockOneId(key, row.stockone_order_id, row.is_stockone)}
            </td>
          );
        else if (
          key.includes("date") ||
          key.includes("etd") ||
          key.includes("created_at") ||
          key.includes("pickup_date") ||
          key.includes("delivery_date")
        ) {
          return <td key={index}>{moment(row[key]).format("DD-MM-YYYY")}</td>;
        }
        else if(key === "advance_amount") return <td key={index}>
          <AdvancePayInfo data={row[key]} triggerInfoModal={() => showPaymentAmtInfo(row["advance_amount_info"])}/>
        </td>
        else if(key === "brand_list") return <td key={index}>
          <BrandWrapper>
            { 
              row[key] && 
              row[key].length > 0 &&
              row[key].join(", ")
              }
          </BrandWrapper>
        </td>
        else if(key === "so_status_id") return <td key={index}>
          <div className={`status-tag ${isOrderApproved(row) && "status-tag-green" || "status-tag-blue"}`}>
            {isOrderApproved(row) && "Approved" || "Pending"}
          </div>
        </td>
        else return <td key={index}>
          <div className="text-md">{row[key] ?? "--"}</div>
        </td>;
      });
      return (
        <tr key={index} className={isReviewed}>
          {rowData}
        </tr>
      );
    });
    return <tbody>{data}</tbody>;
  };

    

  const checkSiStatusId = ( si_status_id, is_download)=> {
    if (si_status_id === 90 || si_status_id === 75 || si_status_id === 105 ){
      if(is_download) return true;
      else return false;
    }else
    return true;
  }

  const checkBillNo = (si_status_id, is_download) => {
    if (si_status_id === 90 || si_status_id === 75 || si_status_id === 105){
      if(is_download) return true;
      else return false;
    }else return false;
  }

  const updateInvoiceStatus = (e, uuid) => {
    let value = e.target.checked;
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.is_download = value;
      }
    });
};

  const setMrpValueFunc = (row) => {
    return <div>{row.purchase_with_tax / (1 - row.vendor_discount)}</div>;
  };

  const inputValueChangedSku = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.sku_code = e.target.value;
        setSkuCodeValue(e.target.value);
      }
    });
  };

  const inputValueChangedRemarks = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.remark = e.target.value;
      }
    });
  };

  const inputValueChangedSkuDesc = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.sku_desc = e.target.value;
      }
    });
  };

  const inputValueChangedQuantity = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.quantity = e.target.value;
    });
  };

  const inputValueChangedPrice = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.mrp = e.target.value;
      }
    });
  };

  const inputValueChangedPartType = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.part_type = e.target.value;
    });
  };

  const inputValueChangedUpdated = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        setCurrentEtd(e.target.value);
        setDateUpdated({ uuid: uuid, isUpdated: true });
        data.etd = e.target.value;
      }
    });
  };

  const inputValueChangedUpdatedTime = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.etd_time = e.target.value;
      }
    });
  };

  const inputValueChangedStatus = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.si_status_id = Number(e.target.value);
    });
  };

  const inputValueChangedVendorName = (e, vendorId, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.vendor_name = e.target.value;
        // data.vendor_id = vendorId;
      }
      return data;
    });
  };

  const inputValueChangedBrandName = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.sku_brand = e.target.value;
    });
  };

  const inputValueChangedFinance = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.is_finance_approved = e.target.value;
      }
    });
  };

  const inputValueChangedTax = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.tax_rate = e.target.value;
    });
  };

  const inputValueChangedHSN = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) data.sku_hsn_code = e.target.value;
    });
  };

  const inputValueChangedDiscount = (e, uuid) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.discount = Number(e.target.value);
      }
    });
  };

  const handleCheckBoxChange = (e, uuid) => {
    let value = e.target.checked;
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.is_ticked = value;
      }
    });
  };

  const warehouse_checkbox = (e, uuid) => {
    let value = e.target.checked;
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        data.order_from_warehouse = value;
      }
    });
  };

  const inputValueChangedGlobal = (e, uuid, key) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        switch (key) {
          // case "purchase_amount":
          // 	data.purchase_amount = e.target.value;
          // 	data.purchase_with_tax =
          // 		parseFloat(data.purchase_amount) *
          // 			(parseFloat(data.tax_rate) / 100) +
          // 		parseFloat(data.purchase_amount);
          // 	setPurchaseTax(
          // 		parseFloat(data.purchase_amount) *
          // 			(parseFloat(data.tax_rate) / 100) +
          // 			parseFloat(data.purchase_amount)
          // 	);
          // 	break;
          case "invoice_no":
            data.invoice_no = e.target.value;
            break;
          case "purchase_with_tax":
            data.purchase_with_tax = e.target.value;
            data.mrp = e.target.value / (1 - data.vendor_discount / 100);
            break;
          case "vendor_discount":
            data.vendor_discount = +e.target.value;
            data.mrp = data.purchase_with_tax / (1 - e.target.value / 100);
            break;
          default:
            break;
        }
      }
    });
  };

  const inputValueChangedPickupAndDelivery = (e, uuid, key) => {
    tableBodyData.map((data) => {
      if (data.uuid === uuid) {
        switch (key) {
          case "pickup":
            data.pickup_date = e.target.value;
            setCurrentVendor(e.target.value);
            break;
          case "delivery":
            data.delivery_date = e.target.value;
            break;
          default:
            break;
        }
      }
    });
  };

  const sendToOrderStatusPage = (orders, uuid, spareId) => {
    tableBodyData.map((item) => {
      if (item.uuid === uuid) {
        props.history.push("/spares-orders/review/order-details", {
          carData: carData,
          uuid: uuid,
          spare_order_id: spareId,
          activeOption: bulkOrCar,
          partType: orders.part_type,
        });
      }
    });
  };

  const makeTableEditable = (uuid) => {
    tableBodyData.map((item, index) => {
      if (item.uuid === uuid) {
        setIsDisabled({ uuid: uuid, isDisabled: !isDisabled });
      }
    });
  };

  let vendorNameList;

  const showStockOneId = (key, id, isStockOneOrder) => {
    let stockOneId;
    id ? (stockOneId = id) : (stockOneId = "N/A");

    if (key === "available_in") {
      return <div>{isStockOneOrder ? "Warehouse" : "Other Vendor"}</div>;
    }

    if (vendorsList) {
      vendorNameList =
        vendorsList.length > 0 &&
        vendorsList.map((item, i) => {
          return (
            <option key={i} value={item.name}>
              {item.name + " - " + item.location}
            </option>
          );
        });
    }
    return <div>{id && id.length < 50 ? id : "N/A"}</div>;
  };

  // const fillAllEtds = (etds) => {
  // // 	console.log("e.target ", currentEtd);
  // // 	tableBodyData.map((row) => {
  // // 		if (row.etd) row.etd = currentEtd;
  // // 		// console.log("rtd ", etd);
  // // 	});
  // // };

  const makeInputField = (actionType, orderId) => {
    let brandListName;
    if (vendorsList) {
      vendorNameList =
        vendorsList.length > 0 &&
        vendorsList.map((item, i) => {
          return (
            <option key={i} value={item.name}>
              {item.name + " - " + item.location}
            </option>
          );
        });
    }
    if (brandList) {
      brandListName =
        brandList.length > 0 &&
        brandList.map((item, i) => {
          return (
            <option key={i} value={item.sku_brand}>
              {item.sku_brand}
            </option>
          );
        });
    }
    let disabledRow;
    if (isGlobalEdit == true) disabledRow = true;
    else if (disabledRow == false) disabledRow = false;
    else disabledRow = disabledRow;

    if (isGlobalEdit != false)
      disabledRow =
        orderId.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    // if (isDisabled.isDisabled == false) isGlobalEdit = isDisabled.isDisabled
    if (isEditable === true) disabledRow = true;
    else if (isEditable === false) disabledRow = false;
    else disabledRow = disabledRow;

    const d = new Date();
    if (activeTab == undefined || activeTab == "undefined") {
      activeTab = {
        header: "Fulfillment",
      };
    }
    return (
      <div className="flex justifyContentCenter">
        {actionType === "sku_code" &&
          (activeTab.header == "Delivery" ? (
            <div>{orderId.sku_code}</div>
          ) : (
            <div style={{ display: "flex" }}>
              <input
                className="border focus:border-slate-400"
                style={{ width: "120px", padding: "10px" }}
                disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
                onChange={(e) => inputValueChangedSku(e, orderId.uuid)}
                defaultValue={orderId.sku_code}
                onFocus={() => setSkuFocus(true)}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              />
              {skuFocus && !disabledRow && (
                <div
                  className="py-1 px-3 text-sm rounded d-flex justify-center items-center bg-green-600 text-white ml-1"
                  onClick={() => {
                    // setSpareOrderIdForReview(orderId);
                    // dispatch({ type: SET_ORDER_ID, data: orderId });
                    fetchDataSku(skuCodeValue, orderId.uuid);
                  }}
                >
                  Fetch
                </div>
              )}
            </div>
          ))}
        {actionType === "remark" && (
          <div>
            <textarea
              className="border focus:border-slate-400"
              style={{ width: "120px", padding: "10px" }}
              disabled={disabledRow}
              onChange={(e) => inputValueChangedRemarks(e, orderId.uuid)}
              defaultValue={orderId.remark}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "quantity" &&
          // (activeTab.header === "Delivery" ? (
          //   <div>{orderId.quantity}</div>
          // ) : (
            <div>
              <input
                className="border focus:border-slate-400"
                style={{ width: "30px", padding: "10px" }}
                disabled={disabledRow || (isNonNetowrkUser() && (activeTab.header === "Delivery" ? orderId.si_status_id === 90 : [75, 90].includes(orderId.si_status_id)))}
                onChange={(e) => inputValueChangedQuantity(e, orderId.uuid)}
                defaultValue={orderId.quantity}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              />
            </div>
          // ))
          }
        {actionType === "part_type" && (
          <div>
            <select
              className="border focus:border-slate-400"
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.part_type}
              onChange={(e) => inputValueChangedPartType(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            >
              <option value="oes">OES</option>
              <option value="oem">OEM</option>
            </select>
          </div>
        )}
        {actionType === "vendor_name" &&
          (activeTab.header == "Delivery" ? (
            <div>{orderId.vendor_name}</div>
          ) : (
            <div>
              {
                <select
                  className="border focus:border-slate-400"
                  disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
                  defaultValue={orderId.vendor_name}
                  onChange={(e) => inputValueChangedVendorName(e, vendorsList?.find(v => v.name === e.target.value)?.id, orderId.uuid)}
                  onBlur={() => setProcurementUUIDs(orderId.uuid)}
                >
                  <option>Select Vendor</option>
                  {orderId.vendor_name
                    ? // <option> {orderId.vendor_name}</option>
                    vendorNameList
                    : vendorNameList}
                </select>
              }
            </div>
          ))}
        {actionType === "brand_name" && (
          <div>
            {
              <select
                className="border focus:border-slate-400"
                disabled={disabledRow}
                defaultValue={orderId.sku_brand}
                onChange={(e) => inputValueChangedBrandName(e, orderId.uuid)}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              >
                {orderId.sku_brand ? (
                  <option> {orderId.sku_brand}</option>
                ) : (
                  brandListName
                )}
              </select>
            }
          </div>
        )}
        {actionType === "spares_inventory" && (
          <div style={{ width: "250px" }}>
            {orderId.nearest_warehouse &&
              <input
                type="checkbox"
                disabled={disabledRow}
                defaultChecked={orderId.order_from_warehouse}
                onChange={(e) => warehouse_checkbox(e, orderId.uuid)}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              />
            }
            <span style={{ paddingLeft: "10px" }}>
              {orderId.title}
            </span>
          </div>
        )}
        {actionType === "etd" &&
          (activeTab.header === "Delivery" ||
            activeTab.header === "Fulfillment" ? (
            <div className="border hover:border-slate-400">
              {orderId.etd ? moment(orderId.etd).format("YYYY-MM-DD") : ""}
              {/* : moment(d).format("YYYY-MM-DD")} */}
            </div>
          ) : (
            <div>
              <input
                className="border focus:border-slate-400"
                type="date"
                style={{ padding: "10px" }}
                disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))} 
                value={
                  //   (orderId.etd =
                  // ?
                  moment(orderId.etd).format("YYYY-MM-DD")
                  // )
                  // : moment(d).format("YYYY-MM-DD")
                }
                onChange={(e) => inputValueChangedUpdated(e, orderId.uuid)}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              />
              <div style={{ display: "flex" }}>
                {orderId.etd || dateUpdated.isUpdated ? (
                  <>
                    <input
                      className="border focus:border-slate-400"
                      type="time"
                      style={{ padding: "10px" }}
                      disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
                      // disabled={orderId.etd ? true : false}
                      defaultValue={orderId.etd_time ? orderId.etd_time : "N/A"}
                      onChange={(e) =>
                        inputValueChangedUpdatedTime(e, orderId.uuid)
                      }
                      onBlur={() => setProcurementUUIDs(orderId.uuid)}
                    />
                  </>
                ) : null}
                {!disabledRow &&
                  orderId &&
                  orderId.etd &&
                  orderId.etd[0] &&
                  !isEtdsFilled && (
                    <div
                      style={{
                        padding: "4px",
                        alignSelf: "center",
                      }}
                      className="makeButton marginLeft-2rem makeFunGreenButton"
                      onClick={() => {
                        fillAllEtds(currentEtd);
                        setIsEtdsFilled(true);
                      }}
                    >
                      Fill All
                    </div>
                  )}
              </div>
            </div>
          ))}
        {actionType === "mrp" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "40px", padding: "10px" }}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.mrp}
              onChange={(e) => inputValueChangedPrice(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "vendor_discount" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "40px", padding: "10px" }}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.vendor_discount}
              onChange={(e) =>
                inputValueChangedGlobal(e, orderId.uuid, "vendor_discount")
              }
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "sku_desc" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ padding: "10px" }}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.sku_desc}
              onChange={(e) => inputValueChangedSkuDesc(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "pickup_date" && (
          <div>
            <input
              className="border focus:border-slate-400"
              type="date"
              style={{ padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              value={
                orderId.pickup_date
                  ? moment(orderId.pickup_date).format("YYYY-MM-DD")
                  : "yyyy-MM-dd"
              }
              onChange={(e) =>
                inputValueChangedPickupAndDelivery(e, orderId.uuid, "pickup")
              }
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
            {!activeTab.header.includes("Del") && (
              <>
                {!disabledRow &&
                  orderId &&
                  orderId.pickup_date &&
                  orderId.pickup_date[0] &&
                  !isPickupFilled && (
                    <div
                      style={{
                        padding: "4px",
                        alignSelf: "center",
                      }}
                      className="makeButton marginLeft-2rem makeFunGreenButton"
                      onClick={() => {
                        fillAllVendors(currentVendor);
                        setIsPickupFilled(true);
                      }}
                    >
                      Fill All
                    </div>
                  )}
              </>
            )}
          </div>
        )}
        {actionType === "delivery_date" && (
          <div>
            <input
              className="border focus:border-slate-400"
              type="date"
              style={{ padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && orderId.si_status_id === 90)}
              defaultValue={
                orderId.delivery_date
                  ? moment(orderId.delivery_date).format("YYYY-MM-DD")
                  : "YYYY-MM-DD"
              }
              onChange={(e) => orderId.si_status_id === 90 && inputValueChangedPickupAndDelivery(e, orderId.uuid, "delivery")}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "sku_hsn_code" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "40px", padding: "10px" }}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.sku_hsn_code}
              onChange={(e) => inputValueChangedHSN(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
              required
            />
          </div>
        )}
        {actionType === "invoice" &&
          garageDetails.userRole.includes("insurance") && (
            <div>
              <input
                type="checkbox"
                disabled={disabledRow}
                defaultChecked={orderId.is_ticked}
                onChange={(e) => handleCheckBoxChange(e, orderId.uuid)}
                onBlur={() => setProcurementUUIDs(orderId.uuid)}
              />
            </div>
          )}
        {actionType === "is_finance_approved" && (
          <div>
            <select
              className="border focus:border-slate-400"
              disabled={disabledRow}
              defaultValue={orderId.is_finance_approved}
              onChange={(e) => inputValueChangedFinance(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
        )}
        {actionType === "tax_rate" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "35px", padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.tax_rate}
              onChange={(e) => inputValueChangedTax(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "discount" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "35px", padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.discount}
              onChange={(e) => inputValueChangedDiscount(e, orderId.uuid)}
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "purchase_amount" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "35px", padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.purchase_amount}
              onChange={(e) =>
                inputValueChangedGlobal(e, orderId.uuid, "purchase_amount")
              }
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "purchase_with_tax" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "35px", padding: "10px" }}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.purchase_with_tax}
              onChange={(e) =>
                inputValueChangedGlobal(e, orderId.uuid, "purchase_with_tax")
              }
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        {actionType === "invoice_no" && (
          <div>
            <input
              className="border focus:border-slate-400"
              style={{ width: "35px", padding: "10px" }}
              // disabled={isEditable}
              disabled={disabledRow || (isNonNetowrkUser() && [75, 90].includes(orderId.si_status_id))}
              defaultValue={orderId.invoice_no}
              onChange={(e) =>
                inputValueChangedGlobal(e, orderId.uuid, "invoice_no")
              }
              onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        
        {actionType === "invoice_checkbox" && (
          <div>
            <input type="checkbox"
            onChange={(event) => updateInvoiceStatus(event, orderId.uuid)}
            disabled={disabledRow ? disabledRow : checkSiStatusId(orderId.si_status_id,orderId.is_download)}
            defaultChecked={checkBillNo(orderId.si_status_id,orderId.is_download)}
            onBlur={() => setProcurementUUIDs(orderId.uuid)}
            />
          </div>
        )}
        
      </div>
    );
  };

  const loader = () => (
    <tbody>
      <tr>
        <td
          colSpan={`${tableHeaderData.length / 2}`}
          className="border-right-none"
        >
          <div className="loader margin-left-30vw"></div>
        </td>
      </tr>
    </tbody>
  );

  const noDataFound = () => (
    <tbody>
      <tr>
        <td
          colSpan={`${tableHeaderData.length / 2}`}
          className="border-right-none"
        >
          <div className="no-data-found">No Data Found</div>
        </td>
      </tr>
    </tbody>
  );

  const reviewActions = (actionType, orderId, row_garage_id) => (
    <div>
      {actionType === "Review" &&
        ((garageDetails.userRole.includes("proc") ||
          garageDetails.userRole.includes("del") ||
          garageDetails.userRole.includes("ful")) && garageDetails.userRole !== "nn_ins_procurement") ? (
        <div
          className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
          style={{
            margin : "auto"
          }}
          onClick={() => {
            setSpareOrderIdForReview({
              orderId: orderId,
              userRoleSelected: garageDetails.userRole,
              garageId: row_garage_id,
            });
            dispatch({ type: SET_ORDER_ID, data: { orderId: orderId } });
          }}
        >
          Review
        </div>
      ) : (
        <select
          style={{
            margin : "auto"
          }}
          onChange={(e) => {
            setSpareOrderIdForReview({
              orderId: orderId,
              userRoleSelected: e.target.value,
            });
            dispatch({
              type: SET_ORDER_ID,
              data: { orderId: orderId, userRoleSelected: e.target.value },
            });
          }}
        >
          <option>Review As</option>
          {!garageDetails.userRole.includes("nn") && (
            <option value="fulfillment"> Fulfillment</option>
          )}
          <option value="procurement"> Procurement</option>
          <option value="delivery"> Delivery</option>
        </select>
      )}
    </div>
  );

  const reviewCancel = (actionType, row) => (
    <div>
      {actionType === "Cancel" && (
        <div
          style={{margin : 'auto'}}
          className={`makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem ${row?.cancel_reason ? 'button-disabled' : ''}`}
          onClick={() => {
            // setSpareOrderIdForReview(orderId);
            // dispatch({ type: SET_ORDER_ID, data: orderId });
            if(!row?.cancel_reason){
              cancelOrder(row?.spare_order_id);
            } 
          }}
        >
          Cancel
        </div>
      )}
    </div>
  );

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const getMaximaETD = (key, row) => {
    const dates = row.spares_items.map((etd) => {
      if (etd.etd) {
        let start = etd.etd.split("T");
        return new Date(start[0]);
      }
    });
    var maxDate = new Date(Math.max.apply(null, dates));
    let finalMaxDate = convert(maxDate);

    return (
      <>{finalMaxDate && !finalMaxDate.includes("Na") ? finalMaxDate : "NA"}</>
    );
  };

  const showActionKeys = (actionType, orders, orderId, index) => (
    <div>
      {actionType == "Actions" ? (
        <div className="flex" style={{ width: "146px" }}>
          <div
            className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
            onClick={() =>
              sendToOrderStatusPage(orders, orders.uuid, orders.spare_order_id)
            }
          >
            More Details
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="flex">
        {(orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true) ? (
          <div
            className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
            onClick={() => makeTableEditable(orders.uuid)}
          >
            Edit
          </div>
        ) : (
          <div
            className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
            onClick={() => {
              setIsDisabled(!isDisabled);
              editAndSaveData(orders, orders.uuid);
            }}
          >
            Save
          </div>
        )}
      </div>
    </div>
  );

  const showMoreDetails = (actionType, orders, orderId) => {
    // const disabledRow =
    //     orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    let disabledRow;
    if (isGlobalEdit) disabledRow = true;
    else disabledRow = false;

    if (isGlobalEdit)
      disabledRow =
        orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    return (
      <div>
        <div className="flex">
          <div
            className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
            onClick={() => downloadPhotos(orders.uuid)}
          >
            Download Photos
          </div>
        </div>
        {!garageDetails.userRole.includes("nn") && (
          <div className="flex">
            {orders.si_status_id == 90 && <div>Delivered</div>}
            {orders.si_status_id == 15 && <div>Open</div>}
            {orders.si_status_id == 30 && <div>Procuring</div>}
            {orders.si_status_id == 65 && <div>Part Not Available</div>}
            {orders.si_status_id == 60 && <div>Cancelled</div>}
            {orders.si_status_id == 105 && <div>Returned</div>}
            {orders.si_status_id == 75 && <div>Dispatched</div>}
          </div>
        )}
      </div>
    );
  };

  const mapSiStatusIdToKey = (si_status_id) => {
    return <>
      <div className="text-center">
            {si_status_id == 90 && <div>Delivered</div>}
            {si_status_id == 15 && <div>Open</div>}
            {si_status_id == 30 && <div>Procuring</div>}
            {si_status_id == 65 && <div>Part Not Available</div>}
            {si_status_id == 60 && <div>Cancelled</div>}
            {si_status_id == 105 && <div>Returned</div>}
            {si_status_id == 75 && <div>Dispatched</div>}
      </div>
    </>
  }

  const procKeys = (actionType, orders, orderId) => {
    // const disabledRow =
    //     orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    let disabledRow;
    if (isGlobalEdit) disabledRow = true;
    else disabledRow = false;

    if (isGlobalEdit)
      disabledRow =
        orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    return (
      <div>
        <div className="flex">
          <div
            className="makeButton marginLeft-2rem makeFunGreenButton marginBottom-point5rem"
            onClick={() => downloadPhotos(orders.uuid)}
          >
            Download Photos
          </div>
        </div>
        <div className="flex">
          {orders.si_status_id !== 30 &&
            orders.si_status_id !== 35 &&
            orders.si_status_id !== 65 ? (
            <>
              {orders.si_status_id == 105 && <div>Returned</div>}
              {orders.si_status_id == 75 && <div>Dispatched</div>}
              {orders.si_status_id == 90 && <div>Delivered</div>}
              {orders.si_status_id == 15 && <div>Open</div>}
              {orders.si_status_id == 60 && <div>Cancelled</div>}
            </>
          ) : (
            <select
              onChange={(e) => inputValueChangedStatus(e, orders.uuid)}
              disabled={disabledRow || (isNonNetowrkUser() && [75,90].includes(orders.si_status_id))}
              defaultValue={orders.si_status_id}
              onBlur={() => setProcurementUUIDs(orders.uuid)}
            >
              <option value="30">Procuring</option>
              <option value="35">Procured</option>
              <option value="65">Part Not Available</option>
            </select>
          )}
        </div>
      </div>
    );
  };

  const delKeys = (actionType, orders, orderId) => {
    // const disabledRow =
    //     orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;
    let disabledRow;
    if (isGlobalEdit) disabledRow = true;
    else disabledRow = false;

    if (isGlobalEdit)
      disabledRow =
        orders.uuid === isDisabled.uuid ? isDisabled.isDisabled : true;

    return (
      <div>
        <div className="flex">
          <select
            onChange={(e) => inputValueChangedStatus(e, orders.uuid)}
            // disabled={garageDetails.userRole.includes("delivery") && !disabledRow ? false : (disabledRow || orders.si_status_id === 90 && !orders.is_delivery_person)}
            // disabled={garageDetails.userRole.includes("delivery") && (disabledRow || (isNonNetowrkUser() && orders.si_status_id === 90))}
            disabled={(disabledRow || (isNonNetowrkUser() && orders.si_status_id === 90))}
            defaultValue={orders.si_status_id}
            onBlur={() => setProcurementUUIDs(orders.uuid)}
          >
            <option value="35" disabled>
              Procured
            </option>
            <option value="65" disabled>
              Part Not Available
            </option>
            <option value="30">Procuring</option>
            <option value="90">Delivered</option>
            {/* <option value="60">Cancelled</option> */}
            <option value="75">Dispatched</option>
            {!isNonNetowrkUser() && <>
              <option value="105">Returned</option>
              <option value="110">Return Received</option>
            </>}
            {/* <option value="40">Pickup Done</option> */}
          </select>
        </div>
      </div>
    );
  };
  const toggleToolTip = (tooTipID) => {
    let tooltipDOM=document.getElementById(tooTipID)
    if (tooltipDOM.style.display === "none") {
      tooltipDOM.style.display = "block";
    } else {
      tooltipDOM.style.display = "none";
    }
  }

  return (
    <div>
      <div
        className={`overFlowAuto scrollerVariantOne catskillWhiteBorder borderRadius-5-Px ${scrollable ? "table-container" : ""
          }`}
      >
        <table>
          {tableHead()}
          {tableDataLoaded
            ? tableBodyData.length
              ? tableBody()
              : noDataFound()
            : loader()}
        </table>
      </div>
    </div>
  );
};

export default withRouter(
  connect((state) => {
    const { garageDetails } = state;
    return {
      garageDetails,
    };
  })(SparesTable)
);
