import store from "../Redux/Store/Store.js";
import { setToastData } from "../Redux/Actions/ActionCreators";
import { errorHandler } from "../Redux/Utils/error.js";
import { $detachProgressBar, $loadProgressBar } from "../Utils/progressBar.js";
// const baseUrl = "https://nn.garage.gomechanic.app/";
// const baseUrl = "https://staging.garage.gomechanic.app/";
// const baseUrl = "https://dev.nn.garage.gomechanic.app/";
const baseUrl = process.env.REACT_APP_API_URL_NON;

export function getNon(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	return fetch(baseUrl + url, options).then(
		(res) => {
		    if(res.status === 200){
				return res.json();
			}
			else if(res.status === 500){
				errorHandler();
			}
			return null; 
		}
	);
}

export function postNon(url, data) {
	$loadProgressBar();
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			$detachProgressBar();
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function putNon(url, data) {
	$loadProgressBar();
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "PUT",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			$detachProgressBar();
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function patchNon(url, data) {
	let token = "Bearer " + localStorage.getItem("token");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "PATCH",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		(res) => res.json(),
		(err) => err
	);
}

export function downloadNon(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	return fetch(baseUrl + url, options).then(
		(res) => (res.status === 200 ? res : null),
		(err) => err
	);
}

export function deleteurlNon(url, data) {
	$loadProgressBar();
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "DELETE",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			$detachProgressBar();
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

//  export function get(url){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"GET",
//         headers:headers,
//     }
//     return fetch((baseUrl+url), options).then(async res=> {
//         const response = res.status===200 ? await res.json() : null
//         store.dispatch(setToastData({
//             message: response ? response.message : 'No Data Found', status: response ? res.status : ''
//         }));
//         return response }, err=>err);
// }

// export function post(url,data){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"POST",
//         headers:headers,
//         body:JSON.stringify(data),
//     }
//     return fetch(baseUrl+url, options).then(res=>res.json(), err=>err);
// }

// export function put(url,data){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"PUT",
//         headers:headers,
//         body:JSON.stringify(data),
//     }
//     return fetch(baseUrl+url, options).then(res=>res.json(),err=>err);
// }
