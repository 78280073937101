import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import { sessionMiddleware } from '../Middlewares/session.middleware';
import reducers from '../Reducers/Reducers';
// import logger from 'redux-logger'
// window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_()

const initialState = {
    loginToken: localStorage.getItem('garageToken'),
    garageDetails: JSON.parse(localStorage.getItem('garageDetails')) || {},
    activeSideBarHeader: JSON.parse(localStorage.getItem('activeSideBarHeader')) || { headerName: 'Dashboard', subHeaderName: null },
    toastData: {},
    cityId: '',
    reconOrderId: null,
    // is_session_active : false
}

function configureStore(preloadedState){
    const middlewares = [sessionMiddleware, thunkMiddleware];
    const middlewareEnhancers = applyMiddleware(...middlewares);

    const store = createStore(reducers, preloadedState, middlewareEnhancers);
    return store;
}

export default configureStore(initialState)
