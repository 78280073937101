import React, { useState, useEffect } from 'react';
import './InputBox.css';

const Input = (props) => {
  const [state, updateState] = useState({
    isInputActive: false,
    value: ''
  });
  useEffect(() => {
    if (props.type === "date" || props.type === "time") {
      updateState({ ...state, isInputActive: true })
    }
  }, [])

  useEffect(() => {
    if (props.value) {
      updateState({ value: props.value, isInputActive: true })
    }
    else {
      if (props.type === "date" || props.type === "time") {
        updateState({ value: '', isInputActive: true })
      }
      else {
        updateState({ value: '', isInputActive: false })
      }
    }
  }, [props.value])

  const onPressEnter = (e) => {
    if (e.keyCode === 13) { props.onPressEnter(e.target.value) }
  }

  const onChange = (e) => {
    updateState({ ...state, value: e.target.value })
    // props.onChange(e.target.value)
  }
  const onFloat = () => {
    if (!state.isInputActive) {
      updateState({ ...state, isInputActive: true })
    }
  }

  const floatOut = (event) => {
    if (state.isInputActive && !event.target.value && !(props.type === "date") && !(props.type === "time")) {
      updateState({ ...state, isInputActive: false })
    }
  }

  let textInput = React.createRef();
  return (
    <div id="inputContainer"
      className={`${state.isInputActive ? 'inputContainer active' : 'inputContainer'}${(props.notValid) ? ' error' : ''} ${props.customClassName ? props.customClassName : ""}`}
    >
      <span className="inputTitle" onClick={() => {
        textInput.current.focus();
        updateState({ ...state, isInputActive: true })
      }}>{props.label}</span>
      <div className="mainInputContainer">
        <input ref={textInput} autoComplete="off" id="floatField" disabled={props.disabled ? props.disabled : false}
          type={props.type} className="mainInput" value={state.value} onChange={onChange} onFocus={onFloat}
          onBlur={floatOut} placeholder='dd-mm-year' onKeyDown={onPressEnter}
        />
      </div>
      <div className="helperText">{props.helperText}</div>
    </div>
  )
}

export default Input;