import React, {createContext, useState} from 'react';
import moment from 'moment';

export const FilterContext = createContext();

export const FilterContextProvider = ({children}) => {
    
    const [filterState, setFilterState] = useState({
        city : "",
        region : "",
        orderStatus : "",
        dateRange : {
            startDate: "01-".concat(moment(new Date()).format("MM-YYYY")),
            endDate: moment(new Date()).format("DD-MM-YYYY"),
        },
        brand : "",
        ageing : ""
    })

    return <>
        <FilterContext.Provider
            value={{
                filterState,
                setFilterState
            }}
        >
            {children}
        </FilterContext.Provider>
    </>
}