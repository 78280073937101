import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Toast.css'

const Toast = (props) => {
    const [showToastClass, setShowToastClass] = useState('')
    const { toastData } = props

    useEffect(() => {
        toggleToast()
    }, [toastData]);

    const toggleToast = () => {
        setShowToastClass('show')
        setTimeout(() => setShowToastClass(''), 3000)
    }
    
    return <>
        {
           toastData.message && ((toastData.status === 200 || toastData.status == true) ? <div id='snackbar' className={`${showToastClass} toast-wrapper success`}>
            <div className="toast-icon toast-success">
                <svg width="30" height="30" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                </svg>
            </div>
            <div className="toast-msg">
                {toastData.message || "Success"}
            </div>
        </div> : <div id='snackbar' className={`${showToastClass} toast-wrapper error`}>
            <div className="toast-icon toast-error">
            <svg width="30" height="30" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
            </svg>
            </div>
            <div className="toast-msg">
                {toastData.message || "Something went wrong"}
            </div>
        </div>)     
        }
    </>
}


export default withRouter(connect((state) => {
    const { toastData } = state
    return {
        toastData,
    }
}, {
})(Toast))
