import {GET_RETURNS_STATS, GET_RETURNS, DOWNLOAD_INVOICE, SET_LOADER, MUTATE_PROCESS, LOCAL_MUTATE, ADD_UUIDS, SET_SPARE_ID, RESET_UUIDS, MASTER_RESET} from '../ReduxConstants/Returns';


const initState = {
    processState : "edit_state",
    returns_stats : {},
    returns_line_items : [],
    loading : false,
    save_process_uuid : [],
    spare_order_id : null
}

export const ReturnsReducer = (state = initState, {type, payload}) => {
    let copyState = JSON.parse(JSON.stringify(state));
    switch(type){
        case GET_RETURNS_STATS : return {...copyState, returns_stats : payload};
        case GET_RETURNS: return {...copyState, returns_line_items : payload};
        case MUTATE_PROCESS: return {...copyState, processState : payload};
        case LOCAL_MUTATE : return {...copyState, returns_line_items : payload};
        case ADD_UUIDS : return {...copyState, save_process_uuid : [...copyState.save_process_uuid, payload]};
        case SET_LOADER: return {...copyState, loading : payload};
        case SET_SPARE_ID : return {...copyState, spare_order_id : payload};
        case RESET_UUIDS : return {...copyState, save_process_uuid : []};
        case MASTER_RESET : return initState;
        default:
            return copyState;
    }
}