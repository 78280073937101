import React from 'react';
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import Routes from './Components/Routes/Routes'
import "./Styles/GlobalCss.css";
import 'rsuite/dist/rsuite.min.css';

function App() {
  return (
    <Provider store={store} >
      <Routes />
    </Provider>
  );
}

export default App;
