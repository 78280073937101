const tableHeaderData = [
	"Date of Order",
	"Part Number",
	"Part Name(app)",
	"Part Name(Spares Catalouges)",
	"Quantity",
	"Make",
	"Stockone Id",
	"Available in",
	// "Vendor Name",
	"MRP",
	"Tax Rate",
	"Workshop Discount",
	"HSN",
	"ETD",
	"Order Status",
	"Actions",
];

const tableHeaderDataProc = [
	"Part Number",
	"Part Name",
	"Quantity",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"MRP",
	"Tax Rate",
	"Workshop Discount",
	"HSN",
	// "Purchase without Tax",
	"Vendor Discount",
	"Purchase with Tax",
	"Invoice Number",
	// "Vendor Location",
	"Status",
	"Invoice"
];

const tableHeaderKeysProc = [
	"sku_code",
	"sku_desc",
	"quantity",
	"etd",
	"pickup_date",
	"vendor_name",
	"mrp",
	"tax_rate",
	"discount",
	"sku_hsn_code",
	// "purchase_amount",
	"vendor_discount",
	"purchase_with_tax",
	"invoice_no",
	// "vendor_location",
	"Order Status Proc",
	"invoice"
];

const tableHeaderDataProcBulk = [
	"Car Make",
	"Model",
	"Fuel Type",
	"Part Number",
	"Part Name",
	"Quantity",
	"Make",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"MRP",
	"Tax Rate",
	"Workshop Discount",
	"HSN",
	// "Vendor Location",
	// "Purchase without Tax",
	"Vendor Discount",
	"Purchase with Tax",
	"Invoice Number",
	"Status",
];

const tableHeaderKeysProcBulk = [
	"car_brand",
	"car_model",
	"car_type",
	"sku_code",
	"sku_desc",
	"quantity",
	"part_type",
	"etd",
	"pickup_date",
	"vendor_name",
	"mrp",
	"tax_rate",
	"discount",
	"sku_hsn_code",
	// "vendor_location",
	// "purchase_amount",
	"vendor_discount",
	"purchase_with_tax",
	"invoice_no",
	"Order Status Proc",
];

const tableHeaderDataProcBulkNonNet = [
	"Date of Order",
	"Car Make",
	"Model",
	"Fuel Type",
	"Reg No.",
	"Variant No.",
	"Chassis No.",
	"Vin No.",
	"Part Number",
	"Part Name",
	"Quantity",
	"Make",
	"Warehouse Availability",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"MRP",
	"Info",
	"Tax Rate",
	"Workshop Selling Price",
	"Workshop Discount",
	"HSN",
	// "Vendor Location",
	// "Download Photos",
	// "Purchase without Tax",
	"Vendor Discount",

	"Purchase with Tax",
	"Invoice Number",
	"Status",
	"Invoice"
];

const tableHeaderDataProcBulkNonNetIns = tableHeaderDataProcBulkNonNet
										 .filter((_, i) => ![15,21,22,23]?.includes(i))

const tableHeaderKeysProcBulkNonNet = [
	"placed_on",
	"car_brand",
	"car_model",
	"car_type",
	"registration_no",
	"car_varient",
	"chassis_no",
	"vin_no",
	"sku_code",
	"sku_desc",
	"quantity",
	"part_type",
	"spares_inventory",
	"etd",
	"pickup_date",
	"vendor_name",
	"mrp",
	"info",
	"tax_rate",
	"selling_price",
	"discount",
	"sku_hsn_code",
	// "vendor_location",
	// "Download Photos",
	// "purchase_amount",
	"vendor_discount",
	"purchase_with_tax",
	"invoice_no",
	"Order Status Proc",
	"invoice_checkbox"
];

const tableHeaderKeysProcBulkNonNetIns = tableHeaderKeysProcBulkNonNet
										 .filter((_, i) => ![15,21,22,23]?.includes(i))


const tableHeaderDataDelBulk = [
	"Car Make",
	"Model",
	"Fuel Type",
	"Part Number",
	"Part Name",
	"Make",
	"Quantity",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"Workshop Delivery Date",
	// "Purchase without Tax",
	"Vendor Discount",

	"Purchase with Tax",
	"Invoice Number",
	"Remarks",
	"Status",
	// "Invoice"
];

const tableHeaderDataReturnNonNet = [
	"Car Make",
	"Model",
	"Fuel Type",
	"Reg No.",
	"Variant No.",
	"Chassis No.",
	"Vin No.",
	"Part Number",
	"Part Name",
	"Make",
	"Quantity",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"Workshop Delivery Date",
	"MRP",
	"Purchase With Tax",
	"Invoice Number",
	"Status",
	"Return Qty",
	"Mark Return"
];


const tableHeaderKeysReturnNonNet = [
	"car_brand",
	"car_model",
	"car_type",
	"registration_no",
	"car_varient",
	"chassis_no",
	"vin_no",
	"sku_code",
	"sku_desc",
	"part_type",
	"quantity",
	"etd",
	"pickup_date",
	"vendor_name",
	"delivery_date",
	"mrp",
	"purchase_with_tax",
	"invoice_no",
	"Order Status Del",
	"return_quantity",
	"is_return_item"
];

const tableHeaderDataDelBulkNonNet = [
	"Car Make",
	"Model",
	"Fuel Type",
	"Reg No.",
	"Variant No.",
	"Chassis No.",
	"Vin No.",
	"Part Number",
	"Part Name",
	"Make",
	"Quantity",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"Workshop Delivery Date",
	// "Purchase without Tax",
	"Vendor Discount",

	"Purchase With Tax",
	"Invoice Number",
	"Remarks",
	"Status",
	// "Invoice"
];

const tableHeaderDataDelNotIns = tableHeaderDataDelBulkNonNet
								 .filter((_, i) => ![13,15,16].includes(i));

const tableHeaderKeysDelBulk = [
	"car_brand",
	"car_model",
	"car_type",
	"sku_code",
	"sku_desc",
	"part_type",
	"quantity",
	"etd",
	"pickup_date",
	"vendor_name",
	"delivery_date",
	// "purchase_amount",
	"vendor_discount",
	"purchase_with_tax",
	"invoice_no",
	"remark",
	"Order Status Del",
	// "invoice"
];

const tableHeaderKeysDelBulkNonNet = [
	"car_brand",
	"car_model",
	"car_type",
	"registration_no",
	"car_varient",
	"chassis_no",
	"vin_no",
	"sku_code",
	"sku_desc",
	"part_type",
	"quantity",
	"etd",
	"pickup_date",
	"vendor_name",
	"delivery_date",
	// "purchase_amount",
	"vendor_discount",
	"purchase_with_tax",
	"invoice_no",
	"remark",
	"Order Status Del",
	// "invoice"
];

const tableHeaderKeysDelNotIns = tableHeaderKeysDelBulkNonNet
								 .filter((_, i) => ![13,15,16].includes(i));


const tableHeaderDataDel = [
	"Part Number",
	"Part Name",
	"Quantity",
	"ETD",
	"Vendor Pickup Date",
	"Vendor Name",
	"Workshop Delivery Date",
	"Remarks",
	"Status",
	"Invoice"
];

const tableHeaderKeysDel = [
	"sku_code",
	"estimate_name",
	"quantity",
	"etd",
	"pickup_date",
	"vendor_name",
	"delivery_date",
	"remark",
	"Order Status Del",
	"invoice"
];

const tableHeaderKeys = [
	"placed_on",
	"sku_code",
	"estimate_name",
	"sku_desc_new",
	"quantity",
	"part_type",
	"stockone_order_id",
	"available_in",
	// "vendor_name",
	"mrp",
	"tax_rate",
	"discount",
	"sku_hsn_code",
	"etd",
	"Order Status",
	"Actions",
];

const tableHeaderDataBulk = [
	"Date of Order",
	"Car Make",
	"Model",
	"Fuel Type",
	// "Reg No.",
	// "Order ID",
	"Part Number",
	"Part Name(app)",
	"Part Name(Spares Catalouges)",
	"Quantity",
	"Make",
	"Stockone Id",
	"Available in",
	// "Vendor Name",
	"MRP",
	"Tax Rate",
	"Workshop Discount",
	"HSN",
	"ETD",
	"Order Status",
	"Actions",
];

const tableHeaderDataBulkNonNet = [
	"Date of Order",
	"Car Make",
	"Model",
	"Fuel Type",
	"Reg No.",
	"Variant No.",
	"Chassis No.",
	"Vin No.",
	// "Order ID",
	"Part Number",
	"Part Name(app)",
	"Part Name(Spares Catalouges)",
	"Quantity",
	"Make",
	"Stockone Id",
	"Available in",
	// "Vendor Name",
	"MRP",
	"Tax Rate",
	"Workshop Discount",
	"HSN",
	"ETD",
	"Order Status",
	"Actions",
];

const tableHeaderKeysBulk = [
	"placed_on",
	"car_brand",
	"car_model",
	"car_type",
	// "registration_no",
	// "gm_order_id",
	"sku_code",
	"estimate_name",
	"sku_desc",
	"quantity",
	"part_type",
	"stockone_order_id",
	"available_in",
	// "vendor_name",
	"mrp",
	"tax_rate",
	"discount",
	"sku_hsn_code",
	"etd",
	"Order Status",
	"Actions",
];

const tableHeaderKeysBulkNonNet = [
	"placed_on",
	"car_brand",
	"car_model",
	"car_type",
	"registration_no",
	"car_varient",
	"chassis_no",
	"vin_no",
	// "registration_no",
	// "gm_order_id",
	"sku_code",
	"estimate_name",
	"sku_desc",
	"quantity",
	"part_type",
	"stockone_order_id",
	"available_in",
	// "vendor_name",
	"mrp",
	"tax_rate",
	"discount",
	"sku_hsn_code",
	"etd",
	"Order Status",
	"Actions",
];

const tableHeaderDataMoreDetails = [
	"Part Number",
	"Part Name",
	"Pick Up",
	"Delivery Date",
	"HSN",
	"Vendor Name ",
	"Vendor Location",
	"Vendor Amount",
	"Tax Rate",
	"Workshop Discount",
	"Vendor Bill Number",
	"Actions",
];

const tableHeaderKeysMoreDetails = [
	"sku_code",
	"sku_desc",
	"pickup_date",
	"delivery_date",
	"sku_hsn_code",
	"vendor_name",
	"vendor_location",
	"mrp",
	"tax_rate",
	"discount",
	"vendor_bill_number",
	"ActionsMore",
];

const tableHeaderDataBulkMoreDetails = [
	"Part Number",
	"Part Name",
	"Pick Up",
	"Delivery Date",
	"HSN",
	"Make",
	// "Brand",
	"Vendor Name ",
	"Vendor Location",
	"Vendor Amount",
	"Tax Rate",
	"Workshop Discount",
	"Vendor Bill Number",
	"Actions",
];

const tableHeaderKeysBulkMoreDetails = [
	"sku_code",
	"sku_desc",
	"pickup_date",
	"delivery_date",
	"sku_hsn_code",
	"part_type",
	// "brand_name",
	"vendor_name",
	"vendor_location",
	"mrp",
	"tax_rate",
	"discount",
	"vendor_bill_number",
	"ActionsMore",
];

module.exports = {
	tableHeaderData,
	tableHeaderKeys,
	tableHeaderDataBulk,
	tableHeaderKeysBulk,
	tableHeaderDataBulkMoreDetails,
	tableHeaderKeysBulkMoreDetails,
	tableHeaderDataMoreDetails,
	tableHeaderKeysMoreDetails,
	tableHeaderDataProc,
	tableHeaderKeysProc,
	tableHeaderDataDel,
	tableHeaderKeysDel,
	tableHeaderDataProcBulk,
	tableHeaderKeysProcBulk,
	tableHeaderDataDelBulk,
	tableHeaderKeysDelBulk,
	tableHeaderDataBulkNonNet,
	tableHeaderKeysBulkNonNet,
	tableHeaderDataProcBulkNonNet,
	tableHeaderKeysProcBulkNonNet,
	tableHeaderKeysProcBulkNonNetIns,
	tableHeaderDataProcBulkNonNetIns,
	tableHeaderDataDelBulkNonNet,
	tableHeaderKeysDelBulkNonNet,
	tableHeaderDataDelNotIns,
	tableHeaderKeysDelNotIns,
	tableHeaderDataReturnNonNet,
	tableHeaderKeysReturnNonNet
};
