function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}

const $appendProgressBar = () => {
    var progressOverlay = document.getElementById("progress-overlay");
    progressOverlay.style.display = "block";
    var container = document.getElementById("progress-bar");
    container.classList.add("progress-container");
    var progressBar = document.createElement("div");
    progressBar.classList.add("progress");
    container.append(progressBar);
    return progressBar;
}

export const $detachProgressBar = () => {
    var progressOverlay = document.getElementById("progress-overlay");
    progressOverlay.style.display = "none";
    var container = document.getElementById("progress-bar");
    removeAllChildNodes(container);
    container.classList.remove("progress-container");
}

export const $loadProgressBar = (progressIncrementTime = 1200) => {
    let intervalProp = null, width = 0;
    let $progressBar = $appendProgressBar();
    clearInterval(intervalProp);
    intervalProp = setInterval(() => {
        if(width == 100){
            // $detachProgressBar();
            clearInterval(intervalProp);
        }
        else{
            width = Number(width) + 10;
            $progressBar.style.width = `${width}%`;
        }
    }, progressIncrementTime);
}