import { SET_SESSION_TIMER, RESET_SESSION_TIMER } from "../ReduxConstants/ReduxConstants";

const initState = {
    is_session_active : false,
};

const dashboardReducer = (state= initState,action) => {
    switch (action.type) {
        case SET_SESSION_TIMER:
            return {
                ...state,
                is_session_active : true
            };
        case RESET_SESSION_TIMER : 
            return {
                ...state,
                is_session_active : false
            };
        default:
            return state;
    }
}
export default dashboardReducer