import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
display : grid;
grid-template-columns : repeat(2, 1fr);
gap : 8px;
background : #fff;
border-radius : 5px;
overflow : hidden;
margin-top : 1rem;
margin-bottom : 1rem;
@media(min-width : 600px){
    grid-template-columns : repeat(4, 1fr);
}
@media(min-width : 1280px){
    grid-template-columns : repeat(7, 1fr);
}
`;

const Tab = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
box-sizing : border-box;
padding : 12px;
gap : 8px;
position : relative;
background : transparent;
height : 86px;
font-size : 0.875rem;
&:not(:last-child)::after{
    display : inline-block;
    position : absolute;
    content : '';
    height : 62px;
    width : 1px;
    background-color : rgba(0, 0, 0, 0.16);
    top : 12px;
    right : 0;
}
`;
const SpareIdTab = styled(Tab)`
background : #fffdec;
font-weight : 600;
&::after{
    background-color : #ffe5b2;
}
`;

const FieldLabel = styled.div`
${({align}) => align ? `text-align : ${align};` : ""}
`;
const FieldValue = styled.div`
${({align}) => align ? `text-align : ${align};` : ""}
`;

export const ReturnStats = ({
    data = {}
}) => {
    return <>
        <Container>
            <SpareIdTab>
                <FieldLabel>Spares ID</FieldLabel>
                <FieldValue>{data?.orderId}</FieldValue>
            </SpareIdTab>
            <Tab>
                <FieldLabel align={"center"} style={{fontWeight : 600}}>Total Vendor Purchase Amount</FieldLabel>
                <FieldValue align={"center"} style={{fontWeight : 600}}>{data?.total_vendor_purchase_amount ?? "N/A"}</FieldValue>
            </Tab>
            <Tab>
                <FieldLabel align={"center"} style={{fontWeight : 600}}>Total Garage Selling Amount</FieldLabel>
                <FieldValue align={"center"} style={{fontWeight : 600}}>{data?.total_garage_selling_amount ?? "N/A"}</FieldValue>
            </Tab>
            <Tab>
                <FieldLabel align={"center"}>Placed Order</FieldLabel>
                <FieldValue align={"center"}>{data?.parts_ordered ?? "N/A"}</FieldValue>
            </Tab>
            <Tab>
                <FieldLabel align={"center"}>Delivered</FieldLabel>
                <FieldValue align={"center"}>{data?.parts_delivered ?? "N/A"}</FieldValue>
            </Tab>
            <Tab>
                <FieldLabel align={"center"}>Cancelled</FieldLabel>
                <FieldValue align={"center"}>{data?.parts_cancelled ?? "N/A"}</FieldValue>
            </Tab>
            <Tab>
                <FieldLabel align={"center"}>Returned</FieldLabel>
                <FieldValue align={"center"}>{data?.parts_returned ?? "N/A"}</FieldValue>
            </Tab>
        </Container>
    </>
}