import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import goMechanicLogo from "../../../Images/new-gomech-logo.png";
import dashboardImage from "../../../Images/dashboard-image.png";
import {
	setSideBarActiveHeader,
	setSideBarToggle,
} from "../../../Redux/Actions/ActionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
	adminUser,
	checkIfSpareRetailerLoggedIn,
	checkIfFinanceAdminOrOps,
	checkIfFinanceAdmin,
	garageOwner,
	isNetworkSparesAdmin,
	isDiscountModule,
	isReturnSpareModule,
	isMalaysiaLogin,
	checkNNInsUser,
	isNonNetowrkUser,
	// isDistributorUser,
	isDistributorAdmin,
	isDistributorUser,
	checkFinanceLevelTwo,
	checkFinanceLevelOne
} from "../../Routes/AdminUsers";
import "./DashBoardSideBar.css";
import Backdrop from "../Backdrop/Backdrop";
import styled from "styled-components";
import useSidebar from "./useSidebar";
// import { checkUserType } from "../../../Methods";

const SubListWrapper = styled.div`
height : ${({n, show}) => show ? Math.floor((n ?? 1)*60 + 2) : 0}px;
`;

const getDashboardTitle = (garageDetails) => {
	if (
		garageDetails.retailer_data &&
		garageDetails.retailer_data.gom_mobil_retailer
	)
		return "Mobil";
	else return "Spares";
};

const getSparesRetailerSidebarHeaderList = (garageDetails) => {
	let sparesRetailerSideBarHeaderTitles = [
		{
			path: "Dashboard",
			title: "Dashboard",
		},
		{
			path: "spares_dashboard",
			title: `${getDashboardTitle(garageDetails)} Transaction`,
		},
		{
			path: "return_credit",
			title: `Return ${getDashboardTitle(garageDetails)}`,
		},
		{
			path: "Payments",
			title: `Payments`,
		},
	];
	if (
		garageDetails.retailer_data &&
		garageDetails.retailer_data.gom_mobil_retailer
	) {
		sparesRetailerSideBarHeaderTitles.push({
			path: "gom_mobil",
			title: "Billed Orders Settlements",
		});
	}
	if (
		garageDetails.retailer_data &&
		garageDetails.retailer_data.gom_spare_retailer
	) {
		sparesRetailerSideBarHeaderTitles.push({
			path: "gom_spares",
			title: "Billed Orders Settlements",
		});
		sparesRetailerSideBarHeaderTitles.push({
			path: "gom_inventory",
			title: "GoM Inventory",
		});
	}

	return sparesRetailerSideBarHeaderTitles;
};

const _route_mapper = {
	["/dashboard/gom-to-distributor"] : "$gom-to-distributor",
	["/dashboard/distributor-to-retailer"] :  "$distributor-to-retailer",
	["/dashboard/return-purchase"] : "$return-purchase",
	["/dashboard/distributor-payment-request"] : "$distributor-payment-request",
	["/dashboard/add-accounts"] : "$add-accounts",
	["/returns"] : "$returns",
	["/dashboard"] : "$dashboard",
	["/billed-orders/garage"] : "$billed-orders-garage",
	["/billed-orders/gomechanic"] : "$billed-orders-gom",
	["/employees/employees-list"] : "$employees-list",
	["/employees/add-employee"] : "$add-employee",
	["/employees/employees-list/edit-employee"] : "$edit-employee",
	["/settlements/by-garage"] : "$by-garage",
	["/settlements/gom-finance"] : "$gom-finance",
	["/reconciliation/settlement-history"] : "$settlement-history",
	["/mobil-orders"] : "$mobil-orders",
	["/settlements/spares-by-gom"] : "$spares-by-gom",
	["/gom-essentials"] : "$gom-essentials",
	["/spares_dashboard"] : "$spares-dashboard",
	["/gom_inventory"] : "$gom-inventory",
	["/gom_mobil"] : "$gom-mobil",
	["/gom_spares"] : "$gom-spares",
	["/return_credit"] : "$return-credit",
	["/payments"] : "$payments",
	["/d0-amount"] : "$do-amount",
	["/spares-orders"] : "$spares-orders",
	["/spares-orders/review"] : "$spares-orders-review",
	["/spares-orders/review/more-details"] : "$more-details",
	["/spares-orders/review/order-details"] : "$order-details",
	["/non-network-retailer-payment-request"] : "$nn-payment-retailer-payment-request",
	["/invoice"] : "$invoice",
	["/discounts"] : "$discounts",
	["/escalations"] : "$escalations",
	["/purchase"] : "$purchase",
	["/reconciliation/region-wise"] : "$region-wise",
	["/reconciliation/city-wise"] : "$city-wise",
	["/payments/garage-recon-requests"] : "$garage-recon-requests",
	["/payments/collection"] : "$payments-collection",
	["/payments/non-network-retailer-payment-request"] : "$nn-retailer-payment-request",
	["/payments/network-retailer-payment-request"] : "$network-retailer-payment-request",
	["/payments/add-accounts"] : "$add-accounts",
	// ["/payments/workshop-pl-entry"] : "$workshop-pl-entry",
	["/payments/payment-by-garages"] : "$payment-by-garages",
	["/reconciliation/garage-wise"] : "$garage-wise",
	["/reconciliation/non-network-garage-wise"] : "$nn-garage-wise",
	["/reconciliation/retailer-payments"] : "$retailer-payments",
	["/reconciliation/distributor-payments"] : "$distributor-payments",
	["/reconciliation/person-wise"] : "$person-wise",
	["/reconciliation/escalations/bill-edit"] : "$bill-edit",
	["/reconciliation/order-tracking"] : "$order-tracking",
	["/reconciliation/pl-return"]: "$pl-return",
	["/garage/garage-list"] : "$garage-list",
	["/garage/add-garage"] : "$add-garage",
	["/onboarding"] : "$onboarding",
	root_link : ""
	}

const DashBoardSideBar = (props) => {
	// console.log(props);
	const {navigateTo} = useSidebar({
		ROUTE_MAPPER : _route_mapper,
		ROUTE_ACTIVE_CLASS : "sidebar-active-tab"
	})
	const [showExpansionPanel, setShowExpansionPanel] = useState({});
	const [showMobileSideBar, setShowMobileSideBar] = useState(false);
	const {
		loginToken,
		activeSideBarHeader,
		setSideBarActiveHeader,
		garageDetails,
	} = props;
	const { headerName, subHeaderName } = activeSideBarHeader;

	useEffect(() => {
		if (props.activeSideBarHeader === true) setShowMobileSideBar(true);
	}, [props.activeSideBarHeader]);

	const adminAccess = (header) => (adminUser() ? header : null);

	const adminOrGarageOwnerOrNSAdmin = (header) => ((garageOwner() || adminUser() || isNetworkSparesAdmin()) ? header : null)

	const exemptFinanceAdminOrOpsOrNSAdmin = (header) => (checkIfFinanceAdminOrOps() || isNetworkSparesAdmin() ? null : header);

	const exemptFinanceAdminorOpsOrGarageOwner = (header) => ((checkIfFinanceAdminOrOps() || garageOwner()) ? null : header); 

	const exemptNSAdmin = (header) => (isNetworkSparesAdmin() ? null : header);

	const exemptNonNetworkUser = (header) => (isNonNetowrkUser() ? null : header);

	const exemptFinanceAdmin = (header) => (checkIfFinanceAdmin() ? header : null);

	const exemptNonNetworkInsurance = (header) => checkNNInsUser() ? null : header;

	const includeFinanceOps = (header) => (checkIfFinanceAdminOrOps() ? header : null);

	const checkIsDiscountModule = (header) => (isDiscountModule() ? header : null);

	const checkIsReturnSpareAllow = (header) => (isReturnSpareModule() ? header : null);

	const onlyNSAdmin = (header) => (isNetworkSparesAdmin() ? header : null);

	const allowFinanceLevelOne = (header) => checkFinanceLevelOne() ? header : null;
	const allowFinanceLevelTwo = (header) => checkFinanceLevelTwo() ? header :  null;

	const sideBarHeaderTitles = [
		exemptNSAdmin("Dashboard"),
		exemptFinanceAdminOrOpsOrNSAdmin(adminAccess("Onboarding")),
		exemptFinanceAdminOrOpsOrNSAdmin("Reports"),
		exemptFinanceAdminOrOpsOrNSAdmin("Billed Orders"),
		includeFinanceOps("Billed Orders"),
		exemptNSAdmin(exemptFinanceAdminorOpsOrGarageOwner("Mobil Orders")),
		exemptFinanceAdminOrOpsOrNSAdmin("Employees"),
		exemptNSAdmin("Settlements"),
		exemptFinanceAdminOrOpsOrNSAdmin(adminAccess("GOM Essentials")),
		exemptFinanceAdminOrOpsOrNSAdmin(adminAccess("Spares Orders")),
		// onlyNSAdmin('PL Orders'),
		adminOrGarageOwnerOrNSAdmin("Reconciliation"),
		onlyNSAdmin('Retailer Payments'),
		onlyNSAdmin('Workshop Payments'),
		onlyNSAdmin('Distributor payments'),
		onlyNSAdmin('Playstore Orders'),
		onlyNSAdmin('Order Tracking'),
		onlyNSAdmin('Pl Return'),
		exemptNSAdmin(adminAccess("Payments")),
		exemptFinanceAdminOrOpsOrNSAdmin(adminAccess("Escalations")),
		// adminAccess("Purchase"),
		allowFinanceLevelOne(exemptFinanceAdmin("DO Amount")),
		exemptNSAdmin(adminAccess("Garage")),
	];

	const sideBarHeaderTitlesDistributor = [
		"Reconciliation",
		"Payments"
	];

	const sideBarHeaderTitlesNon = [ 
		"Spares Orders", 
		"Reconciliation",
		exemptNonNetworkInsurance("Non-Network Retailer Payment Request"), 
		exemptNonNetworkInsurance("Invoice"),
		checkIsDiscountModule("Discounts"),
		checkIsReturnSpareAllow("Returns")
	];

	const sideBarHeaderTitlesMN = [
		"Reconciliation",
		"Payments"
	]

	const sparesRetailerSideBarHeaderTitles = [
		...getSparesRetailerSidebarHeaderList(garageDetails),
	];
	 
	const settlementsOptionwithUser = () =>{
		if(garageOwner()){
			return ["By Garage",  "GOM Finance"]
		}else{
			return ["By Garage", "Spares By GoM", "GOM Finance"]
		}
		
	}

	// Finance user accessibilities
	const sideBarSubHeadersList = {
		Dashboard: [],
		"RetailerOrDistributorOnboard": [],
		Reports: ["Garage", "GoMechanic"],
		"Billed Orders": ["Garage", "GoMechanic"],
		"Mobil Orders": [],
		Employees: ["Employees List", "Add Employee"],
		Settlements: settlementsOptionwithUser() ,
		"Spares Orders": [],
		Escalations: [],
		"GOM Essentials": [],
		// "PL Orders": [
		// 	onlyNSAdmin('Retailer Payments'),
		// 	onlyNSAdmin('Workshop Payments'),
		// 	onlyNSAdmin('Distributor payments'),
		// 	onlyNSAdmin('Playstore Orders'),
		// 	onlyNSAdmin('Order Tracking')
		// ],
		Reconciliation: [
			exemptNonNetworkUser(exemptNSAdmin("Region wise")),
			exemptNonNetworkUser(exemptNSAdmin("City wise")),
			exemptNSAdmin("Garage wise"),
			exemptNonNetworkUser(exemptNSAdmin("Non-network Garage wise")),
			exemptNSAdmin(exemptNonNetworkUser("Retailer payments")),
			// onlyNSAdmin("Order Tracking"),
			// exemptFinanceAdmin("Distributor payments"),
			exemptNonNetworkUser(exemptNSAdmin("Summary")),
			exemptNonNetworkUser(exemptNSAdmin("Escalations/Bill Edit")),
			exemptNonNetworkUser(exemptNSAdmin("Settlement History"))
		],
		Payments:[
			allowFinanceLevelTwo(exemptNSAdmin("Garage Recon Requests")),
			allowFinanceLevelOne(exemptNSAdmin("Non-Network Retailer Payment Request")),
			allowFinanceLevelOne(exemptNSAdmin("Collection Payment")),
			allowFinanceLevelTwo("Network Retailer Payment Request"),
			exemptFinanceAdmin("Add Accounts"),
			includeFinanceOps("No Payments in 60 Days"),
			exemptNSAdmin("Payment By Garages"),
			// onlyNSAdmin("Workshop PL Entry")
		],
		// "Spares Payments": ["Non-Network Retailer Payment Request"],
		"Non-Network Retailer Payment Request": [],
		// Purchase: [],
		Garage: ["Garage List", "Add Garage"],
		"Invoice": [],
	};

	const sideBarHeadersListMN = {
		Reconciliation: 
		["Garage wise",
		"Escalations/Bill Edit",
		"Settlement History"],
		Payments:[
			"Garage Recon Requests",
			// "Network Retailer Payment Request",
			// "Add Accounts",
			"Payment By Garages"
		]
	}

	const sideBarHeadersListDistributor = {
		Reconciliation: [
			"GoMechanic To Distributor",
			"Distributor To Retailer",
			"Return Purchase",
		],
		Payments : [
			"Distributor Payment Request",
			"Add Account"
		],
		root : "/dashboard",
		links : {
			Reconciliation : [
				"/gom-to-distributor",
				"/distributor-to-retailer",
				"/return-purchase",
				"/payments" ],
			Payments : [
				"/distributor-payment-request",
				"/add-accounts"
			]
		}
	}

	const toggleExpansionPanel = (header, index) => {
		setShowExpansionPanel({
			...showExpansionPanel,
			[index]: !showExpansionPanel[index],
		});
	};

	const setActiveSubHeaDer = (header, subHeader) => {
		const activeSubHeader = subHeader && subHeader.toLowerCase();
		const activeSideBarHeader = {
			headerName: header,
			subHeaderName: activeSubHeader,
		};
		if (activeSideBarHeader.headerName == "Spares Orders") {
			garageDetails.data = {
				name: "All Garages",
				id: 1001001,
			};
			const garageDeTailss = {
				...garageDetails,
				data: { id: 1001001, name: "All Garages" },
			};
			localStorage.setItem("garageDetails", JSON.stringify(garageDeTailss));
		}
		localStorage.setItem(
			"activeSideBarHeader",
			JSON.stringify(activeSideBarHeader)
		);
		setSideBarActiveHeader(activeSideBarHeader);
	};

	const getSideBarCardItemsList = () => {
		let sideBar;
		// console.log(checkUserType());
		
		isMalaysiaLogin() ? (sideBar = sideBarHeaderTitlesMN) :
		(garageDetails.userType && garageDetails.userType.includes("nn") ? 
		(sideBar = sideBarHeaderTitlesNon) : 
		isDistributorAdmin() ? 
		(sideBar = sideBarHeaderTitlesDistributor):
		(sideBar = sideBarHeaderTitles));

		return sideBar?.map((header, index) => {
			const activeHeaderClass = "";
				// header === headerName ? "whiteBackground active-header" : "";
			
			let getSubHeadersList = 
				isMalaysiaLogin() ? 
				sideBarHeadersListMN :
				isDistributorAdmin() ? 
				sideBarHeadersListDistributor :
				isDistributorUser() ? {} :
				sideBarSubHeadersList;
				
			let noOfSubHeader = (getSubHeadersList[header])?.filter(Boolean)?.length;
			
			// const showExpansionPanelClass = showExpansionPanel[index]
			// 	? header === "Reconciliation"
			// 		? "show-expansion-panel-recon"
			// 		: (header === "Settlements"|| header === "Payments")
			// 		? "show-expansion-panel-settle"
			// 		: "show-expansion-panel"
			// 	: "";
			const expandIcon = showExpansionPanel[index] ? faAngleUp : faAngleDown;
			// console.log(`/${header}`, _route_mapper[`/${header}`]);
			if (header) {
				// if ((isMalaysiaLogin() && sideBarHeadersListMN[header] || sideBarSubHeadersList[header])?.length) {
				if (noOfSubHeader) {
					return (
						<div key={index} className="dashboardSideBarHeaderContainer">
							<div
								className={`expansion-panel-header ${activeHeaderClass}`}
								onClick={() => toggleExpansionPanel(header, index)}
							>
								<div>{header}</div>
								<FontAwesomeIcon icon={expandIcon} className="arrow" />
							</div>
							<SubListWrapper
								className={`hide-expansion-panel`}
								show={showExpansionPanel[index]}
								n={noOfSubHeader}
							>
								{/* {getSideBarSubHeaderItemsList(header, isMalaysiaLogin() && sideBarHeadersListMN[header] || sideBarSubHeadersList[header])} */}
								{getSideBarSubHeaderItemsList(
									header, 
									getSubHeadersList[header], 
									isDistributorAdmin() ?
									 sideBarHeadersListDistributor :
									 null )}
							</SubListWrapper>
						</div>
					);
				} else {
					return (
						// <Link to={`/${header}`} key={index}>
							<div
								id={`${_route_mapper[`/${header?.replaceAll(" ", "-").toLowerCase()}`]}`}
								className={`expansion-panel-header ${activeHeaderClass}`}
								onClick={() => {
									navigateTo(`/${header?.replaceAll(" ", "-").toLowerCase()}`);
									setActiveSubHeaDer(header);
								}}
							>
								{header}
							</div>
						// </Link>
					);
				}
			}
		});
	};

	const getSideBarSubHeaderItemsList = (header, headerList, headerMapper) => {
		return headerList.map((subHeader, index) => {
			const activeSubHeaderClass =
				header === headerName && subHeader && subHeader.toLowerCase() === subHeaderName
					? "cadetBlueColor"
					: "";
			if(subHeader){
				let href = null;
				(headerMapper && headerMapper["links"]) ? 
				(href = headerMapper["root"] + headerMapper["links"][header][index]) :
				(href = `/${header}/${subHeader}`);
				href = href.replaceAll(" ", "-").toLowerCase();
				// console.log(`${href}`, _route_mapper[`/${href}`]);
				return (
					// <Link to={href} key={index}>
						<div
							id={`${_route_mapper[href]}`}
							className={`expansion-panel-item ${activeSubHeaderClass}`}
							onClick={() => {
								navigateTo(href);
								setActiveSubHeaDer(header, subHeader);
							}}
						>
							{subHeader}
						</div>
					// </Link>
				);
			}
			return null;
		});
	};

	const sideBarClosedHandler = () => {
		setShowMobileSideBar(!showMobileSideBar);
		props.setSideBarToggle(false);
	};

	let classes = "close";
	if (showMobileSideBar || activeSideBarHeader === true) classes = "open";

	/** Sidebar code for spares retailer */
	const getSparesRetailerSideBarCardItemsList = () =>
		sparesRetailerSideBarHeaderTitles.map(({ path, title }, index) => {
			const activeHeaderClass = "";
				// path === headerName ? "whiteBackground active-header" : "";
			if (path) {
				return (
					// <Link to={`/${path}`} key={index}>
						<div
							id={`${_route_mapper["/"+path]}`}
							className={`expansion-panel-header ${activeHeaderClass}`}
							onClick={() => {
								navigateTo(`/${path?.replaceAll(" ", "-").toLowerCase()}`);
								setActiveSubHeaDer(path);
							}}
						>
							{title}
						</div>
					// </Link>
				);
			}
		});
	/** End of Spares retailer sidebar */

	if (loginToken) {
		return (
			<>
				<Backdrop
					show={activeSideBarHeader === true || showMobileSideBar}
					clicked={sideBarClosedHandler}
				/>
				<div className={`dashboard-side-bar ${classes}`}>
					{/* <img src={goMechanicLogo} className='logo'/> */}
					<div className="d-flex justify-center my-1">
						<img src={goMechanicLogo} className="w-24 h-24"/>
					</div>
					<div className="side-bar-container projectFontFamily fontSize-14-Px">
						{checkIfSpareRetailerLoggedIn()
						// ?getSideBarCardItemsList()
							? getSparesRetailerSideBarCardItemsList()
							: getSideBarCardItemsList()}
					</div>
					<div className="fixed bottom-0 w-full d-flex justify-center mb-3">
						<img src={dashboardImage} className="w-28 h-20" />
					</div>
					{/* <img src={dashboardImage} className='dashboard-image'/> */}
				</div>
			</>
		);
	} else {
		return null;
	}
};

export default withRouter(
	connect(
		(state) => {
			const { loginToken, activeSideBarHeader, garageDetails } = state;
			return {
				loginToken,
				activeSideBarHeader,
				garageDetails,
			};
		},
		{
			setSideBarActiveHeader,
			setSideBarToggle,
		}
	)(DashBoardSideBar)
);
