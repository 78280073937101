import store from "./Redux/Store/Store.js";

export const checkUserType = () => {
	const state = store.getState();
	let userType = state.garageDetails.userType;
	if (userType && userType.includes("nn")) return true;
	return false;
};

export const isFulfillmentUser = () => {
	const state = store.getState();
	let userType = state.garageDetails.userType;
	if (userType && userType.includes("ful")) return true;
	return false;
};

export const isProcurementUser = () => {
	const state = store.getState();
	let userType = state.garageDetails.userType;
	if (userType && userType.includes("proc")) return true;
	return false;
};

export const isDeliveryUser = () => {
	const state = store.getState();
	let userType = state.garageDetails.userType;
	if (userType && userType.includes("del")) return true;
	return false;
};

export const downloadCSVFileMethod = async (data, filename) => {
	const convertedData = await data?.text();
	var a = document.createElement("a");
	const blob = new Blob([convertedData], { type: "text/csv" });
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = `${filename}.csv`;
	a.click();
};

export const downloadExcelFileMethod = (filePromise, filename) => {
			filePromise
			.then((response) => {
				if (!response) {
					alert("No Data to download");
					return;
				}
				return response.body;
			})
			.then((body) => {
				const reader = body.getReader();

				return new ReadableStream({
					start(controller) {
						return pump();

						function pump() {
							return reader.read().then(({ done, value }) => {
								// When no more data needs to be consumed, close the stream
								if (done) {
									controller.close();
									return;
								}

								// Enqueue the next data chunk into our target stream
								controller.enqueue(value);
								return pump();
							});
						}
					},
				});
			})
			.then((stream) => new Response(stream))
			.then((response) => response.blob())
			.then((blob) => URL.createObjectURL(blob))
			.then((url) => {
				var a = document.createElement("a");
				a.href = url;
				a.download = `${filename}.xlsx`;
				a.click();
			})
			.catch((err) => console.error(err));
}

export const downloadPdfFileMethod = async (data, filename) => {
	const convertedData = await data.blob();
	var a = document.createElement('a');
	const blob = new Blob([convertedData], {type : 'application/pdf'});
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = `${filename}.pdf`;
	a.click();
}