import { SET_RECON_ORDER_ID } from "../ReduxConstants/ReduxConstants";

const reconOrderIdReducer = (state=null,action) => {
    switch (action.type) {
        case SET_RECON_ORDER_ID:
                return action.data;
        default:
            return state;
    }
}

export default reconOrderIdReducer