import {
	SET_LOGIN_TOKEN,
	SET_GARAGE_DETAILS,
	SET_SIDEBAR_SUBHEADER,
	SET_ACTION_MESSAGE,
	SET_CITY_ID,
	SET_RECON_ORDER_ID,
	SET_SIDEBAR_TOGGLE,
} from "../ReduxConstants/ReduxConstants";

export const setLoginToken = (data) => {
	return { type: SET_LOGIN_TOKEN, data: data };
};

export const setGarageDetails = (data) => {
	return { type: SET_GARAGE_DETAILS, data: data };
};

export const setSideBarActiveHeader = (data) => {
	return { type: SET_SIDEBAR_SUBHEADER, data: data };
};

export const setSideBarToggle = (data) => {
	return { type: SET_SIDEBAR_TOGGLE, data: data };
};

export const setToastData = (data) => {
	return { type: SET_ACTION_MESSAGE, data: data };
};

export const setCityId = (data) => {
	return { type: SET_CITY_ID, data: data };
};

export const setReconOrderId = (data) => {
	return { type: SET_RECON_ORDER_ID, data: data };
};
