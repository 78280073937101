import React from "react";
import './error.css';

export default class ErrorBoundary extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            error : null
        }
    }

    static getDerivedStateFromError(error){
        return { error : error.toString()}
    }

    componentDidCatch(error, info){
        console.log(error.toString(), info.componentStack);
    }

    render(){
        if(this.state.error){
            return <>
            {/* FALLBACK UI */}
            <div className="error_bound_wrap">
                <div className="error_bound_card">
                    <div className="error_bound_title">{"Something went wrong!!!"}</div>
                    <div className="error_bound_message">
                        {"Page is not available, please try again later."}
                    </div>
                    <div className="error_bound_reload">
                        <button onClick={() => window.location.reload()}>Reload</button>
                    </div>
                </div>
            </div>
            </>
        }
        return this.props.children;
    }
}