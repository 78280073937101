import {
	SET_SIDEBAR_SUBHEADER,
	SET_SIDEBAR_TOGGLE,
} from "../ReduxConstants/ReduxConstants";

const sideBarReducer = (state = null, action) => {
	switch (action.type) {
		case SET_SIDEBAR_SUBHEADER:
			return action.data;
		case SET_SIDEBAR_TOGGLE:
			return action.data;
		default:
			return state;
	}
};
export default sideBarReducer;
