function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}

export {
    removeAllChildNodes
}

export const mapQueryParams = (payload) => {
	let getEntries = 
	Object.entries(payload)

	const entriesLen = getEntries.length;
	if(entriesLen == 0)
		return null;
	getEntries = getEntries.filter(([,value]) => !!value);
	getEntries = getEntries.reduce((acc, cur, index) => {
		acc += `${cur[0]}=${cur[1]}`;
		if(index < entriesLen - 1){
			acc+="&";
		}
		return acc;
	}, `?`);
    return getEntries;
}
