import { SET_DO_AMT_ORDERS, SET_EDIT, SET_LOADER, SET_DO_ORDER_ID, SET_PAGE_INDEX, SET_SAVE, SET_PAGINATION, SET_UNIQUE_ROW, SELECT_FILE } from "../ReduxConstants/DoAmount";

const initState = {
    orderId : null,
    DoAmountOrders : [],
    PaginationDetails : {
        totalPage : 1,
        currentPages: [],
        onePageRows: 5
    },
    pageIndex : 1,
    isEdit : false,
    isSave : false,
    isloading : false,
    editableUnquieRowKey : null,
    editableRowData : {},
    selectedFile : null
}

export const doAmountReducer = (state = initState, action) => {
    const copyState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case SET_DO_ORDER_ID: return {...copyState, orderId : action?.payload};
        case SET_LOADER: return {...copyState, isloading : action?.payload};
        case SET_PAGINATION: return {...copyState, PaginationDetails : {
            ...copyState.PaginationDetails,
            totalPage : action?.payload?.totalPage,
            currentPages : action?.payload?.currentPages,
            onePageRows : action?.payload?.onePageRows
        }};
        case SET_PAGE_INDEX: 
        return {...copyState, 
                pageIndex : action?.payload?.activePage, 
                currentPages : action?.payload?.currentPages };
        case SET_DO_AMT_ORDERS: return {...copyState, DoAmountOrders : action?.payload};
        case SET_EDIT: return {...copyState, isEdit : action?.payload};
        case SET_SAVE: return {...copyState, isSave : action?.payload};
        case SET_UNIQUE_ROW: 
            return { ...copyState, 
                     editableUnquieRowKey : action?.payload?.key,
                     editableRowData : action?.payload?.row };
        case SELECT_FILE:
            return { ...copyState, selectedFile : action?.payload};
        default : return copyState;
    }
}