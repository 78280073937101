import { SET_GARAGE_DETAILS } from "../ReduxConstants/ReduxConstants";

const garageDetailsReducer = (state=null,action) => {
    switch (action.type) {
        case SET_GARAGE_DETAILS:
                return action.data;
        default:
            return state;
    }
}
export default garageDetailsReducer