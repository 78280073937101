import React, { lazy } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { adminUser, allowedUserRoutes, checkIfFinanceAdmin, isDistributorAdmin, isNetworkSparesAdmin } from './AdminUsers'
import Layout from "./Layout";
const NotFound = lazy(() => import('../Common/NotFound/NotFound'));

function userComponent(distributorAdmin, financeAdmin, admin, userAccess, Component, props, location, NSAdmin) {
  const garageDetails = JSON.parse(localStorage.getItem('garageDetails'));
  // return <Component {...props} /> 
  if(NSAdmin) return isNetworkSparesAdmin() ? <Component {...props}/> : <NotFound/>; 
  else if (userAccess == "all") return <Component {...props} />;
  else if (garageDetails.userRole == "spares_retailer") {
    return userAccess == "spares_retailer" || userAccess == "mobil_retailer" ? <Component {...{userAccess, ...props}} /> : <NotFound />
  }
  else if(garageDetails.userRole === "Finance Admin" && userAccess === "Finance Admin") return <Component {...props}/> 
  else {
    if (userAccess == "spares_retailer" || userAccess == "Finance Admin" || userAccess == "mobil_retailer") return <NotFound />;
    if(garageDetails.userRole === "nn_ins_procurement"){
      return allowedUserRoutes[garageDetails.userRole]?.includes(location?.pathname) ?
      <Component {...props} /> :
      <NotFound />;
    } 
    if(distributorAdmin){
      return isDistributorAdmin() || isNetworkSparesAdmin() ?
              <Component {...props} /> : 
              <NotFound />;
    }
    if(financeAdmin){
      return checkIfFinanceAdmin() ?
              <Component {...props} /> : 
              <NotFound />; 
    }  
    return admin ? adminUser() ? <Component {...props} /> : <NotFound /> : <Component {...props} />
  }
}

function PrivateRoute({ component: Component, NSAdmin, distributorAdmin, financeAdmin, admin, loginToken, garageDetails, userAccess, ...rest }) {
  const isAuthenticated = loginToken ? true : false

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Layout>
            {userComponent(distributorAdmin, financeAdmin, admin, userAccess, Component, props, rest?.location, NSAdmin)}
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}


export default withRouter(connect((state) => {
  const { loginToken, garageDetails } = state
  return {
    loginToken,
    garageDetails
  }
}, {
})(PrivateRoute))
