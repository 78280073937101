import { SET_LOGIN_TOKEN } from "../ReduxConstants/ReduxConstants";

const loginReducer = (state=null,action) => {
    switch (action.type) {
        case SET_LOGIN_TOKEN:
                return action.data;
        default:
            return state;
    }
}
export default loginReducer