import React, { useState } from "react";
import "./DateTimePicker.css";
import Input from "./Input/InputBox";
// // import RangeCalendar from "./Calendar/RangeCalendar.js";
import Calendar from "./Calendar/Calendar.js";
import moment from "moment";

const DatePicker = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const { date, setDaTeRange, dateType, dateName, view, disabled,labelText } = props

  const toggleCalendar = (boolean, date, dateType) => {
    setDaTeRange(date, dateType, dateName)
    setShowCalendar(boolean);
  };

  const onPressEnter = (dateValue) => {
    const date = (dateType === 'single') ? new moment(dateValue, 'MM-YYYY')._d : new moment(dateValue, 'DD-MM-YYYY')._d
    setDaTeRange(date, dateType, dateName)
    setShowCalendar(false);
  }

  const inputLabel = () => {
    let label;
    if (dateType === 'single') { 
      label=labelText?labelText:"Select Date"
    } else if (dateType === 'from') {
      label = 'Start Date'
    } else if (dateType === 'to') {
      label = 'End Date'
    }
    return label
  }


  return (
    <div>
      <div onClick={() => {if(!disabled)toggleCalendar(true)}}>
        <Input customClassName="datepickerContainer" type="text" label={inputLabel()} value={date} onPressEnter={onPressEnter} disabled={disabled} />
      </div>
      {showCalendar && !disabled &&(
        <div>
          <Calendar toggleCalendar={toggleCalendar} dateType={dateType} daTe={date} view={view}/>
          <div className="overlayDiv" onClick={() => toggleCalendar(false)}></div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
