import { SET_CITY_ID } from "../ReduxConstants/ReduxConstants";

const cityIdReducer = (state=null,action) => {
    switch (action.type) {
        case SET_CITY_ID:
                return action.data;
        default:
            return state;
    }
}

export default cityIdReducer