import {
    GET_VENDORLIST,
    GET_SINGLE_ORDER,
    SET_ORDER_ID,
} from "../ReduxConstants/ReduxConstants";

export const getSingleOrderDataReducer = (state = null, action) => {
    switch (action.type) {
        case GET_SINGLE_ORDER:
            return action.data;
        default:
            return state;
    }
};

export const setOrderIdForReview = (state = null, action) => {
    switch (action.type) {
        case SET_ORDER_ID:
            return action.data;
        default:
            return state;
    }
};

export const vendorReducer = (state = null, action) => {
    switch (action.type) {
        case GET_VENDORLIST:
            return action.data;
        default:
            return state;
    }
};
