import { getReturns, updateReturns } from '../../Apis/Apis';
import {GET_RETURNS, SET_LOADER, GET_RETURNS_STATS, MUTATE_PROCESS, LOCAL_MUTATE, ADD_UUIDS, SET_SPARE_ID, RESET_UUIDS, MASTER_RESET} from '../ReduxConstants/Returns';
import Store from '../Store/Store';

export const setLoader = (status) => dispatch => dispatch({type : SET_LOADER, payload: status});
export const resetSaveProcessUUID = () => dispatch => dispatch({
    type : RESET_UUIDS
})
export const mutateProcess = () => {
    const globalState = Store.getState();
    const { processState } = globalState.returns;
    return dispatch => {
        dispatch({
            type : MUTATE_PROCESS,
            payload : processState === "edit_state" && "save_state" || "edit_state"
        })
        processState === "save_state" && dispatch(mutateReturns());
    }
}

export const localMutate = (payload = {}, id) => {
    const globalState = Store.getState();
    let {returns_line_items, save_process_uuid} = globalState.returns;
    returns_line_items = returns_line_items.map(v => v?.uuid === id && {
        ...v,
        ...payload
    } || v)

    return dispatch => {
        dispatch({
            type : LOCAL_MUTATE,
            payload : returns_line_items
        })
        save_process_uuid.indexOf(id) < 0 && dispatch({
            type : ADD_UUIDS,
            payload : id
        })
    }   
} 



export const mutateReturns = () => async dispatch => {
    const globalState = Store.getState();
    let {returns_line_items, save_process_uuid, spare_order_id} = globalState.returns;
    if(save_process_uuid?.length > 0 && spare_order_id){
        let filtered = returns_line_items.filter(v => save_process_uuid.includes(v?.uuid));
        let formatted = filtered.map(v => ({
            spare_order_id,
            return_quantity : Number(v?.return_quantity),
            is_return_item : v?.is_return_item,
            uuid : v?.uuid
        }))
        await updateReturns(formatted);
        dispatch(resetSaveProcessUUID());
        dispatch(getReturnsById(spare_order_id));
    }
}

export const getReturnsById = (spareOrderId) => async dispatch => {
        dispatch(setLoader(true));
        let data = await getReturns(spareOrderId);
        if(data){
            dispatch({
                type : GET_RETURNS_STATS, payload : {
                total_vendor_purchase_amount : data?.total_vendor_purchase_amount,
                total_garage_selling_amount : data?.total_garage_selling_amount,
                parts_ordered : data?.parts_ordered,
                parts_delivered : data?.parts_delivered,
                parts_cancelled : data?.parts_cancelled,
                parts_returned : data?.parts_returned,
                orderId : data?.spare_order_id 
            }})
            dispatch({type : GET_RETURNS, payload : data?.spare_items})
            dispatch({type : SET_SPARE_ID, payload : data?.spare_order_id})
        }
        dispatch(setLoader(false));
}

export const resetAll = () => dispatch => dispatch({ type : MASTER_RESET });