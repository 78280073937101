import React, { useState, useEffect } from 'react';
import './Checkbox.css'

const Checkbox = (props) => {
    const [ checKed, setchecKed ] = useState(false)
    const { disabled, checked, onChange, type, background } = props

    useEffect(() => {
        setchecKed(checked)
    }, [checked])

    const defaultOnClick = () => {
        if (!disabled) setchecKed(boolean => !boolean)
    }

    const onClick = () => {
        if (onChange) {
            if (!disabled) onChange(!checKed); 
        }
        defaultOnClick()
    }

    const checkBoxClass = () => checKed ? `check-box-checked ${type}` : `check-box-unchecked ${type} ${background}`

    // const checkButton = () => checKed ? <span className='check-mark'>&#9989;</span> : ''
    const checkButton = () => checKed ? <span className='check-mark'>✔</span> : ''
    // const checkButton = () => checKed ? '✅' : ''


    return ( 
        <div className={`${checkBoxClass()}`} onClick={onClick}>{checkButton()}</div>
     );
}
 
export default Checkbox;