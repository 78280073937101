export const adminUser = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const adminUsers = [
    "Finance Admin",
    "Finance Ops",
    "Region Head",
    "City Head",
    "Central CR",
    "admin",
    "network_spares_admin",
    "spares_ops_insurance",
    "spares_ops_retail",
    "spares_insurance_delivery",
    "spares_insurance_fulfillment",
    "spares_insurance_procurement",
    "spares_retail_fulfillment",
    "spares_retail_procurement",
    "spares_retail_delivery",
    "nn_owner",
    "nn_admin",
    "nn_procurement",
    "nn_delivery",
    "nn_fulfillment",
    "nn_ins_procurement",
    "nn_sales",
  ];
  const adminUser =
    garageDetails && adminUsers.includes(garageDetails.userRole) ? true : false;
  return adminUser;
};

export const malaysiaAccess = () => {
  return ["City Head",
  "Central CR","Finance Admin",
  "Finance Ops"]?.includes(JSON.parse(localStorage.getItem("garageDetails"))?.userRole)
}

export const isCentralCR = () => {
  return ["Central CR"]?.includes(JSON.parse(localStorage.getItem("garageDetails"))?.userRole);
}

export const isDistributorAdmin = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails?.userRole?.includes("distributor_admin");
}

export const isDistributorUser = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails?.userRole?.includes("distributor");
}


export const checkIfInsuranceUser = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails?.userRole?.indexOf('insurance') > -1;
}

export const isMalaysiaLogin = () => localStorage.getItem("is_malaysia_login") === "mly";

export const checkIfFinanceAdminOrOps = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const financeAdmins = [
    "Finance Admin",
    "Finance Ops",
  ];
  const isFinanceAdminOrOps =
    garageDetails && financeAdmins.includes(garageDetails.userRole) ? true : false;
  return isFinanceAdminOrOps;
};

export const checkIfFinanceAdmin = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails && garageDetails.userRole === "Finance Admin";
}

export const isNonNetowrkUser = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const nnUser = [
    "nn_owner",
    "nn_admin",
    "nn_procurement",
    "nn_ins_procurement",
    "nn_delivery",
    "nn_fulfillment",
    "nn_sales",
  ];
  const isNNUser =
    garageDetails && nnUser.includes(garageDetails.userRole) ? true : false;
  return isNNUser;
}

export const checkNNInsUser = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails && garageDetails.userRole === "nn_ins_procurement";
}

export const checkIfSpareRetailerLoggedIn = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const isSparesUser =
    garageDetails && garageDetails.userRole == "spares_retailer";
  return isSparesUser;
};

export const escalationResolveAccessAdmin = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const adminUsers = ["Finance Admin", "Finance Ops", "City Head", "Central CR"];
  const escalationResolveAccessAdmin =
    garageDetails && adminUsers.includes(garageDetails.userRole) ? true : false;
  return escalationResolveAccessAdmin;
};

export const garageOwner = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  const garageOwner = ["Operation/Garage"];
  const gaRageOwner =
    garageDetails && garageOwner.includes(garageDetails.userRole)
      ? true
      : false;
  return gaRageOwner;
};

export const isNetworkSparesAdmin = () => {
  const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
  return garageDetails && 
          garageDetails.userRole &&
          garageDetails.userRole === "network_spares_admin";
}


export const isDiscountModule = () => localStorage.getItem('is_discount_module') === "true";
export const isReturnSpareModule = () => localStorage.getItem('return_spares_user') === "true"; 


export const allowedUserRoutes = {
  ["nn_ins_procurement"] : ["/Spares Orders", "/Spares Orders/Review"],
}

export const checkFinanceLevelOne = () => {
  const finance_type = localStorage.getItem("finance_type");
  return finance_type == 1;
}

export const checkFinanceLevelTwo = () => {
  const finance_type = localStorage.getItem("finance_type");
  return finance_type == 2;
}