import store from "../Redux/Store/Store.js";
import { setToastData } from "../Redux/Actions/ActionCreators";
import { isMalaysiaLogin } from "../Components/Routes/AdminUsers.js";
import { $detachProgressBar, $loadProgressBar } from "../Utils/progressBar.js";
// const baseUrl = "https://garage.gomechanic.app/";
// const baseUrl = "https://staging.garage.gomechanic.app/";
// const baseUrl = "https://dev.nn.garage.gomechanic.app/";
const partsRocketBaseUrl = "https://api.parts-rocket.com/";

const baseUrl = process.env.REACT_APP_API_URL;
const baseUrlWFAPP = process.env.REACT_APP_API_URL_WFAPP;
const garageBaseUrl = "https://garage.gomechanic.app/";

const BASEURL = {
	INDIA_NETWORK_URL : process.env.REACT_APP_API_URL,
    INDIA_NON_NETWORK_URL : process.env.REACT_APP_API_URL_NON,
	WORK_FORCEAPP_URL : process.env.REACT_APP_API_URL_WFAPP,
	PARTS_ROCKET_URL : "https://api.parts-rocket.com/",
	MALAYSIA_NETWORK_URL : process.env.REACT_APP_API_URL_MALAYSIA
}

export function get(url, type) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	let URL = (isMalaysiaLogin() || type === 0) && 
			  BASEURL.MALAYSIA_NETWORK_URL || 
			  BASEURL.INDIA_NETWORK_URL;

	return fetch(URL + url, options).then(
		(res) => {
			if(res.status === 401){
				return;
			}
			else if (res.status === 200 || res.status === 400) {
				const rest = res.json();
				return rest;
			} else return null;
		},
		(err) => err
	);
}

export async function getV2(url, type){
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	let URL = (isMalaysiaLogin() || type === 0) && 
			  BASEURL.MALAYSIA_NETWORK_URL || 
			  BASEURL.INDIA_NETWORK_URL;

	const response = await fetch(URL + url, options);
	if(response.status == 401) return;
	if(response.status == 200 || response.status == 400){
		const data = await response.json();
		return {...data, statusCode : response.status}
	}
	return null;
}

export const authLogin = (url, data, type = 0) => {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	};

	let URL = (type === 0 && BASEURL.MALAYSIA_NETWORK_URL) || 
			  (type === 1 && BASEURL.INDIA_NETWORK_URL); 


	return fetch(URL + url, options).then(
		async (res) => {
			const response = await res.json();
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
} 


export function post(url, data) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	};

	let URL = isMalaysiaLogin() && 
			  BASEURL.MALAYSIA_NETWORK_URL || 
			  BASEURL.INDIA_NETWORK_URL;


	return fetch(URL + url, options).then(
		async (res) => {
			const response = await res.json();
			if(response.status == 401){
				return;
			}
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}



export function postFormData(url, data, showToast = true){
	$loadProgressBar();
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Authorization: token,
	};
	const options = {
		method: "POST",
		headers: headers,
		body: data,
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			$detachProgressBar();
			if(response.status == 401){
				return;
			}
			showToast && store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function put(url, data) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "PUT",
		headers: headers,
		body: JSON.stringify(data),
	};

	let URL = isMalaysiaLogin() && 
			  BASEURL.MALAYSIA_NETWORK_URL || 
			  BASEURL.INDIA_NETWORK_URL;


	return fetch(URL + url, options).then(
		async (res) => {
			const response = await res.json();
			if(response.status == 401){
				return;
			}
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function putFormData(url, data) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Authorization: token,
	};
	const options = {
		method: "PUT",
		headers: headers,
		body: data,
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			if(response.status == 401){
				return;
			}
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function patch(url, data) {
	let token = "Bearer " + localStorage.getItem("token");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "PATCH",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		(res) => res.json(),
		(err) => err
	);
}

export function download(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	let URL = isMalaysiaLogin() && 
			  BASEURL.MALAYSIA_NETWORK_URL || 
			  BASEURL.INDIA_NETWORK_URL;

	return fetch(URL + url, options).then(
		(res) => (res.status === 200 ? res : null),
		(err) => err
	);
}

export function deleteurl(url, data) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "DELETE",
		headers: headers,
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + url, options).then(
		async (res) => {
			const response = await res.json();
			store.dispatch(
				setToastData({
					message: response ? response.message : "Something Went Wrong",
					status: response ? res.status : "",
				})
			);
			return response;
		},
		(err) => err
	);
}

export function getPartsRocket(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	return fetch(partsRocketBaseUrl + url, options).then(
		(res) => {
			if(res.status == 401){
				return;
			}
			else if (res.status === 200) {
				const rest = res.json();
				return rest;
			} else return null;
		},
		(err) => err
	);
}

export function downloadWFAPP(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	return fetch(baseUrlWFAPP + url, options).then(
		(res) => (res.status === 200 ? res : null),
		(err) => err
	);
}

export function downloadGarageBillAPP(url) {
	let token = "Bearer " + localStorage.getItem("garageToken");
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: token,
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	return fetch(garageBaseUrl + url, options).then(
		(res) => (res.status === 200 ? res : null),
		(err) => err
	);
}

//  export function get(url){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"GET",
//         headers:headers,
//     }
//     return fetch((baseUrl+url), options).then(async res=> {
//         const response = res.status===200 ? await res.json() : null
//         store.dispatch(setToastData({
//             message: response ? response.message : 'No Data Found', status: response ? res.status : ''
//         }));
//         return response }, err=>err);
// }

// export function post(url,data){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"POST",
//         headers:headers,
//         body:JSON.stringify(data),
//     }
//     return fetch(baseUrl+url, options).then(res=>res.json(), err=>err);
// }

// export function put(url,data){
//     let token ="Bearer "+localStorage.getItem('garageToken');
//     const headers={
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':token
//     }
//     const options={
//         method:"PUT",
//         headers:headers,
//         body:JSON.stringify(data),
//     }
//     return fetch(baseUrl+url, options).then(res=>res.json(),err=>err);
// }


// 1. ALL GARAGES API - GET - https://mly.dev.garage.gomechanic.app/workshops/?show_all_workshops=1
// 			you can also send 'region': (str)- north/south/east/west/Central
// 			or 'city_id':(int)


// 2. https://mly.dev.garage.gomechanic.app/reconciliation/garage-wise/?month=5&year=2021&order_filter=requested&garage_id=6&page=1&page_limit=50&order_type=retail
// 	order_filter can be one of these - ["all", "requested", "approved", "completed", "approved_by_finance", "escalated_by_finance", "settled"]

// base url for dev is-  https://mly.dev.garage.gomechanic.app/

// you can also send 'region': (str)- north/south/east/west/Central central ka c chota aayega😅