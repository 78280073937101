import React, { useState, Fragment, useRef, useEffect } from "react";
import "./MultiSelect-2.css";

const MultipleSelection = (props) => {
	const [searchString, updateSearchString] = useState("");
	const [isDropdownOpened, toggleDropdownStatus] = useState(false);
	const dropdownContainer = useRef(null);

	const {isSearchIsolated = false} = props;

	useEffect(() => {
		if (typeof document !== "undefined") {
			document.addEventListener("click", handleClickOutside);
		}
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	// Check if the click event happens outside of dropdown to close it right away
	const handleClickOutside = (event) => {
		if (
			dropdownContainer.current &&
			!dropdownContainer.current.contains(event.target)
		) {
			toggleDropdownStatus(false);
		}
	};

	const updateSelectionList = (event, option) => {
		let new_selection = null;
		if (event.target.checked) {
			new_selection = [...props.selectedOptions, option];
		} else {
			new_selection = props.selectedOptions.filter(
				(_selected_option) =>
					_selected_option[props.checkbox_id] !== option[props.checkbox_id]
			);
		}
		if (new_selection) {
			props.onChange(new_selection);
		}
	};

	const handleSingleSelection = (option) => {
		updateSearchString("");
		toggleDropdownStatus(false);
		console.log("option", option);
		props.onChange(option);
	};

	const checkSelect = () => {
		return props.multiple
			? null
			: props.selectedOptions && props.options
			? props.options.reduce(
					(a, v) =>
						v[props.checkbox_id] === props.selectedOptions
							? v[props.labelKey]
							: a,
					null
			  )
			: null;
	};

	const updaTeSearchString = (value) => {
		updateSearchString(value);
		if (props.onType) props.onType(value);
	};

	const onSearch = (value) => {
		!!isSearchIsolated &&
		props.onSearch(value);
		updaTeSearchString(value);
		
	};

	const filterData = (arr) => {
		return arr?.filter((filter_option) => {
			return (
				filter_option[props.labelKey]
					?.toLowerCase()
					.indexOf(searchString.toLowerCase()) !== -1
			);
		})
	}

	return (
		<>
			<div
				className="mui-2-dropdown-check-list"
				style={
					props.borderRadius === "none"
						? { borderRadius: 0 }
						: { borderRadius: "2px" }
				}
			>
				<span
					className={`selected-label ${
						props.multiple ? "color-black-label" : ""
					}`}
				>
					{props.multiple
						? props.selectedOptions && props.selectedOptions.length > 0
							? props.selectedOptions
									.map((selected) => selected[props.labelKey])
									.join(" | ")
							: ""
						: checkSelect()
						? props.placeholder
						: ""}
				</span>
				<input
					className={`anchorInput ${props.notValid ? "input-box-error" : ""} ${
						checkSelect() ? "color-black-placeholder" : ""
					}`}
					placeholder={
						props.multiple
							? props.placeholder
							: checkSelect()
							? checkSelect()
							: props.placeholder
					}
					value={searchString}
					disabled={props.disabled}
					onChange={(event) => {
						onSearch(event?.target?.value) 
					}}
					onFocus={() => toggleDropdownStatus(true)}
					ref={dropdownContainer}
				/>
				<ul
					className={`mui-2-dropdown-options ${
						!!isDropdownOpened ? "open" : "close"
					}`}
				>
					{props.options && props.options.length > 0
						? (isSearchIsolated ? props.options : filterData(props.options))
								// .filter((filter_option) => {
								// 	return (
								// 		filter_option[props.labelKey]
								// 			?.toLowerCase()
								// 			.indexOf(searchString.toLowerCase()) !== -1 &&
								// 			!isSearchIsolated
								// 	);
								// })
								?.map((option) => {
									let specialAttributeItem =
										props.specialAttributeCase &&
										props.specialAttributeCase.length &&
										props.specialAttributeCase.find(
											(item) => item[props.labelKey] === option[props.labelKey]
										);

									return (
										<Fragment
											key={`${option[props.labelKey]}-${
												option[props.checkbox_id]
											}`}
										>
											{" "}
											{props.multiple ? (
												<li>
													<label
														htmlFor={`checkbox${props.labelKey}${
															option[props.checkbox_id]
														}`}
													>
														{props.disabledItems &&
															props.disabledItems.reduce(
																(a, v) =>
																	v[props.checkbox_id] ===
																	option[props.checkbox_id]
																		? null
																		: a,
																<input
																	type="checkbox"
																	id={`checkbox${props.labelKey}${
																		option[props.checkbox_id]
																	}`}
																	onChange={(event) =>
																		updateSelectionList(event, option)
																	}
																	checked={
																		props.selectedOptions &&
																		props.selectedOptions.length > 0 &&
																		props.selectedOptions.reduce(
																			(a, v) =>
																				v[props.checkbox_id] ===
																				option[props.checkbox_id]
																					? true
																					: a,
																			false
																		)
																	}
																/>
															)}
														<span className="margin_l_8">
															{option[props.labelKey]}
															{}
														</span>
													</label>
												</li>
											) : (
												<li
													onClick={() => handleSingleSelection(option)}
													className={
														specialAttributeItem &&
														specialAttributeItem.customClass
															? specialAttributeItem.customClass
															: ""
													}
												>
													<label
														className={
															option[props.checkbox_id] ===
															props.selectedOptions
																? "single-selected-item"
																: ""
														}
													>
														<span className="margin_l_8">
															{option[props.labelKey]}
														</span>
														<span>
															{specialAttributeItem &&
															specialAttributeItem[
																props.keyToIncludeWithContent
															]
																? `${
																		specialAttributeItem[
																			props.keyToIncludeWithContent
																		]
																  }`
																: ""}
														</span>
													</label>
												</li>
											)}{" "}
										</Fragment>
									);
								})
						: null}
				</ul>
			</div>
			{props.error && (
				<label className="multi-select-error">{props.error}</label>
			)}
		</>
	);
};

export default MultipleSelection;
