import { SET_ACTION_MESSAGE } from "../ReduxConstants/ReduxConstants";

const toastDataReducer = (state=null,action) => {
    switch (action.type) {
        case SET_ACTION_MESSAGE:
                return action.data;
        default:
            return state;
    }
}

export default toastDataReducer