const Joi = require("@hapi/joi");

const joiSchema = Joi.object({
	"Payment Mode": Joi.string().required(),
	"Payable By": Joi.string().required(),
	"Net Payable By": Joi.string().required(),
	"Bank Payment": Joi.number().integer().required(),
	"Transaction Id": Joi.string().required(),
	"Paid Amount By": Joi.string().required(),
	Remarks: Joi.string().required(),
});

module.exports = joiSchema;
